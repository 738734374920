
import { pageTitleMixin } from '@/mixins/common';
import commentForm from '@/components/comment-form';
import productSubtitle from '@/components/product-subtitle'
import WmSelect from '@/components/wm-select.vue'
import { isNumber } from 'bootstrap-vue/esm/utils/inspect';
import collectionPreview from '@/views/orders/preview/collection_preview.vue';
import imageModal from '@/components/common/image-modal/index.vue';

export default {
  name: "orderCollection",
  data() {
    return {
      schema: undefined,
      dataModel: {},
      preloader: true,
      pageTitle: undefined,
      pageTitleDescription: undefined,
      commentFormData: undefined,
      commentData: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
        comment: undefined,
      },
      imageCatalog: [],
      orderData: {},
      catalogId: 0,
      previewStatus: false,
    }
  },
  components: {
    commentForm,
    WmSelect,
    productSubtitle,
    collectionPreview,
    imageModal,
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    console.log('this.$route.params.slug', this.$route.params.slug, this.$route.params);
    this.retrieveProductData();
  },
  updated() {
    setTimeout(this.changeHelpTextPosition(), 2000);
  },
  methods: {
    retrieveProductData() {
      this.$store.dispatch('getProductSchema', this.$route.params.slug)
        .then(( data )=> {
            this.schema = data.schema;
            this.imageCatalog = this.getImageCatalog(data.schema);
            this.catalogId = this.getCatalogId(data.schema);
            this.addHiddenSubmit();
            this.preloader = false;
            this.productTitle = data.name;
            this.pageTitle = data.name;
            this.pageTitleDescription = data.page_title;
        });
    },
    getImageCatalog(schema) {
      const fields = schema.fields;
      if (fields && Array.isArray(fields) && fields.length >= 1) {
        return fields[0].images
      }
      return []
    },
    getCatalogId(schema) {
      const fields = schema.fields;
      if (fields && Array.isArray(fields) && fields.length >= 1) {
        return fields[0].catalog_id
      }
      return 0
    },
    changeHelpTextPosition(){
      const pageContainer = document.getElementsByClassName('product-page');
      const helpTextElements = document.getElementsByClassName('helpText');
      const pageContainerRect = pageContainer[0].getBoundingClientRect();
      const maxContainerWidth = pageContainerRect.x + pageContainerRect.width;
      if (helpTextElements.length > 1) {
        for (let i = 0; i <= helpTextElements.length - 1; i += 1) {
          console.log(helpTextElements[i], i, typeof helpTextElements[i]);
          if (typeof helpTextElements[i] === 'object') {
            let elementContainer = helpTextElements[i].getBoundingClientRect();
            let maxElementWidth = elementContainer.x + elementContainer.width;
            if (maxElementWidth > maxContainerWidth) {
              helpTextElements[i].style.top = '18px';
              helpTextElements[i].style.left = `-${maxElementWidth - maxContainerWidth}px`;
            } else {
              helpTextElements[i].style.top = '0';
              helpTextElements[i].style.left = '20px';
            }
          }
          if (i > 10) {
            break
          }
        }
      }
    },
    addHiddenSubmit() {
      console.log('Add hidden submit button');
      if (!this.schema.fields) {
        this.schema.fields = []
      }
      this.schema.fields.push({
        type: 'submit',
        id: 'order-submit-btn',
        onSubmit: this.submitOrder,
        styleClasses: ['order-submit-hidden-btn'],
        validateBeforeSubmit: true,
      });
    },
    submit() {
      this.submitOrder()
    },
    showPreviewForm() {
      this.previewStatus = this.isOrderEmpty();
    },
    confirmOrder(id) {
      this.preloader = true;
      this.$store.dispatch('confirmErpOrder', {
        erpId: this.$store.getters.erpId,
        orderId: id,
      }).then(() => {
        this.preloader = false;
        this.$router.push({name: 'ordersTable'});
      })
    },
    submitOrder() {
      const orderData = this.commentFormData;
      orderData.status = 1;
      orderData.category = this.$route.params.slug;
      orderData.data = {
        image_data: this.dataModel,
        catalog_id: this.catalogId
      };
      let orderId = undefined;
      this.$store.dispatch('createOrder', {
        erpId: this.$store.getters.erpId,
        data: orderData
      }).then((data) => {
        orderId = data.id;
      }).then(() => this.confirmOrder(orderId))
    },
    getOrderItemValue(id) {
      if (this.orderData.hasOwnProperty(id)) {
        return this.orderData[id]
      }
      return null
    },
    setOrderItemValue(evnt) {
      const value = evnt.target.value
      if (value && isNumber(value)) {
        this.orderData[evnt.name] = value
      }
    },
    isOrderEmpty() {
      return !Object.values(this.dataModel).every(x => x === null || x === '')
    },
    showImageModal(imageUrl) {
      this.$modal.show(imageModal,
        {
          imageData: imageUrl,
        },
        {
          width: '80%',
          height: '90%',
          classes: 'image-modal',
          adaptive: true,
        });
    },
    clickOnPreviewButton() {
      document.getElementById('preview-button').click();
    },
  },
}
