import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  REFRESH_TOKEN,
  SIGNUP_SUCCESS,
  UPDATE_NEWS_LIST,
  UPDATE_USER_PROFILE,
  UPDATE_ERP_CONTACTS,
  UPDATE_ERP_ASDDRESSES,
  UPDATE_CURR_ERP_ADDR,
  UPDATE_CUSTOM_PLAQUES,
  UPDATE_PLAQUES,
  UPDATE_PLAQUE_FORMATS,
  SET_PAGE_TITLE,
  UPDATE_NEW_CLIENT_DATA_ERP,
  UPDATE_NEW_CLIENT_DATA_CONTACTS,
  UPDATE_NEW_CLIENT_DATA_ADDRESS,
  REMOVE_NEW_CLIENT_DATA_CONTACTS,
  REMOVE_NEW_CLIENT_DATA_ADDRESS,
  UPDATE_NEW_CLIENT_DATA_USER_KIT,
  UPDATE_CURRENT_PAGE,
  UPDATE_UNCONFIRMED_ORDERS,
  UPDATE_VIDEO_LIST,
} from '@/store/mutation-types';
import { authApi, wmApi } from '@/api/general';
import { HTTP } from "@/api/common";

export const authActions = {
  async obtainToken ({ commit, dispatch }, userData) {
    const _tmp = await authApi.obtainToken(userData)
      .then((data) => {
        localStorage.setItem('wmToken', data.token);
        commit(AUTH_SUCCESS, data)
        dispatch('getUserProfile')
        return data
      })
      .catch(error => {
        commit(AUTH_ERROR, error)
        console.log('Remove token');
        localStorage.removeItem('wmToken') // if the request fails, remove any possible user token if possible
      })
    return _tmp
  },
  removeToken ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      console.log('Remove token');
      localStorage.removeItem('wmToken') // clear your user's token from localstorage
      resolve()
    })
  },
  refreshToken ({ commit }) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('wmToken')
      authApi.refreshToken({'token': token})
        .then((data) => {
          localStorage.setItem('wmToken', data.token);
          commit(REFRESH_TOKEN, data)
          resolve(data)
        })
        .catch(error => {
          console.log('Remove token');
          localStorage.removeItem('wmToken') // if the request fails, remove any possible user token if possible
          console.log(error)
        })
    })
  },
  userRegistration ({ commit }, userData) {
    return new Promise((resolve) => {
      authApi.registerUser(userData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          return error
        })
    })
  }
}

export const wmActions = {
  getNewsList ({ commit }) {
    return new Promise((resolve, reject) => {
      wmApi.getNewsList()
        .then((data) => {
          commit(UPDATE_NEWS_LIST, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async getUserProfile ({ commit }) {
    const tmp = await wmApi.getUserProfile()
      .then((data) => {
        commit(UPDATE_USER_PROFILE, data)
        return data
      })
      .catch(error => {
        console.log(error)
      })
    return tmp
  },
  async updateUserProfile ({ dispatch }, profileInfo) {
    const tmp = await wmApi.updateUserProfile(profileInfo)
      .then((data) => {
        dispatch('getUserProfile')
        return data
      })
      .catch(error => {
        console.log(error)
      })
    return tmp
  },
  getErpContacts ({ commit }, erpId) {
    return new Promise((resolve, reject) => {
      wmApi.getErpContacts(erpId)
        .then((data) => {
          commit(UPDATE_ERP_CONTACTS, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpContact ({ commit }, { erpId, contactId }) {
    return new Promise((resolve, reject) => {
      wmApi.getErpContact(erpId, contactId)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteErpContacts ({ dispatch }, { erpId, id }) {
    return new Promise((resolve, reject) => {
      wmApi.deleteErpContacts(erpId, id)
        .then((response) => {
          if (response) {
            dispatch('getErpContacts', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateErpContact ({ dispatch }, { erpId, id, contactInfo }) {
    return new Promise((resolve, reject) => {
      wmApi.updateErpContact(erpId, id, contactInfo)
        .then((response) => {
          if (response) {
            dispatch('getErpContacts', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addErpContact ({ dispatch }, { erpId, contactInfo }) {
    return new Promise((resolve, reject) => {
      wmApi.addErpContact(erpId, contactInfo)
        .then((response) => {
          if (response) {
            dispatch('getErpContacts', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpAddresses ({ commit }, erpId) {
    return new Promise((resolve, reject) => {
      wmApi.getErpAddresses(erpId)
        .then((data) => {
          commit(UPDATE_ERP_ASDDRESSES, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpAddress ({ commit }, { erpId, addrId }) {
    return new Promise((resolve, reject) => {
      wmApi.getErpAddress(erpId, addrId)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateErpAddress ({ dispatch }, { erpId, addrId, addrInfo }) {
    return new Promise((resolve, reject) => {
      wmApi.updateErpAddress(erpId, addrId, addrInfo)
        .then((response) => {
          if (response) {
            dispatch('getErpAddresses', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addErpAddress ({ dispatch }, { erpId, addrInfo }) {
    return new Promise((resolve, reject) => {
      wmApi.addErpAddress(erpId, addrInfo)
        .then((response) => {
          if (response) {
            dispatch('getErpAddresses', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteErpAddress ({ dispatch }, { erpId, addrId }) {
    return new Promise((resolve, reject) => {
      wmApi.deleteErpAddress(erpId, addrId)
        .then((response) => {
          if (response) {
            dispatch('getErpAddresses', erpId)
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getCustomPlaques ({ commit }) {
    return new Promise((resolve, reject) => {
      wmApi.getCustomPlaques()
        .then((data) => {
          commit(UPDATE_CUSTOM_PLAQUES, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPlaques ({ commit }) {
    return new Promise((resolve, reject) => {
      wmApi.getPlaques()
        .then((data) => {
          commit(UPDATE_PLAQUES, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addCustomPlaque ({ dispatch }, { plaqueId, plaqueData }) {
    return new Promise((resolve, reject) => {
      console.log(plaqueData)
      wmApi.addCustomPlaque(plaqueId, plaqueData)
        .then((data) => {
          if (data) {
            dispatch('getCustomPlaques')
          }
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteCustomPlaque ({ dispatch }, plaqueId) {
    return new Promise((resolve, reject) => {
      wmApi.deleteCustomPlaque(plaqueId)
        .then((response) => {
          if (response) {
            dispatch('getCustomPlaques')
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPlaqueFormats ({ commit }, plaqueId) {
    return new Promise((resolve, reject) => {
      wmApi.getPlaqueFormats(plaqueId)
        .then((data) => {
          commit(UPDATE_PLAQUE_FORMATS, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createPlaqueOrder ({ dispatch }, { erpId, orderData }) {
    return new Promise((resolve, reject) => {
      wmApi.createPlaqueOrder(erpId, orderData)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDocuments ({ commit }, docType) {
    return new Promise((resolve, reject) => {
      wmApi.getDocuments(docType)
        .then((data) => {
          commit(`UPDATE_DOCUMENTS_${docType.toUpperCase()}`, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async getVideoList ({ commit }) {
    wmApi.getVideoList()
      .then((data) => {
        commit('UPDATE_VIDEO_LIST', data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  async getPages ({ commit }, slug) {
    const tmp = await wmApi.getPages(slug)
      .then((data) => {
        commit(UPDATE_CURRENT_PAGE, data)
        return data
      })
      .catch(error => {
        console.log(error)
      })
    return tmp
  },
}

export const commonActions = {
  setPageTitle ({ commit }, title) {
    commit(SET_PAGE_TITLE, title)
  },
  storeNewClientDataErp({ commit }, payload) {
    console.log('storeNewClientDataErp - action', payload);
    commit(UPDATE_NEW_CLIENT_DATA_ERP, payload)
  },
  storeNewClientUserKit({ commit }, payload) {
    console.log('storeNewClientUserKit - action', payload);
    commit(UPDATE_NEW_CLIENT_DATA_USER_KIT, payload)
  },
  storeNewClientDataContacts({ commit, state }, payload) {
    console.log('storeNewClientDataContacts - action', payload);
    const _tmp = [...state.newClientDataContacts];
    if (payload.num !== undefined) {
      _tmp[payload.num] = payload.contactInfo
    } else {
      _tmp.push(payload.contactInfo)
    }
    commit(UPDATE_NEW_CLIENT_DATA_CONTACTS, _tmp)
  },
  removeNewClientDataContacts({ commit, state }, payload) {
    console.log('removeNewClientDataContacts - action', payload);
    let _tmp = [...state.newClientDataContacts];
    _tmp = _tmp.filter((item) => item !== payload && item !== null);
    console.log('removeNewClientDataContacts - action2', _tmp);
    commit(UPDATE_NEW_CLIENT_DATA_CONTACTS, _tmp)
  },
  storeNewClientDataAddress({ commit, state }, payload) {
    console.log('storeNewClientDataAddress - action', payload);
    const _tmp = [...state.newClientDataAddress];
    if (payload.num !== undefined) {
      _tmp[payload.num] = payload.addressInfo
    } else {
      _tmp.push(payload.addressInfo)
    }
    commit(UPDATE_NEW_CLIENT_DATA_ADDRESS, _tmp)
  },
  removeNewClientDataAddress({ commit, state }, payload) {
    console.log('removeNewClientDataAddress - action', payload);
    let _tmp = [...state.newClientDataAddress];
    _tmp = _tmp.filter((item) => item !== payload && item !== null);
    console.log('removeNewClientDataAddress - action2', _tmp);
    commit(UPDATE_NEW_CLIENT_DATA_ADDRESS, _tmp)
  },
};
