<template>
  <div ref="startPage" class="start-page-form__container">
    <h2 class="start-page-form__title">{{ $t('login.title') }}</h2>
    <div class="start-page-form__wrap">
      <form @submit.prevent="onSubmit">
        <vue-form-generator
          :schema="schema"
          :model="model"
          ref="vfg"
        ></vue-form-generator>
        <input type="submit" value="Submit" class="form-hidden"/>
      </form>
      <div v-if="errorShow" class="start-page-form__error">{{ $t('login.bad_login') }}</div>
      <!-- <div
        @click.prevent="login"
        class="wm-btn start-page-form__btn"
        type="submit"
        >{{ $t('login.title') }}</div> -->
      <router-link to="/reset" class="start-page-form__forgot-link">{{ $t('login.forgot_pw') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data() {
    return {
      errorShow: false,
      model: {
        login: '',
        password: '',
      },
      schema: {
        fields: [{
          type: "input",
          inputType: "text",
          model: "login",
          placeholder: this.$t('login.login'),
          required: true,
          validator: 'string',
        },
        {
          type: "input",
          inputType: "password",
          model: "password",
          placeholder: this.$t('login.password'),
          required: true,
          validator: 'string',
        },
        {
          type: 'submit',
          onSubmit: this.login,
          buttonText: this.$t('login.title'),
          model: 'message',
          styleClasses: ['start-page-form__btn'],
          validateBeforeSubmit: true,
        }]
      }
    }
  },
  mounted() {

  },
  methods: {
    async login() {
      const _tmp = await this.$store.dispatch( 'obtainToken', {
        username: this.model.login,
        password: this.model.password
      });
      console.log('login', _tmp);
      if(_tmp) {
        // if(_tmp.is_manager) {
        //   this.$router.push({ name: 'clientsTable'})
        // } else {
        //   this.$router.push({ name: 'mainPage' })
        // }
        this.$router.push({ name: 'mainPage', })
      } else {
        this.errorShow = true
      }
    },
    onSubmit($event) {
      // using "validateAsync option, so validate() returns a promise
      this.$refs.vfg.validate().then((valid) => {
        if(valid) { this.login }
        else { /* handle */ }
      }).catch((err) => {
        /* handle */
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @mixin placeholder {
    &::-webkit-input-placeholder { @content; }
    &:-moz-placeholder           { @content; }
    &::-moz-placeholder          { @content; }
    &:-ms-input-placeholder      { @content; }
  }
  .form-control {
    @include placeholder {
      color: red !important;
    }
  }
  .form-hidden {
    display: none;
  }
</style>
