<template>
  <div class="top-menu-wrap">
    <div class="top-menu">
      <div
        v-if="userProfile.profile"
        class="top-menu__company">{{ userProfile.profile.enterprise.name }}
      </div>
      <div class="top-menu__btn-wrap" :class="{'flex-end' : isManager}">
        <router-link v-if="!isManager && userProfile.profile" to="/my-cabinet" class="top-menu__btn wm-btn">{{ $t('top_menu.cabinet') }}</router-link>
        <div @click.prevent="logout" class="top-menu__btn wm-btn ml-2">{{ $t('top_menu.sign_out') }}</div>
      </div>
    </div>
    <!--<div class="top-menu__lang-switch">-->
      <!--<div @click.prevent="$i18n.locale = 'en'" :class="{ 'active' : $i18n.locale==='en'}">EN</div>-->
      <!--<div @click.prevent="$i18n.locale = 'fr'" :class="{ 'active' : $i18n.locale==='fr'}">FR</div>-->
    <!--</div>-->
  </div>

</template>

<script>
export default {
  name: 'TopMenu',
  computed: {
    userProfile() {
      return this.$store.getters.userProfile;
    },
    isManager() {
      return this.$store.getters.isManager;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('removeToken').then(() => {
        this.$router.push('/login')
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .top-menu-wrap {
    display: flex;
  }

  .flex-end {
    justify-content: flex-end !important;
  }

  .top-menu {
    margin: 0 10px;

    &__btn-wrap {
      display: flex;
      justify-content: space-between;
    }

    &__company {
      text-align: center;
      font-size: 22px;
      font-family: 'DaxMedium';
    }

    &__btn {
      width: 110px;
      height: 30px;
      font-size: 14px;
    }

    &__lang-switch {
      font-size: 14px;
      margin-right: 10px;

      .active {
        color: #fff;
        background-color: #707070;
        border-radius: 2px;
      }

      div {
        padding: 0 5px;
        cursor: pointer;
      }
    }
  }

</style>
