<template>
  <div>
    <b-row>
      <b-col cols="10">
        <div class="order-dl">
          <div v-if="itsOk" class="order-dl__title">{{ $t('order_download.title_good') }}</div>
          <div v-if="!itsOk" class="order-dl__title">{{ $t('order_download.title_bad') }}</div>
          <div class="order-dl__btn-group">
            <button v-if="itsOk" class="wm-btn order-dl__btn order-dl__btn--dl">{{ $t('order_download.pdf_dl') }}</button>
            <router-link :to="{ name: 'home'}" class="wm-btn order-dl__btn">
              {{ $t('order_download.back') }}
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'OrderDownloadPage',
  data() {
    return {
      
    }
  },
  computed: {
    itsOk() {
      return this.$route.params.status == 200 || this.$route.params.status == 201
    }
  }
  // beforeMount() {
  //   this.itsOk = ()
  // }
}
</script>

<style scoped lang="scss">

  .order-dl {
    margin-top: 90px;

    &__title {
      text-align: center;
      font-size: 20px;
      font-family: 'DaxMedium';
      margin-bottom: 40px;
    }
    
    &__btn-group {
      display: flex;
      justify-content: center;

    }

    &__btn {
      height: 35px;
      border-radius: 100px;
      width: 200px;
      margin: 0 15px;
      &--dl {

      }
    }
  }

</style>
