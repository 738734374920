<template>
  <div>
    <div class="header">
      <!-- <router-link :to="{ name: isManager ? 'clientsTable' : 'mainPage' }" class="header__logo" href="#"> -->
      <div class="header-left-empty-col"></div>
      <div class="header-logo-wrapper">
        <router-link :to="{ name: 'mainPage' }" class="header__logo" href="#">
          <img src="/static/img/wm_logo.png" alt="">
        </router-link>
      </div>
<!--        <h1 v-if="pageTitle" class="header__title">{{ pageTitle }}</h1>-->
      <TopMenu/>
    </div>
    <div class="header-empty-line"></div>
  </div>
</template>

<script>
import TopMenu from '@/components/top-menu.vue'
import { pageTitleMixin } from '@/mixins/common';

export default {
  name: 'Header',
  data() {
    return {
    }
  },
  components: {
    TopMenu,
  },
  mixins: [ pageTitleMixin ],
  computed: {
    pageTitle() {
      return this.$store.getters.pageTitle
    },
    // pageTitleDescription() {
    //   return this.$store.getters.pageTitleDescription
    // },
    isManager() {
      return this.$store.getters.isManager
    },
    headerTitle() {
      switch(this.$route.name) {
        case 'mainPage':
          return this.$t('header.title');
          break;
        case 'myCabinet':
          return this.$t('top_menu.cabinet');
          break;
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeEdit');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left-empty-col {
      width: 30%;
      display: block;
    }

    &-logo-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      display: block;
      width: 185px;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;

      img {
        width: 90%;
      }
    }

    &__title {
      text-transform: uppercase;
      font-family: 'DaxMedium';
      font-size: 25px;
      line-height: 25px;
      margin: 0;
    }
    &-empty-line {
      display: flex;
      width: 100%;
      height: 1px;
    }
  }

  // .page-title-description {
  //   padding: 0 30px;
  //   text-align: left;
  //   font-size: 14px;
  // }

</style>
