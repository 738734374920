<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div class="wm-modal__title">{{ $t('presentationKit.title') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="wm-modal__subtitle">{{ isEditModal ? $t('edit_addr_modal.subtitle') : $t('add_addr_modal.subtitle') }}</div>
      <div class="product-page">
        <div class="product-page__form">
          <vue-form-generator
            :schema="schema"
            :model="dataModel"
          ></vue-form-generator>
        </div>
      </div> 
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>

  export default {
    name: "presentationKitModal",
    props: {
      kitModel: Object
    },
    data() {
      return {
        isEditModal: !!this.$route.params.id,
        schema: undefined,
        dataModel: undefined,
        slug: 'presentation-kit',
      }
    },
    beforeMount() {
      this.retrieveProductData();
    },
    mounted() {
      
    },
    methods: {
      retrieveProductData() {
        this.$store.dispatch('getProductSchema', this.slug)
          .then(( data )=> {
            console.log('data', data);
            this.schema = data.schema;
            this.schema.fields.push({
              type: 'submit',
              id: 'order-submit-btn',
              onSubmit: this.submitUserKit,
              styleClasses: ['order-submit-hidden-btn'],
              validateBeforeSubmit: true,
            });
            if(this.kitModel) {
              this.dataModel = JSON.parse(JSON.stringify(this.kitModel.data));
            } else {
              this.dataModel = data.model;
            }
            this.preloader = false;
          });
      },

      submit() {
        document.getElementById('order-submit-btn').click();
      },

      submitUserKit() {
        const data = {
          data: JSON.parse(JSON.stringify(this.dataModel)),
        };
        this.$store.dispatch('storeNewClientUserKit', data); 
        this.$emit('close')
      },
      close() {
        this.$emit('close')
      }
    },
    watch: {
    }
  }
</script>

<style scoped lang="scss">
  .product-page {
    margin-bottom: 50px;

    &__form {
      margin: 20px 30px;
    }
    &__comment-form {
    }
  }

  .order-submit-hidden-btn {
    display: none;
  }

</style>
