import WmSelect from '@/components/wm-select.vue';

export default {
  name: "InterPersonalize",
  props: {
    interPersonalizeData: {
      type: Array,
      default: [],
    },
    interPersonalizeDropdownData: {
      type: Array,
      default: [],
    },
    submit: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    WmSelect,
  },
  data() {
    return {
      dataValues: [],
      dataValuesExample:
      {
        'id': 0,
        'text': '',
        'option': '',
        'quantity': 0,
        'error': false,
      },
      textValues: [],
      quantityValues: [],
      testValues: [],
      rowIndex: 0,
    }
  },
  mounted() {
    this.addInterRow()
  },
  methods: {
    updateDropdownValue(value, id) {
      this.clearValidation()
      const rowValue = this.dataValues.find(item => item.id === id)
      const optionData = this.interPersonalizeData.find(
        item => item.title === this.interPersonalizeDropdownData[value])
      rowValue.option = optionData.id
    },
    updateQuantityValue(event) {
      this.clearValidation()
      const itemId = parseInt(event.target.name.slice(12), 10)
      const itemQuantity = parseInt(event.target.value, 10)
      const rowValue = this.dataValues.find(item => item.id === itemId)
      rowValue.quantity = itemQuantity
    },
    updateTextValue(event) {
      this.clearValidation()
      const itemId = parseInt(event.target.name.slice(11), 10)
      const rowValue = this.dataValues.find(item => item.id === itemId)
      rowValue.text = event.target.value
    },
    addInterRow() {
      const _tmp = JSON.parse(JSON.stringify(this.dataValuesExample))
      _tmp['id'] = this.rowIndex
      this.dataValues.push(_tmp)
      this.rowIndex += 1
    },
    removeInterRow(rowId) {
      this.clearValidation()
      const rowIndex = this.dataValues.findIndex(item => item.id === rowId)
      this.dataValues.splice(rowIndex, 1)
    },
    validateData() {
      let isValid = true
      this.dataValues.forEach((item) => {
        if (item.quantity) {
          if (item.text === '' || item.option === '') {
            item['error'] = true
            isValid = false
          }
        } else {
          this.removeInterRow(item.id)
        }
      })
      if (isValid) {
        this.$emit('personalizeData', this.dataValues)
        this.$emit('validation', true)
      } else {
        this.$emit('validation', false)
      }
    },
    clearValidation() {
      this.dataValues.forEach(item => item.error = false)
    },
  },
  watch: {
    submit: {
      handler() {
        console.log('personalize submit', this.submit)
        if (this.submit) {
          this.validateData()
        }
      }
    }
  }
}
