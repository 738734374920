import VueFormGenerator from 'vue-form-generator'

const VueFormGeneratorLocale = VueFormGenerator

VueFormGeneratorLocale.validators.resources.fieldIsRequired = 'This field is required!'
VueFormGeneratorLocale.validators.resources.invalidFormat = 'Invalid format!'
VueFormGeneratorLocale.validators.resources.invalidNumber = 'Invalid number'
VueFormGeneratorLocale.validators.resources.invalidEmail = 'Invalid e-mail address!'
VueFormGeneratorLocale.validators.resources.invalidURL = 'Invalid URL!'
// VueFormGeneratorLocale.validators.resources.selectOptions.noneSelectedText = 'test'

export default VueFormGeneratorLocale
