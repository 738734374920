<template>
  <div class="left-side-bar">
    <div class="left-side-bar__links">
      <router-link to="/" class="left-side-bar__link wm-link">{{ $t('left_side_bar.home') }}</router-link>
      <router-link :to="{ name: 'about' }" class="left-side-bar__link wm-link">{{ $t('left_side_bar.help') }}</router-link>
    </div>
    <nav class="left-side-bar__menu">
      <router-link v-if="isManager" :to="{ name: 'clientsTable' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.clients') }}</router-link>
      <div v-if="isManager" class="left-side-bar__line"></div>
      <router-link :to="{ name: 'ordersTable' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.requests') }}</router-link>
      <div class="left-side-bar__line"></div>
      <router-link :to="{ name: 'document' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.documents') }}</router-link>
      <div class="left-side-bar__line"></div>
      <router-link :to="{ name: 'tarif' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.prices') }}</router-link>
      <div class="left-side-bar__line"></div>
      <router-link :to="{ name: 'catalog' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.catalog') }}</router-link>
      <div class="left-side-bar__line"></div>
      <router-link :to="{ name: 'video' }" class="left-side-bar__menu-link wm-link">{{ $t('left_side_bar.video') }}</router-link>
    </nav>
    <div class="left-side-bar__bottom-links">
      <router-link :to="{ name: 'landing' }" class="left-side-bar__link wm-link">{{ $t('left_side_bar.landing') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftSideBar',
  data() {
    return {

    }
  },
  computed: {
    isManager() {
      return this.$store.getters.isManager;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .left-side-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 10px;
      flex-direction: row;
    }

    &__link {
      font-size: 16px;
      text-decoration: underline;
      font-family: 'DaxMedium';
    }

    &__menu {
      width: 100%;
      padding: 10px;
      background-color: #d6d6d6;
      border: 1px solid #b9b9b9;
    }

    &__menu-link {
      font-family: 'DaxBold';
      display: block;
      font-size: 18px;
      padding: 5px 10px;
      margin: 15px 0;
    }

    &__line {
      background-color: #fff;
      width: 30%;
      height: 2px;
      margin: 0 auto;
    }

    &__bottom-links {
      width: 100%;
      padding: 10px 0 10px 20px;
    }
  }

</style>
