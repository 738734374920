<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="orders-page">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="11" class="table-wrap">
          <router-link 
            :to="{ name: 'clientNew' }" 
            class="wm-btn clients-page__btn"
          >
            {{ $t('clients_table.new_client') }}
          </router-link>

          <b-table-simple borderless>
            <b-thead>
              <b-tr class="table-head-row">
                <b-td>{{ $t('enterpriseModal.name') }}</b-td>
                <b-td>{{ $t('enterpriseModal.postalCode') }}</b-td>
                <b-td>{{ $t('enterpriseModal.tva') }}</b-td>
                <b-td>{{ $t('enterpriseModal.webSite') }}</b-td>
                <b-td>{{ $t('enterpriseModal.comment') }}</b-td>
                <b-td :colspan="2">{{ $t('enterpriseModal.contract_link_head_label') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
              </b-tr>
              <b-tr 
                v-for="item in erpList"
                :key="item.id"
                class="table-body-row"
              >
                <b-td
                  class="table-clicable-el"
                  @click="editErp(item.id)"
                >{{ item.name }}</b-td>
                <b-td>{{ item.postal_code }}</b-td>
                <b-td>{{ item.vat }}</b-td>
                <b-td><a :href="item.web_site" target="_blank" rel="noopener noreferrer">{{ item.web_site }}</a></b-td>
                <b-td>{{ item.comment }}</b-td>
                <b-td v-if="linePreloader !== item.id">
                  <a v-if="!item.contract_pdf"
                     href="#" @click.prevent="createContract(item.id)">
                    {{ $t('enterpriseModal.contract_link_button_label') }}
                  </a>
                  <a v-else
                     :href="item.contract_pdf"
                     target="_blank">
                    {{ $t('enterpriseModal.contract_download_button_label') }}
                  </a>
                </b-td>
                <b-td v-else><img src="/static/img/preloader/30.svg"></b-td>
                <b-td>
                  <a v-if="item.contract_pdf"
                     href="#" @click.prevent="createContract(item.id)">
                    {{ $t('enterpriseModal.contract_link_button_label_repeat') }}
                  </a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </div>
  
</template>

<script>
import { pageTitleMixin } from '@/mixins/common'

export default {
  name: 'ClientsTable',
  data() {
    return {
      preloader: true,
      linePreloader: undefined,
      pageTitle: this.$t('page_titles.enterprises'),
    }
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    this.$store.dispatch('getEnterpriseList').then(() => {
      this.preloader = false;
    })
  },
  computed: {
    erpList() {
      return this.$store.getters.erpList;
    },
  },
  methods: {
    editErp(id) {
      this.$router.push({ name: 'clientNew', params: { id: id } });      
    },
    async createContract(erpId) {
      this.linePreloader = erpId;
      const result = await this.$store.dispatch('createContract', { erpId: erpId });
      if (result.hasOwnProperty('detail')) {
        if (result.detail === 'Billing address has not found for enterprise') {
          this.linePreloader = undefined;
          alert(this.$t('managerDashboardErrors.no_billing_address'))
        }

        if (result.detail === 'Erp admin not found') {
          this.linePreloader = undefined;
          alert(this.$t('managerDashboardErrors.no_erp_admin'))
        }
      }
      if (result.hasOwnProperty('contract')) {
        this.$store.dispatch('getEnterpriseList').then(() => {
          this.linePreloader = undefined;
        })
      }
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .table-wrap {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
  }

  .table-head-row {
    border-bottom: 1px solid #b9b9b9;
  }

  .table-body-row td{
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
  } 

  .table-clicable-el {
    cursor: pointer;
  }

  .table-clicable-el:hover {
    color: blue;
    text-decoration: underline;
  }

  .download-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(/static/img/download.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .clients-page__btn {
    margin-left: auto;
    width: 150px;
  }

</style>
