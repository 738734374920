const messages = {
  en: {
    login: {
      title: 'Log `in',
      login: 'Login',
      password: 'Password',
      forgot_pw: 'Forgot your password?',
      bad_login: 'Invalid Login or password',
    },
    signup: {
      registration: 'Registration',
      name: 'Name *',
      company: 'Company *',
      postal_code: 'Postal code *',
      email: 'Email *',
      phone: 'Phone *',
      message: 'Message',
      required: '* : required fields',
      signup: 'Sign up',
      alert_msg: 'Thank you. Your request has been sent for processing.',
      userExists: 'Votre entreprise a déjà un compte, contactez nous pour obtenir les accès contact@westmemory.fr ou au 02 85 52 87 44',
    },
    reset_pw: {
      title: 'Enter your email to reset password',
      send: 'Send'
    },
    left_side_bar: {
      help: 'Need help?',
      home: 'Home',
      requests: 'My requests',
      documents: 'My documents',
      prices: 'Prices',
      clients: 'Clients',
      catalog: 'Catalog',
      video: 'Video',
      landing: 'Voir le site vitrine',
    },
    right_side_bar: {
      open: 'Info',
      close: 'Hide',
      top_section_title: 'How to use the platform?',
      section_title: 'Offer put forward',
    },
    header: {
      title: 'Dashboard',
    },
    top_menu: {
      tmp: 'Name of company',
      cabinet: 'My cabinet',
      sign_out: 'Sign out',
    },
    plaques_table: {
      reference: 'Reference',
      visual: 'Visual',
      format: 'Format',
      pedestal: 'pedestal',
      plate: 'plate',
      text: 'Text',
      amount: 'Amount',
      location_inter: 'Location Inter',
      aColler: 'à coller',
      socleSansInter: 'sur socle sans inter',
      socleEmplacement: 'sur socle emplacement 1 inter',
    },
    sliders_page: {
      preview: 'PREVIEW MY REQUEST',
      choose_category: 'select category',
      all_category: 'Show all categories',
    },
    comment_form: {
      comment: 'Comment',
      input: 'Reference file *',
      dropdown_default: 'Choose variant',
      dropdown_contact: 'Contact *',
      dropdown_address: 'Delivery address *',
      validate: 'Validate request',
      preview: 'Preview order',
      backButtonText: 'Back',
      help_text: 'Family or deceased name for follow-up, email exchanges and billing',
      footer_text: 'Once validated, you will find your request in the "My requests" tab',
    },
    main_page: {
      title: 'New request',
      defaultTitle: 'Product',
    },
    product: {
      inter: 'Inter',
      epitafie: 'Epitafie',
      custom: 'Custom',
      customLabel: 'Custom',
      customLabelQuantity: 'Quantity',
      mobilierTitle: 'Mobilier Inter',
      mobilierText: 'Retrouver la fiche produit dans le panneau "infos" à droite de votre écran et nous contacter pour toute demande.',
      mobilierTextPlaceholder: 'votre texte',
      interPersonalizeTitle: 'Inter personnalisé',
      interPersonalizeAddLinkTitle: 'Add a new row',
      interPersonalizeError: 'Error in the data',
    },
    previewPage: {
      title: 'Order summary',
      fieldName: 'Name',
      fieldValue: 'Value',
      fieldQuantity: 'Quantité',
      fieldOption: 'Option',
      fieldText: 'Texte',
    },
    order_edit: {
      amount: 'Amount',
      add_btn: 'Add',
      cb_on_base: 'on base',
      cb_on_plate: 'on plate',
      inter_base: 'inter base',
      inter_plate: 'inter plate',
      validate: 'Validate',
      text: 'Text',
      quantity: 'quantity',
      help_text: 'Some help text to plaque edit', // need to edit
      aColler: 'à coller',
      socleSansInter: 'sur socle sans inter',
      socleEmplacement: 'sur socle emplacement 1 inter',
    },
    errorTexts: {
      required: 'Value required!',
      error: 'Value error!',
      usedEnterpriseName: 'This enterprise name exists',
      siteUrl: 'Name not valid (Example: https://site.com)',
    },
    cabinet: {
      contacts: 'Contacts',
      add_contact: 'Add contact',
      gender: 'Gender',
      name: 'Name *',
      first_name: 'First Name *',
      function: 'Function *',
      phone: 'Phone',
      mob_phone: 'Mobile phone',
      email: 'Email *',
      reg_contacts: 'Registered contacts',
      reg_contact_info: 'Registered contact information',
      contact_info: 'for any modification please contact contact@westmemory.fr',
      delivery_address: 'Delivery address',
      billing_address: 'Billing address',
      enterprise: 'Enterprise',
      address: 'Address',
      postal_code: 'Postal code',
      email_nr: 'Email',
      city: 'City',
      VAT: 'VAT number',
      website: 'Website',
      main_contact: 'Main contact',
      contact_accounting: 'Contact accounting',
      change_password: 'Change password',
      comment: 'Comment',
      profile_info: 'Profile information',
      username: 'Username',
      position: 'Position',
    },
    add_addr_modal: {
      title: 'Add new address',
      titleEdit: 'Edit new address',
      subtitle: 'Enter the information:',
      addr_type: 'Address type:',
      ok: 'OK',
      cancel: 'Cancel',
    },
    edit_addr_modal: {
      title: 'Edit address',
      subtitle: 'Enter new information:',
    },
    order_download: {
      title_good: 'Thank you, your request will be treated as soon as possible',
      title_bad: 'Something wrong, please try again',
      pdf_dl: 'Download in PDF',
      back: 'Back to home page',
    },
    orders_table: {
      reference: 'Reference',
      contact: 'Contact',
      date: 'DATE',
      download: 'Download',
      status: 'Status',
      confirm: 'Confirm all',
      confirm_message: 'Order is confirmed!',
      processing: 'Processing',
      done: 'Done',
      orders_confirm: 'Orders confirmed!',
      status_new: 'New',
      status_confirmed: 'Confirmed',
    },
    newClient: {
      erpSectionTitle: 'Enterprise information',
      contacts: 'Contacts',
      address: 'Address',
      btn: 'Create a new client',
      presentationKit: 'Presentation kit',
      contactDelConfirm: 'Are you sure you want to delete this contact?',
      addressDelConfirm: 'Are you sure you want to delete this address?',
      adminWarn: 'Please, create at least one decision maker contact.',
    },
    managerDashboardErrors: {
      no_billing_address: 'Billing address has not found for enterprise',
      no_erp_admin: 'In contacts must be at least one contact with option "Decision maker"',
    },
    enterpriseModal: {
      title: 'Add new enterprise',
      titleEdit: 'Edit enterprise info',
      name: 'Enterprise',
      postalCode: 'Postal code',
      tva: 'VAT number',
      webSite: 'Web site',
      comment: 'Comments',
      membership: 'Membership',
      contract_link_head_label: 'Contract status',
      contract_link_button_label: 'Create contract',
      contract_link_button_label_repeat: 'Redo create contract',
      contract_download_button_label: 'Download contract',
    },
    contactModal: {
      title: 'Add new contact',
      titleEdit: 'Edit contact',
      mr: 'Mr',
      ms: 'Mrs',
      func: 'Function',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      mobilePhone: 'Mobile phone',
      email: 'email',
      dm: 'Decision maker',
    },
    documents: {
      title: 'Document title',
    },
    addressModal: {
      address: 'Address',
      email: 'Email',
      phone: 'Phone',
      website: 'Web site',
      postalCode: 'Postal code',
      city: 'City',
    },
    clients_table: {
      new_client: 'Create new client',
    },
    page_titles: {
      about: 'About',
      cabinet: 'My cabinet',
      dashboard: 'Dashboard',
      orders: 'My requests',
      documents: 'My documents',
      tarifs: 'Prices',
      catalog: 'Catalog',
      video: 'Video',
      enterprises: 'Enterprises',
      edit_client: 'Edit client',
      pm_collections: 'Plaques & Medaillon : collections', // need to translate
      inter_epitafie: 'CATALOGUE INTER ET EPITAPHE',
    },
    warningInfo: {
      emptyVideoList: 'Aucune vidéo trouvée',
    },
    productPageFilter: {
      inter: 'Inter models',
      epitafie: 'Epitaph models',
      custom: 'Inter custom',
      mobilier: 'Mobilier Inter',
    },
  },

  fr: {
    login: {
      title: 'Connexion',
      login: 'Identifiant',
      password: 'Mot de passe',
      forgot_pw: 'Mot de passe oublié ?',
      bad_login: 'Identifiants incorrects',
    },
    signup: {
      registration: 'Demander un accès',
      name: 'Nom *',
      company: 'Entreprise *',
      postal_code: 'Code Postal *',
      email: 'Email *',
      phone: 'Téléphone *',
      message: 'Message',
      required: '* : champs obligatoires',
      signup: 'ENVOYER',
      alert_msg: 'Merci. Votre demande a été envoyée pour traitement.', // need to translate
      userExists: 'User already exists. Try to login, please',
    },
    reset_pw: {
      title: 'Merci d’indiquer votre email ci-dessous pour recevoir un nouveau mot de passe.',
      send: 'ENVOYER',
    },
    left_side_bar: {
      help: 'Besoin d`aide ?',
      home: 'Accueil',
      requests: 'Mes demandes',
      documents: 'Mes documents',
      prices: 'Tarifs',
      clients: 'Clients', // need to translate
      catalog: 'Catalogue',
      video: 'Vidéo',
      landing: 'Voir le site vitrine',
    },
    right_side_bar: {
      open: 'INFOS',
      close: 'Cacher',
      top_section_title: ' Comment utiliser la plateforme ?',
      section_title: 'OFFRE MISE EN AVANT',
    },
    header: {
      title: 'DASHBOARD',
    },
    top_menu: {
      tmp: 'nom de l`entreprise',
      cabinet: 'Mon compte',
      sign_out: 'Déconnexion',
    },
    plaques_table: {
      reference: 'Référence',
      visual: 'Visuel',
      format: 'Format',
      pedestal: 'sur socle',
      plate: 'sur plaque',
      text: 'Texte',
      amount: 'Quantité',
      location_inter: 'Emplacement Inter',
      aColler: 'à coller',
      socleSansInter: 'sur socle sans inter',
      socleEmplacement: 'sur socle emplacement 1 inter',
    },
    sliders_page: {
      preview: 'APERCU DE MA DEMANDE',
      choose_category: 'Choisir une catégorie',
      all_category: 'Afficher toutes les catégories',
    },
    comment_form: {
      comment: 'Commentaire',
      input: 'Référence dossier *',
      dropdown_default: 'Choisissez une variante',
      dropdown_contact: 'Contact *',
      dropdown_address: 'Adresse de livraison *',
      validate: 'VALIDER DEMANDE',
      preview: 'APERCU DE MA DEMANDE',
      backButtonText: 'Retour',
      help_text: 'Nom de la famille ou du défunt pour le suivi, les échanges par mail et la facturation',
      footer_text: 'Une fois validé, vous retrouverez votre demande dans l\'onglet "Mes demandes"',
    },
    product: {
      inter: 'Modèles Inter',
      epitafie: 'Modèles Epitaphe',
      custom: 'Inter personnalisé',
      customLabel: 'Texte personnalisé',
      customLabelQuantity: 'Quantité',
      mobilierTitle: 'Mobilier Inter',
      mobilierText: 'Retrouver la fiche produit dans le panneau "infos" à droite de votre écran et nous contacter pour toute demande.',
      mobilierTextPlaceholder: 'votre texte',
      interPersonalizeTitle: 'Inter personnalisé',
      interPersonalizeAddLinkTitle: 'Ajouter un inter',
      interPersonalizeError: 'Erreur dans les données',
    },
    previewPage: {
      title: 'Récapitulatif de la commande',
      fieldName: 'Nom',
      fieldValue: 'Valeur',
      fieldQuantity: 'Quantité',
      fieldOption: 'Option',
      fieldText: 'Texte',
    },
    main_page: {
      title: 'Nouvelle demande / commande',
      defaultTitle: 'Produit',
    },
    order_edit: {
      amount: 'Quantité',
      add_btn: 'Ajouter',
      cb_on_base: 'sur socle',
      cb_on_plate: 'sur plaque',
      inter_base: 'inter socle',
      inter_plate: 'inter plaque',
      validate: 'VALIDER',
      text: 'Texte',
      quantity: 'Quantité',
      help_text: 'Some help text to plaque edit', // need to edit and translate
      aColler: 'à coller',
      socleSansInter: 'sur socle sans inter',
      socleEmplacement: 'sur socle emplacement 1 inter',
    },
    errorTexts: {
      required: 'Valeur requise!',
      error: 'Valeur incorrects!',
      usedEnterpriseName: 'Ce nom d`entreprise existe déjà. Veuillez en choisir un autre', // need to translate
      siteUrl: 'Nom non valide (Exemple : https://site.com)', // need to translate
    },
    cabinet: {
      contacts: 'Contacts',
      add_contact: 'Ajouter contact',
      gender: 'Genre',
      name: 'Nom',
      first_name: 'Prénom',
      function: 'Fonction',
      phone: 'Téléphone fixe',
      mob_phone: 'Téléphone mobile',
      email: 'Email',
      reg_contacts: 'Contact(s) enregistré(s)',
      reg_contact_info: 'Coordonnées enregistrées',
      contact_info: 'pour toutes modification merci de contacter contact@westmemory.fr',
      delivery_address: 'Adresse de livraison',
      billing_address: 'Adresse de facturation',
      enterprise: 'Entreprise',
      address: 'Adresse',
      postal_code: 'Code postal',
      email_nr: 'Email',
      city: 'Ville',
      VAT: 'Num de TVA',
      website: 'Site web',
      main_contact: 'Contact principal',
      contact_accounting: 'Contact comptabilité',
      change_password: 'Modification mot de passe',
      comment: 'Commentaire',
      profile_info: 'Informations sur le profil', // need to translate
      username: 'Identifiant', // need to translate
      position: 'Position', // need to translate
    },
    add_addr_modal: {
      title: 'Ajouter une nouvelle adresse',
      titleEdit: 'Modifier la nouvelle adresse',
      subtitle: 'Enter les informations:',
      addr_type: 'Type d`adresse:',
      ok: 'OK',
      cancel: 'Annuler',
    },
    edit_addr_modal: {
      title: 'Modifier l`adresse',
      subtitle: 'Entrez de nouvelles informations:',
    },
    order_download: {
      title_good: 'Merci, votre demande sera traitée dans les meilleurs délais',
      title_bad: 'Quelque chose ne va pas, s`il vous plaît essayez à nouveau',
      pdf_dl: 'Télécharger en PDF',
      back: 'Retour à la page d`accueil',
    },
    orders_table: {
      reference: 'Référence',
      contact: 'Contact',
      date: 'DATE',
      download: 'Téléchargement',
      status: 'numéro de commande',
      confirm: 'Confirmer tout',
      confirm_message: 'La commande est confirmée!', // need to translate
      processing: 'En traitement', // need to translate
      done: 'Terminé', // need to translate
      orders_confirm: 'Votre commandes sont confirmées!', // need to translate
      status_new: 'Nouveau', // need to translate
      status_confirmed: 'Confirmée', // need to translate
    },
    newClient: {
      erpSectionTitle: 'Informations d`entreprise',
      contacts: 'Contacts',
      address: 'Adresse',
      btn: 'Créer un nouveau client',
      presentationKit: 'Presentation kit', // need to translate
      contactDelConfirm: 'Êtes-vous sûr de vouloir supprimer ce contact?', // need to translate
      addressDelConfirm: 'Êtes-vous sûr de vouloir supprimer cette adresse?', // need to translate
      adminWarn: 'S`il vous plaît, créez au moins un contact pour les décideurs.', // need to translate
    },
    managerDashboardErrors: {
      no_billing_address: 'Adresse de facturation n\'a pas été trouvée pour l\'entreprise',
      no_erp_admin: 'Dans les contacts doivent être au moins un contact avec l\'option "Décideur"',
    },
    presentationKit: {
      title: 'Ajouter la presentation kit', // need to translate
    },
    enterpriseModal: {
      title: 'Ajouter une nouvelle entreprise',
      titleEdit: 'Modifier les informations de l`entreprise',
      name: 'Entreprise',
      postalCode: 'Code postal',
      tva: 'Num de TVA',
      webSite: 'Site web',
      comment: 'Comments',
      membership: 'appartenance',
      contract_link_head_label: 'Lien contractuel',
      contract_link_button_label: 'Créer un contrat',
      contract_link_button_label_repeat: 'Refaire créer un contrat',
      contract_download_button_label: 'Télécharger le contrat',
    },
    contactModal: {
      title: 'Ajouter un nouveau contact',
      titleEdit: 'Modifier le contact',
      mr: 'Monsieur',
      ms: 'Madame',
      func: 'Fonction',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      phone: 'Téléphone',
      mobilePhone: 'Téléphone portable',
      email: 'email',
      dm: 'Décideur',
    },
    documents: {
      title: 'Titre du document',
    },
    addressModal: {
      address: 'Adresse',
      email: 'Email',
      phone: 'Téléphone',
      website: 'Site web',
      postalCode: 'Code postal',
      city: 'Ville',
    },
    clients_table: {
      new_client: 'Créer un nouveau client', // need to translate
    },
    page_titles: {
      about: 'Besoin d\'aide', // need to translate
      dashboard: 'Tableau de bord', // need to translate
      cabinet: 'Mon cabinet', // need to translate
      orders: 'Mes demandes', // need to translate
      documents: 'Mes documents', // need to translate
      tarifs: 'tarifs', // need to translate
      catalog: 'Catalogue',
      video: 'Vidéo',
      enterprises: 'Entreprises', // need to translate
      edit_client: 'Modifier le client', // need to translate
      pm_collections: 'Plaques & Medaillon : collections', // need to translate
      inter_epitafie: 'CATALOGUE INTER ET EPITAPHE',
    },
    warningInfo: {
      emptyVideoList: 'Aucune vidéo trouvée',
    },
    productPageFilter: {
      inter: 'Modèles Inter',
      epitafie: 'Modèles Epitaphe',
      custom: 'Inter personnalisé',
      mobilier: 'Mobilier Inter',
    },
  },
}

export default messages
