<template>
  <b-container class="page-wrapper main-document-container">
    <Preloader v-show="preloader"/>
    <div v-show="!preloader">
      <b-row v-if="isHomePage" no-gutters>
        <b-col :cols="headerCols" class="transition" ref="headCol">
          <Header></Header>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col v-if="isHomePage" cols="2">
          <LeftSideBar/>
        </b-col>
        <b-col :cols="routerCols">
          <div
            :style="{'height': containerHeight + 'px'}"
            class="router-container"
          >
            <router-view/>
            <modals-container/>
          </div>
        </b-col>
        <b-col v-if="isHomePage && !isManager">
          <!-- <div class="right-sidebar-container"> -->
            <RightSideBar @sidebarToggle="sidebarStaus"/>
          <!-- </div> -->
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios'
// @ is an alias to /src
import LeftSideBar from '@/components/left-side-bar.vue'
import RightSideBar from '@/components/right-side-bar.vue'
import Header from '@/components/header.vue'

export default {
  name: 'HomePage',
  components: {
    LeftSideBar,
    RightSideBar,
    Header,
  },
  data() {
    return {
      sidebarOpen: false,
      headerCols: 12,
      routerCols: 10,
      preloader: false,
    }
  },
  head: {
    script: [
      { type: 'text/javascript', src: '/static/js/gtag.js', async: true},
    ],
  },
  beforeCreate() {
    if (this.isAuth) {
      this.$store.dispatch('getUserProfile').then(() => {
        console.log('PROFILE', this.$store.getters.userProfile)
        this.preloader = false
      })
    }
  },
  created() {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('removeToken').then(() => {
            this.$router.push('/login')
          })
        }
        throw err;
      });
    });
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    authStatus() {
      return this.$store.getters.authStatus === 'success'
    },
    isHomePage() {
      if(this.$route.name === 'startPage' || this.$route.name === 'resetPw') {
        this.routerCols = 12;
        return false;
      } else {
        this.routerCols = 10;
        return true;
      }
    },
    isManager() {
      return this.$store.getters.isManager;
    },
    containerHeight() {
      if(this.isHomePage) {
        return document.documentElement.clientHeight - 88
      } else return document.documentElement.clientHeight
    },
    userId() {
      return this.$store.getters.userId;
    }
    // mainContentHeight() {
    //   return document.documentElement.clientHeight - this.$refs.headCol.offsetHeight();
    // }
  },
  methods: {
    sidebarStaus(isOpen) {
      this.sidebarOpen = isOpen;
    }
  },
  watch: {
    sidebarOpen() {
      this.sidebarOpen ? this.headerCols = 9 : this.headerCols = 12;
    },
    $route(to, from){
      this.currRouter = to.name;
    },
    // userId() {
    //   this.$store.dispatch('getUserProfile');
    // }
  }
}
</script>

<style scoped lang="scss">

 .page-wrapper {
   overflow: hidden;
   min-height: 100vh;
 }

 .transition {
   transition: .4s all ease;
 }

 .router-container {
   overflow-y: auto;
   padding-right: 35px;
   width: 100%;
   box-sizing: content-box;
   margin-top: 55px;
 }

 .right-sidebar-container {
   height: 100vh;
   overflow-y: auto;
   padding-right: 35px;
   width: 100%;
   box-sizing: content-box;
 }

</style>
