<template>
  <div>
    <div class="order-edit">
      <div
        @click="close"
        class="order-edit__close-btn"
      >+</div>
      <div class="order-edit__top-section">
        <div class="order-edit__img-wrap">
          <div class="plaque-slider"
               v-if="plaquesExists"
               @contextmenu="rightClick($event)"
          >
            <div
              v-show="showNavCursors"
              class="plaque-slider__control__nav_left"
              @click="prevPlaque()"

            >
            </div>
            <div class="plaque-slider__wrapper js-slider">
              <div class="plaque-slider__body" :style='{left: sliderOffsetLeft + "px"}'>
                <div
                  class="plaque-slider__img js-slide"
                  v-for="plaque in plaques"
                  :key="plaque.id"
                  :style='"background-image: url(" + plaque.image_thumbnail_large + ")"'
                >
                </div>
              </div>
            </div>
            <div
              v-show="showNavCursors"
              class="plaque-slider__control__nav_right"
              @click="nextPlaque()"
              :disabled="sliderDisabledRight"
            >
            </div>
          </div>
          <img v-else v-lazy="originPlaque.image_thumbnail_large" alt="" class="order-edit__img">
        </div>
        <div class="order-edit__plaque-name">{{ originPlaque.name }}</div>
      </div>
      <div class="order-edit__middle-section">
        <div class="order-edit__group">
          <wmSelect
            :options="plaqueFormats"
            @updateOption="newCustomPlaque.size = $event"
            :customItemStyle="{'width': '150px', 'height': '35px'}"
            :placeholder="$t('plaques_table.format')"
          />
          <div v-if="validateErrors.format" class="error">
            {{ $t('errorTexts.required') }}
          </div>
        </div>
        <div class="order-edit__checkbox-group pos-rel">
          <div class="location-inter order-edit__loc-i">{{ $t('plaques_table.location_inter') }}</div>
          <label v-show="!is10x15Format" class="wm-checkbox">
            <div class="order-edit__checkbox-title">
              {{ $t('order_edit.cb_on_base') }}
            </div>
            <input
              v-model="newCustomPlaque.on_pedestal"
              class="wm-checkbox__hidden-input"
              name="all"
              type="checkbox"
            >
            <div class="wm-checkbox__item"></div>
          </label>
          <label v-show="!isSmallFormat && !is10x15Format" class="wm-checkbox pos-rel">
            <div class="order-edit__checkbox-title">{{ $t('order_edit.cb_on_plate') }}</div>
            <input
              v-model="newCustomPlaque.on_plate"
              class="wm-checkbox__hidden-input"
              name="all"
              type="checkbox"
            >
            <div class="wm-checkbox__item"></div>
          </label>
          <label v-show="is10x15Format" class="wm-checkbox pos-rel">
            <div class="order-edit__checkbox-title">{{ $t('order_edit.aColler') }}</div>
            <input
              v-model="newCustomPlaque.a_coller"
              class="wm-checkbox__hidden-input"
              name="all"
              type="checkbox"
            >
            <div class="wm-checkbox__item"></div>
          </label>
          <label v-show="is10x15Format" class="wm-checkbox pos-rel">
            <div class="order-edit__checkbox-title">{{ $t('order_edit.socleSansInter') }}</div>
            <input
              v-model="newCustomPlaque.socle_sans"
              class="wm-checkbox__hidden-input"
              name="all"
              type="checkbox"
            >
            <div class="wm-checkbox__item"></div>
          </label>
          <label v-show="is10x15Format" class="wm-checkbox pos-rel">
            <div class="order-edit__checkbox-title">{{ $t('order_edit.socleEmplacement') }}</div>
            <input
              v-model="newCustomPlaque.socle_inter"
              class="wm-checkbox__hidden-input"
              name="all"
              type="checkbox"
            >
            <div class="wm-checkbox__item"></div>
          </label>
        </div>
<!--        <div class="order-edit__group">-->
<!--          <div class="el-with-help-button">-->
<!--            <input-->
<!--              v-model="newCustomPlaque.text"-->
<!--              class="order-edit__input wm-input text-input"-->
<!--              :placeholder="$t('order_edit.text')"-->
<!--              type="text"-->
<!--            >-->
<!--            <HelpButton :helpText="$t('order_edit.help_text')"/>-->
<!--          </div>-->
<!--          <div v-if="validateErrors.text" class="error">-->
<!--            {{ $t('errorTexts.required') }}-->
<!--          </div>-->
<!--        </div>-->
        <input
          v-model="newCustomPlaque.amount"
          class="order-edit__input wm-input"
          :placeholder="$t('order_edit.amount')"
          type="text"
        >
        <button
          @click.prevent="addCustomPlaque(originPlaque.id)"
          class="order-edit__add-btn wm-btn"
          :disabled="!isFormСompleted"
        >
          {{ $t('order_edit.add_btn') }}
        </button>
      </div>
      <div class="order-edit__bottom-section">
        <div class="order-edit__bm-group">
          <div
            v-for="item in customPlaques"
            :key="item.id"
            class="order-edit__bm">
            <div class="order-edit__bm-data">
              <span>{{ item.size }}</span> |
              <span>{{ item.on_pedestal ? $t('order_edit.cb_on_base') : '-' }}</span> |
              <span>{{ item.on_plate ? $t('order_edit.cb_on_plate') : '-' }}</span> |
              <span>{{ item.a_coller ? $t('order_edit.aColler') : '-' }}</span> |
              <span>{{ item.socle_sans ? $t('order_edit.socleSansInter') : '-' }}</span> |
              <span>{{ item.socle_inter ? $t('order_edit.socleEmplacement') : '-' }}</span> |
              <span>{{ item.amount }}</span>
            </div>
            <div
              @click.prevent="delCustomPlaque(item.id)"
              class="order-edit__bm-close-btn"></div>
          </div>
          <!-- <div class="order-edit__bm">
            <div class="order-edit__bm-data">
              <span>{{ $t('orders_table.format') }}</span> -
              <span>{{ $t('order_edit.inter_base') }}</span> -
              <span>{{ $t('order_edit.inter_plate') }}</span> -
              <span>{{ $t('orders_table.text') }}</span> -
              <span>{{ $t('order_edit.amount') }}</span>
            </div>
            <div class="order-edit__bm-close-btn">+</div>
          </div> -->
        </div>
        <div class="order-edit__btn-container">
          <button
            @click.prevent="submit"
            class="order-edit__validate-btn wm-btn"
            :disabled="customPlaques.length === 0"
          >
            {{ $t('order_edit.validate') }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HelpButton from '@/components/help-button.vue'
import WmSelect from '@/components/wm-select.vue'
import { ImageCopyrightsMixin } from '@/mixins/common'

export default {
  name: 'OrderEdit',
  props: {
    plaque: Object,
    plaques: Array,
    order: [String, Number],
    orderPlaques: Array,
  },
  components: {
    HelpButton,
    WmSelect,
  },
  mixins: [
    ImageCopyrightsMixin,
  ],
  data() {
    return {
      sliderAllCount: 0,
      sliderActive: 1,
      sliderOffsetLeft: 0,
      sliderOffsetStep: 0,
      showNavCursors: true,
      newCustomPlaque: {
        original: { category: { name: this.plaque.category.name}},
        size: undefined,
        on_pedestal: false,
        on_plate: false,
        text: ' ',
        erp_id: this.$store.getters.erpId,
        amount: 1,
        order: +this.order || null,
        a_coller: false,
        socle_sans: false,
        socle_inter: false,
      },
      newCustomPlaqueStored: null,
      newOrderData: {
        product_list: []
      },
      currOrderPlaques: this.orderPlaques,
      selectedTextOption: undefined,
      formatOption: undefined,
      isSmallFormat: false,
      is10x15Format: false,
      validateErrors: {
        text: false,
        format: false,
      },
      isAddBtnDisabled: true,
    }
  },
  mounted() {
    this.initSlider()
    window.addEventListener('resize', () => {
      this.initSlider()
      this.openSlide(this.sliderActive)
    })
    this.$store.dispatch('getCustomPlaques');
    this.$store.dispatch('getPlaqueFormats', this.plaque.id);
  },
  computed: {
    originPlaque () {
      if (this.plaquesExists) {
        return this.plaques[this.sliderActive - 1]
      }

      return this.plaque
    },
    plaquesExists () {
      return !(typeof this.plaques === 'undefined' || this.plaques === null)
    },
    sliderDisabledLeft () {
      if (this.plaquesExists) {
        if (this.sliderActive === 1) {
          return true
        }
      }
      return false
    },
    sliderDisabledRight () {
      if (this.plaquesExists) {
        if (this.sliderActive === this.sliderAllCount) {
          return true
        }
      }
      return false
    },
    customPlaques() {
      let currCustomPlaques = [];
      if(this.order) {
        currCustomPlaques = this.currOrderPlaques;
      } else {
        currCustomPlaques = this.$store.getters.customPlaques
      }
      currCustomPlaques = currCustomPlaques.filter(item => item.original.id == this.originPlaque.id)
      return currCustomPlaques;

    },
    plaqueFormats() {
      const formats = {};
      this.$store.getters.plaqueFormats.forEach(element => {
        formats[element.id] = `${element.width} × ${element.height} cm`;
      });
      return formats;
    },
    isFormСompleted() {
      if((this.newCustomPlaque.text.length > 0) && this.newCustomPlaque.size) {
        return true
      } else return false
    },
  },
  methods: {
    textOption(option) {
      this.selectedTextOption = option;
      this.checkboxChange();
    },
    // checkboxChange() {
    //   this.newCustomPlaque.pedestal_label = this.predestalChecked ? this.textOptions[this.selectedTextOption] : '';
    //   this.newCustomPlaque.plate_label = this.plateChecked ? this.textOptions[this.selectedTextOption] : '';
    // },
    isFormValid() {
      this.validateErrors.format = this.newCustomPlaque.size === undefined;
      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        return true
      }
      return false
    },
    addCustomPlaque(plaqueId) {
      if(this.isFormValid()) {
          this.$store.dispatch('addCustomPlaque', {
          plaqueId: plaqueId,
          plaqueData: this.newCustomPlaque
        }).then((data) => {
          this.$store.dispatch('getPlaques')
          if(this.order) { this.currOrderPlaques.push(data) }
          this.clearCustomPlaqueValues();
        })
      } else this.isAddBtnDisabled = true;
    },
    delCustomPlaque(customPlId) {
      this.$store.dispatch('deleteCustomPlaque', customPlId).then((data) => {
        this.$store.dispatch('getPlaques')
        if(this.order) this.currOrderPlaques = this.currOrderPlaques
          .filter(item => item.id != customPlId)
      });
    },
    clearCustomPlaqueValues() {
      this.newCustomPlaque.on_pedestal = false;
      this.newCustomPlaque.on_plate = false;
      this.newCustomPlaque.text = ' ';
      this.newCustomPlaque.amount = 1;
      this.newCustomPlaque.a_coller = false;
      this.newCustomPlaque.socle_sans = false;
      this.newCustomPlaque.socle_inter = false;
    },
    close() {
      this.$emit('close');
    },
    submit() {
      console.log('this.order', this.order);
      if(this.order) {
        this.currOrderPlaques.forEach(element => {
          this.newOrderData.product_list.push(element.id)
        });
        this.$store.dispatch('patchErpPlaqueOrder', {
          erpId: this.$store.getters.erpId,
          orderId: this.$route.params.id,
          orderData: this.newOrderData
        }).then(() => {
          this.$emit('close');
        })
      } else {
        this.$emit('close');
      }
    },
    initSlider: function () {
      if (this.plaquesExists) {
        let sliderBody = this.$el.querySelector('.js-slider')
        let sliderSlidies = sliderBody.querySelectorAll('.js-slide')

        this.sliderOffsetStep = sliderBody.getBoundingClientRect().width
        this.sliderAllCount = sliderSlidies.length
        this.sliderActive = this.plaques.indexOf(this.plaque) + 1
        this.openSlide(this.sliderActive)
      } else {
        this.showNavCursors = false
      }
    },
    nextPlaque: function () {
      if (this.sliderActive < this.sliderAllCount) {
        this.sliderActive += 1
      }
      if (this.sliderActive === this.sliderAllCount) {
        this.sliderActive = 1
      }
      this.openSlide(this.sliderActive)
    },
    prevPlaque: function () {
      if (this.sliderActive > 1) {
        this.sliderActive -= 1
      }
      if (this.sliderActive === 1) {
        this.sliderActive = this.sliderAllCount
      }
      this.openSlide(this.sliderActive)
    },
    openSlide: function (id) {
      if (id >= 0 && id <= this.sliderAllCount) {
        this.sliderActive = id
        this.sliderOffsetLeft = -(this.sliderActive * this.sliderOffsetStep - this.sliderOffsetStep)
      }
    },
  },
  watch: {
    // predestalChecked() {
    //   this.checkboxChange();
    // },
    // plateChecked() {
    //   this.checkboxChange();
    // },
    newCustomPlaque: {
      deep: true,
      handler() {
        this.isSmallFormat = false;
        this.is10x15Format = false;

        if(this.plaqueFormats[this.newCustomPlaque.size].includes('15 × 40.00 cm')) {
          this.isSmallFormat = true;
        }

        // Handle the special format with separate options
        if (this.plaqueFormats[this.newCustomPlaque.size].includes('15.00 × 10.00 cm')) {
          this.isSmallFormat = false; // Just to prevent an errors in the future
          this.is10x15Format = true;
        }

        this.isAddBtnDisabled = false;
        console.log('>>>', !this.isSmallFormat && !this.is10x15Format, this.isSmallFormat, this.is10x15Format);
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .order-edit {
    font-family: 'DaxRegular';
    background-color: #d6d6d6;
    border-radius: 15px;
    padding: 30px 20px 10px;

    &__close-btn {
      position: absolute;
      top: -8px;
      right: 10px;
      transform: rotate(45deg);
      color: #707070;
      font-size: 33px;
      font-family: serif;
      cursor: pointer;
    }

    &__top-section {
      margin-bottom: 45px;
    }

    &__img-wrap {
      margin: 0 auto;
      width: 90%;
      height: auto;
      overflow: hidden;
      margin-bottom: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      max-width: 80%;
      margin: 0 30px;
    }

    &__plaque-name {
      text-align: center;
    }

    &__middle-section {
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      margin: 0 20% 20px;
    }

    &__checkbox-group {
      display: flex;
      justify-content: left;
      width: 100%;
      margin-left: 20px;

      label {
        min-width: 160px;
      }
    }

    &__checkbox-title {
      position: absolute;
      top: -15px;
      text-align: center;
      font-size: 12px;
    }

    &__loc-i {
      font-size: 12px;
      top: -35px;
    }

    &__input {
      width: 100px;
      height: 35px;
    }

    &__add-btn {
      width: 90px;
      border-radius: 100px;
      font-size: 14px;
      height: 35px;
      margin-left: 20px;
    }

    &__add-btn:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &__bottom-section {
      display: flex;
      justify-content: space-between;
    }

    &__bm-group {
      width: 50%;
    }

    &__bm {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      border: 1px solid #b9b9b9;
      border-radius: 100px;
      padding: 5px 10px 5px 15px;
      margin-bottom: 10px;
    }

    &__bm-close-btn {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      background-image: url(/static/img/trash.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }

    &__btn-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    &__validate-btn {
      width: 200px;
      height: 35px;
      border-radius: 100px;
      font-size: 14px;
      text-transform: uppercase;
    }

    &__validate-btn:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .text-input {
      min-width: 350px;
    }

    &__group {
      position: relative;
    }
  }

  .error {
    position: absolute;
    bottom: -15px;
    color: red;
    font-size: 14px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100px;
  }

  .plaque-slider {

    $slider-height: 600px;
    $slider-width: 900px;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d6d6d6;

    &__wrapper {
      width: $slider-width;
      height: $slider-height;
      position: relative;
      overflow: hidden;
    }

    &__img {
      min-width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      flex: 1 100%;
    }

    &__body {
      min-width: 100%;
      height: $slider-height;
      display: flex;
      position: relative;
      align-items: stretch;
      transition: all .5s ease;
    }

    &__control {

      &__nav_left,
      &__nav_right {
        display: inline-block;
        width: 10px;
        height: 10px;
        padding: 5px;
        box-sizing: border-box;
        border-top: 4px solid #707070;
        border-right: 4px solid #707070;
        background-color: #d6d6d6;
        cursor: pointer;
        margin: 0 10px 25px;
        transition: transform 150ms linear;
        &[disabled] {
          opacity: 0.2;
          border-color: black;
        }
      }

      &__nav_left {
        transform: rotate(-135deg);
        &:active {
          transform: rotate(-135deg) scale(0.9);
        }
      }

      &__nav_right {
        transform: rotate(45deg);
        &:active {
          transform: rotate(45deg) scale(0.9);
        }
      }
    }
  }

</style>
