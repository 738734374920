import Vue from 'vue';
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://dbc72765ec1e4fc8817cd552c32e16d9@o170229.ingest.sentry.io/1800642',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  });
}

