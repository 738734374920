<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="start-page">
      <img class="start-page__logo" alt="" src="/static/img/wm_logo.png">
      <b-row no-gutters>
        <b-col cols="6">
          <SignIn/>
        </b-col>
        <b-col cols="6">
          <SignUp/>
          <a class="start-page__tel" href="tel:0285528744">02 85 52 87 44</a>
        </b-col>
      </b-row>
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/sign-in-form.vue'
import SignUp from '@/components/sign-up-form.vue'

export default {
  name: 'StartPage',
  components: {
    SignIn,
    SignUp,
  },
  data() {
    return {
      preloader: false
    }
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">

</style>
