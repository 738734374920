import { pageTitleMixin } from '@/mixins/common';
import { deepCopy, twoDigitStr } from "@/include/helpers";
import commentForm from '@/components/comment-form';
import productSubtitle from '@/components/product-subtitle';
import WmSelect from '@/components/wm-select.vue';
import standardPreview from '@/views/orders/preview/standard_preview.vue';
import InterPersonalize from './inter-personalize.vue';

export default {
  name: "InterEpitafie",
  data() {
    return {
      isOrderEdit: !!this.$route.params.id,
      schema: undefined,
      filteredSchema: undefined,
      dataModel: {},
      preloader: true,
      productData: undefined,
      pageTitle: undefined,
      pageTitleDescription: undefined,
      commentFormData: undefined,
      commentData: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
        comment: undefined,
      },
      interSchema: undefined,
      epitafieSchema: undefined,
      customSchemaField: undefined,
      customSchema: [],
      customModel: {},
      interCount: 1,
      epitafieCount: 1,
      customCount: 1,
      testSchema: {
        fields: [
          {
            type: "upload",
            label: "Photo",
            model: "photo",
            inputName: "photo",
            multiple: true,
            onChanged(model, schema, event) {
              model.photo = event.target.files
            }
          },
        ]
      },
      interEpitafieList: [],
      testModel: {
        photo: undefined,
      },
      newElCount: 0,
      addButton: {
        classes: 'lol',
        label: 'Add',
        onclick: () => {},
      },
      removeButton: {
        classes: 'lol-rem',
        label: 'Remove',
        onclick: () => {},
      },
      activeFilterDropdown: false,
      filterItems: [],
      selectedFilterItem: '',
      previewStatus: false,
      isFormValid: false,
      interPersonalizeData: [],
      interPersonalizeDropdownData: [],
      personalizeValidateStatus: false,
      personalizeOrderData: [],
      isPersonalizeFormValid: false,
      showPersonalize: true,
    }
  },
  components: {
    commentForm,
    WmSelect,
    productSubtitle,
    standardPreview,
    InterPersonalize,
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    console.log('this.$route.params.slug', this.$route.params.slug);
    this.retrieveProductData();
  },
  updated() {
    setTimeout(this.changeHelpTextPosition(), 2000);
  },
  methods: {
    retrieveProductData() {
      this.$store.dispatch('getProductSchema', this.$route.params.slug)
        .then(( data )=> {
            console.log('data', data);
            if (this.$route.params.slug.includes('inter-and-epitafie')) {
              this.preloader = true;
              this.schema = data.schema;
              this.interSchema = data.schema.fields.slice(0, 2);
              this.epitafieSchema = data.schema.fields.slice(2, 4);
              this.customSchemaField = data.schema.fields.slice(4, 6);
              this.$store.dispatch('getInterEpitafieList').then((data) => {
                this.interEpitafieList = data;
                console.log('this.interEpitafieList', this.interEpitafieList);
                this.preloader = false;
              });
              // Prepare dropdown filter
              this.filterItems['3'] = this.$t('sliders_page.all_category')
              this.filterItems['0'] = this.$t('productPageFilter.inter')
              this.filterItems['1'] = this.$t('productPageFilter.epitafie')
              this.filterItems['2'] = this.$t('productPageFilter.custom')
              this.activeFilterDropdown = true
            }

            this.productTitle = data.name;
            this.pageTitle = data.name;
            this.pageTitleDescription = data.page_title;
        });
      this.$store.dispatch('getInterPersonalizeList')
        .then((data) => {
          console.log('>>>>>', )
          this.interPersonalizeData = data
          this.interPersonalizeDropdownData = data.map(item => item.title);
        })
    },
    changeHelpTextPosition(){
      const pageContainer = document.getElementsByClassName('product-page');
      const helpTextElements = document.getElementsByClassName('helpText');
      const pageContainerRect = pageContainer[0].getBoundingClientRect();
      const maxContainerWidth = pageContainerRect.x + pageContainerRect.width;
      if (helpTextElements.length > 1) {
        for (let i = 0; i <= helpTextElements.length - 1; i += 1) {
          if (typeof helpTextElements[i] === 'object') {
            let elementContainer = helpTextElements[i].getBoundingClientRect();
            let maxElementWidth = elementContainer.x + elementContainer.width;
            if (maxElementWidth > maxContainerWidth) {
              helpTextElements[i].style.top = '18px';
              helpTextElements[i].style.left = `-${maxElementWidth - maxContainerWidth}px`;
            } else {
              helpTextElements[i].style.top = '0';
              helpTextElements[i].style.left = '20px';
            }
          }
          if (i > 10) {
            break
          }
        }
      }
    },
    reindexCounter(data=undefined) {
      let _tmpData = this.dataModel;
      if (data) {
        _tmpData = data
      }
      let interCount = Object.keys(_tmpData).filter(item => item.includes('inter') && !item.includes('quantity')).length;
      let epitafieCount = Object.keys(_tmpData).filter(item => item.includes('epitafie') && !item.includes('quantity')).length;
      this.interCount = interCount;
      this.epitafieCount = epitafieCount;
      console.log('reindexCounter', this.interCount, this.epitafieCount, this.customCount)
    },
    buildCustomSchemaEdit(data) {
      this.schema.fields = {};
      this.customSchema = [];
      this.reindexCounter(data);
      let _inter = 0;
      let _epitafie = 0;
      let _custom = 0;
      let result = undefined;

      for (let objKey in data) {
        if (objKey.includes('inter') || objKey.includes('epitafie')) {
          if (!objKey.includes('quantity')) {
            if (objKey.includes('inter')) {
              _inter += 1;
              if (_inter === this.interCount) {
                result = this.addSchemaEdit(objKey, true);
                _inter +=1;
              } else {
                result = this.addSchemaEdit(objKey);
              }
            };
            if (objKey.includes('epitafie')) {
              _epitafie += 1;
              if (_epitafie === this.epitafieCount) {
                result = this.addSchemaEdit(objKey, true);
                _epitafie +=1;
              } else {
                result = this.addSchemaEdit(objKey);
              }
            };
            this.customSchema = this.customSchema.concat(result[0]);
          }
        }
      }
      if (_custom === 0) {
        this.customSchema = this.customSchema.concat(this.customSchemaField);
      }

      console.log('_tmpSchema - edit', this.customSchema, this.dataModel);
      this.schema.fields = this.customSchema;
      this.dataModel = data;
      this.addHiddenSubmit()
    },
    buildFormInterEpitafie() {
      const groupSet = {
        groups: [
          {
            legend: this.$t('product.inter'),
            fields: []
          },
          {
            legend: this.$t('product.epitafie'),
            fields: []
          },
        ]
      };
      const field = {
        inputName: '', // custom00
        inputType: 'number',
        label: '', //custom
        model: '', // custom00
        multiple: false,
        required: false,
        styleClasses: ['col-md-4', 'inter-epitafie-item'],
        type: 'input',
        validator: "number",
      };
      this.customSchema = [];
      this.customModel = {};
      const newSchema = deepCopy(groupSet);

      if (this.interEpitafieList && this.interEpitafieList.length) {

        this.interEpitafieList.forEach((item) => {
          if (item.item_type === 'inter') {
            const _tmpField = deepCopy(field);
            _tmpField.inputName = item.code;
            _tmpField.label = `${item.text} (${item.code})`;
            _tmpField.model = item.code;
            newSchema.groups[0].fields = newSchema.groups[0].fields.concat([_tmpField]);
            this.customModel[item.code] = '';
          }

          if (item.item_type === 'epitafie') {
            const _tmpField = deepCopy(field);
            _tmpField.inputName = item.code;
            _tmpField.label = `${item.text} (${item.code})`;
            _tmpField.model = item.code;
            newSchema.groups[1].fields = newSchema.groups[1].fields.concat([_tmpField]);
            this.customModel[item.code] = '';
          }
        })
      }
      this.schema = newSchema;
      this.addHiddenSubmit();
      this.dataModel = this.customModel;
    },
    addHiddenSubmit() {
      console.log('Add hidden submit button');
      if (!this.schema.fields) {
        this.schema.fields = []
      }
      this.schema.fields.push({
        type: 'submit',
        id: 'order-submit-btn',
        onSubmit: this.submitOrder,
        styleClasses: ['order-submit-hidden-btn'],
        validateBeforeSubmit: true,
      });
    },
    addSchemaEdit(nameField, isAddButton=false) {
      let _tmpSchema = [];
      let _newField = undefined;
      if (nameField.includes('inter')) {
        _newField = deepCopy(this.interSchema);
      }
      if (nameField.includes('epitafie')) {
        _newField = deepCopy(this.epitafieSchema);
      }

      _newField.forEach(item => {
        item.inputName = nameField;
        item.model = nameField;
        if (item.label.includes('quantity')) {
          item.label = this.$t('order_edit.quantity');
          item.inputName = `quantity_${nameField}`;
          item.model = `quantity_${nameField}`
        }
        // add buttons
        if (item.inputName.includes('quantity')) {
          const _removeBtn = deepCopy(this.removeButton);
          _removeBtn.onclick = () => {
            this.removeItemForm(deepCopy(item.inputName), deepCopy(item.model));
          };
          item.buttons = [_removeBtn];
          if (isAddButton) {

            const _addBtn = deepCopy(this.addButton);
            _addBtn.onclick = () => {
              if (nameField.includes('inter')) {
                this.dataModel[`quantity_inter${twoDigitStr(this.interCount)}`] = '';
                this.dataModel[`inter${twoDigitStr(this.interCount)}`] = '';
                this.interCount++;
                this.customModel = Object.assign({}, this.dataModel);
              }
              if (nameField.includes('epitafie')) {
                this.dataModel[`quantity_epitafie${twoDigitStr(this.epitafieCount)}`] = '';
                this.dataModel[`epitafie${twoDigitStr(this.epitafieCount)}`] = '';
                this.epitafieCount++;
                this.customModel = Object.assign({}, this.dataModel);
              }
            };
            item.buttons = [_addBtn]
          }
        }
      });
      _tmpSchema = _tmpSchema.concat(_newField);

      return [_tmpSchema]
    },
    reindexSchema() {
      let newIndexEpitafie = 0;
      let newIndexInter = 0;
      let newIndexCustom = 0;
      const newDataModel = {};
      const newSchema = [];

      for (let dataItem in this.dataModel) {
        console.log('dataItem', dataItem);
        if (dataItem.includes('quantity_inter')) {
          let _tmpName = dataItem.toString();
          let interIndex = _tmpName.slice(-2);
          newDataModel[`quantity_inter${twoDigitStr(newIndexInter)}`] = this.dataModel[dataItem];
          newDataModel[`inter${twoDigitStr(newIndexInter)}`] = this.dataModel[`inter${interIndex}`];
          newIndexInter++;
        }
      }
      for (let dataItem in this.dataModel) {
        if (dataItem.includes('quantity_epitafie')) {
          let _tmpName = dataItem.toString();
          let epitafieIndex = _tmpName.slice(-2);
          newDataModel[`quantity_epitafie${twoDigitStr(newIndexEpitafie)}`] = this.dataModel[dataItem];
          newDataModel[`epitafie${twoDigitStr(newIndexEpitafie)}`] = this.dataModel[`epitafie${epitafieIndex}`];
          newIndexEpitafie++;
        }
      }
      console.log('reindexSchema', newDataModel, newSchema);
      this.dataModel = newDataModel;
      this.buildCustomSchemaEdit(newDataModel);
    },
    removeItemForm(fieldName, modelName) {
      const _index = this.customSchema.findIndex(item => item.inputName === fieldName);
      this.customModel = this.dataModel;
      console.log('removeItemForm1', _index, fieldName, deepCopy(this.customModel), deepCopy(this.customSchema));
      if (_index) {
        delete this.customModel[this.customSchema[_index-1].inputName];
        delete this.customModel[this.customSchema[_index].inputName];
        this.customSchema.splice(_index-1, 2);
      }
      console.log('removeItemForm2', _index, fieldName, this.customModel, this.customSchema);
      this.schema.fields = this.customSchema;
      this.dataModel = this.customModel;
    },
    submit() {
      document.getElementById('order-submit-btn').click();
    },
    showPreviewForm() {
      this.$refs.productForm.validate()
      this.personalizeValidateStatus = true
      setTimeout(() => this.checkValidationStatus(), 200)
    },
    checkValidationStatus() {
      console.log('checkValidationStatus', this.isFormValid && this.isOrderEmpty(), this.isPersonalizeFormValid)
      if (this.isFormValid && this.isOrderEmpty() || this.isPersonalizeFormValid) {
        this.previewStatus = true
      }
    },
    confirmOrder(id) {
      this.preloader = true;
      this.$store.dispatch('confirmErpOrder', {
        erpId: this.$store.getters.erpId,
        orderId: id,
      }).then(() => {
        this.preloader = false;
        this.$router.push({name: 'ordersTable'});
      })
    },
    submitOrder() {
      const orderData = this.commentFormData;
      orderData.data = this.dataModel;
      orderData.status = 1;
      orderData.category = this.$route.params.slug;
      let orderId = undefined;
      this.$store.dispatch('createOrder', {
        erpId: this.$store.getters.erpId,
        data: orderData
      }).then((data) => {
        orderId = data.id;
        this.confirmOrder(orderId)
      })
    },
    isOrderEmpty() {
      return !Object.values(this.dataModel).every(x => x === null || x === '')
    },
    onProductFormValidated(isValid, _) {
      this.isFormValid = isValid;
    },
    clickOnPrevieButton() {
      document.getElementById('preview-button').click();
    },
    getPersonalizeOrderData(value) {
      this.personalizeOrderData = value
    },
    personalizeErrorHandler(status) {
      console.log('status', status)
      if (status) {
        this.isPersonalizeFormValid = true
        const _data = JSON.parse(JSON.stringify(this.personalizeOrderData))
        _data.forEach((item) => {
          const optionValue = this.interPersonalizeData.find(itemInter => itemInter.id === item.option).title
          item.option = optionValue
        })
        this.dataModel['personalize'] = _data
      } else {
        this.personalizeOrderData = []
        this.personalizeValidateStatus = false
        this.isPersonalizeFormValid = false
      }
    }
  },
  watch: {
    // TODO how to start after help text loaded?!!!
    interEpitafieList: {
      handler() {
        this.buildFormInterEpitafie();
      }
    },
    schema: {
      deep: true,
      handler() {
        if (typeof this.schema === 'object') {
          this.filteredSchema = this.schema
        }
      }
    },
    selectedFilterItem: {
      handler() {
        if (Object.keys(this.schema).find(x => x ==='groups')) {
          const _tmp = Object.assign({}, this.schema)
          let newGroup = []

          // Filter schema by dropdown value
          switch (this.selectedFilterItem) {
            case '0':
              newGroup = _tmp.groups[0]
              this.showPersonalize = false
              break
            case '1':
              newGroup = _tmp.groups[1]
              this.showPersonalize = false
              break
            case '2':
              this.showPersonalize = true
              break
            default:
              this.showPersonalize = true
          }

          // Check if newGroup is defined and consist right data
          if (Object.keys(newGroup).find(x => x === 'legend')) {
            _tmp.groups = [newGroup]
          }
          this.filteredSchema = (this.showPersonalize && this.selectedFilterItem === '2') ? {} : Object.assign({}, _tmp)

        } else {
          this.filteredSchema = Object.assign({}, this.schema)
        }
      }
    }
  }
}
