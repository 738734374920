<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div class="wm-modal__title">{{ isEditModal ? $t('edit_addr_modal.title') : $t('add_addr_modal.title') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="wm-modal__subtitle">{{ isEditModal ? $t('edit_addr_modal.subtitle') : $t('add_addr_modal.subtitle') }}</div>
      <div class="contact-info">
        <div class="contact-info__form">
          <wmSelect 
            v-if="!isEditModal"
            :options="addrType"
            @updateOption="addrInfo.address_type=$event"
            :customItemStyle="{'width': '269px', 'height': '35px'}"
            :placeholder="$t('add_addr_modal.addr_type')"
          />
          <div class="erp-info__form wm-group">
            <input 
              v-model="addrInfo.street"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.address')" 
              type="text"
            >
            <div v-if="validateErrors.street" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input 
              v-model="addrInfo.cell_phone"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.mob_phone')" 
              type="text"
            >
            <div v-if="validateErrors.cell_phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input 
              v-model="addrInfo.fixed_phone"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.phone')" 
              type="text"
            >
            <div v-if="validateErrors.fixed_phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <input 
            v-model="addrInfo.postal_code"
            class="contact-info__input wm-input" 
            :placeholder="$t('cabinet.postal_code')" 
            type="text"
          >
          <div class="erp-info__form wm-group">
            <input 
              v-model="addrInfo.email"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.email_nr')" 
              type="text"
            >
            <div v-if="validateErrors.email" class="error">
              {{ $t('errorTexts.error') }}
            </div>
          </div>
          <input 
            v-model="addrInfo.city"
            class="contact-info__input wm-input" 
            :placeholder="$t('cabinet.city')" 
            type="text"
          >
          
        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import { validateEmail, validateSiteUrl } from '@/include/helpers'

export default {
  name: 'editAddressModal',
  components: {
    WmSelect
  },
  props: {
    addrId: Number,
    isEditModal: false
  },
  data() {
    return {
      addrType: {
        1: this.$t('cabinet.delivery_address'),
        2: this.$t('cabinet.billing_address')
      },
      addrInfo: {
        address_type: 1,
        street: '',
        city: '',
        postal_code: '',
        cell_phone: '',
        fixed_phone: '',
        email: '',
      },
      validateErrors: {
        street: false,
        cell_phone: false,
        fixed_phone: false,
        email: false,
      },
      erpId: undefined,
    }
  },
  beforeMount() {
    this.erpId = this.$store.getters.erpId;
    if(this.isEditModal){
      this.$store.dispatch('getErpAddress', {
        erpId: this.erpId, 
        addrId: this.addrId
      }).then((data) => {
        this.addrInfo = data;
      })
    }
  },
  computed: {
    currErpAddress() {
      return this.$store.getters.currErpAddress;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.validateErrors.street = typeof this.addrInfo.street === "undefined" || this.addrInfo.cell_phone === '';
      this.validateErrors.cell_phone = typeof this.addrInfo.cell_phone === "undefined" || this.addrInfo.cell_phone === '';
      this.validateErrors.fixed_phone = typeof this.addrInfo.fixed_phone === "undefined" || this.addrInfo.fixed_phone === '';
      this.validateErrors.email = typeof this.addrInfo.email === "undefined" || !validateEmail(this.addrInfo.email);

      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        if(this.isEditModal){
          this.$store.dispatch('updateErpAddress', {
            erpId: this.erpId, 
            addrId: this.addrId,
            addrInfo: this.addrInfo
          }).then(() => {
            this.$emit('close');
          })
        } else {
          this.$store.dispatch('addErpAddress', {
            erpId: this.erpId, 
            addrInfo: this.addrInfo
          }).then(() => {
            this.$emit('close');
          })
        }
      }
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .contact-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }
  }

</style>
