<template>
  <div>
    <div
      @mouseover.prevent="showHelpText"
      @mouseleave.prevent="closeHelpText"
      ref="helpBtn"
      class="help-button">
      <div
        ref="helpText"
        v-if="showHelp"
        class="help-text"
        :class="{ 'left' : isPosLeft }"
      >{{ helpText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpButton',
  props: {
    helpText: String
  },
  data() {
    return {
      showHelp: false,
    }
  },
  mounted() {

  },
  computed: {
    isPosLeft() {
      const btn = this.$refs.helpBtn.getBoundingClientRect();
      const container = document.querySelector('.main-document-container').getBoundingClientRect();
      if(container.right < btn.right + 350) {
        return true;
      } else return false;
    },
  },
  methods: {
    showHelpText() {
      this.showHelp = true;
    },
    closeHelpText() {
      this.showHelp = false;
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .help-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 14px;
    background-image: url(/static/img/help.png);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .help-text {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: justify;
    background-color: #444;
    color: #fff;
    min-width: 300px;
    max-width: 350px;
    padding: 10px;
    border-radius: 6px;
    font-family: 'DaxRegular';
    z-index: 15;
    margin-bottom: 10px;
    box-shadow: 2px 2px 6px rgba(0,0,0,.5);
    font-size: 12px;
  }

  .help-text.left {
    right: 0;
    left: auto;
  }

</style>
