import { render, staticRenderFns } from "./inter-personalize.vue?vue&type=template&id=dc0a8a3e&scoped=true&"
import script from "./inter-personalize.js?vue&type=script&lang=js&"
export * from "./inter-personalize.js?vue&type=script&lang=js&"
import style0 from "./inter-personalize.scss?vue&type=style&index=0&id=dc0a8a3e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc0a8a3e",
  null
  
)

export default component.exports