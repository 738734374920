<template>
  <div>
    <Preloader v-show="preloader"/>
    <div class="about">
      <div v-html="aboutInfo"></div>
    </div>
  </div>

</template>

<script>
import { pageTitleMixin } from '@/mixins/common';

export default {
  name: 'About',
  data() {
    return {
      preloader: true,
      pageTitle: this.$t('page_titles.about'),
    }
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    this.$store.dispatch('getPages', this.$route.name).then(() => {
      this.preloader = false
    })
  },
  computed: {
    aboutInfo() {
      return this.$store.getters.currPage;
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .about {
    padding: 20px 30px;
    text-align: justify;
  }
</style>
