import { HTTP } from '@/api/common';

export const managerApi = {
  createEnterprise( data ) {
    return HTTP.post('enterprise/', data, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },
  createEnterpriseContact( data ) {
    return HTTP.post(`enterprise/${data.erpId}/contacts/`, data.clientInfo, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  createEnterpriseAddress( data ) {
    return HTTP.post(`enterprise/${data.erpId}/addresses/`, data.addressInfo, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  checkEnterpriseName( data ) {
    return HTTP.post('/enterprise/verify/', data, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getEnterpriseList() {
    return HTTP.get('/enterprise/', { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getEnterprise(erpId) {
    return HTTP.get(`/enterprise/${erpId}/`, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getUserKit(erpId) {
    return HTTP.get(`/enterprise/${erpId}/userkit`, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        if (response) return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  updateEnterprise(erpId, erpInfo) {
    return HTTP.put(`/enterprise/${erpId}/`, erpInfo, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  createUserKit(erpId, data) {
    return HTTP.post(`/enterprise/${erpId}/userkit/`, data, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  generateContract(erpId) {
    return HTTP.get(`/contract/${erpId}/`, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then(response => {
        console.log('API response generateContract', response.data);
        if (response) return response.data
      })
      .catch((err) => console.log('API ERROR', err, err.detail))
  },
};
