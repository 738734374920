<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div class="wm-modal__title">{{ isEditModal ? $t('edit_addr_modal.title') : $t('add_addr_modal.title') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="wm-modal__subtitle">{{ isEditModal ? $t('edit_addr_modal.subtitle') : $t('add_addr_modal.subtitle') }}</div>
      <div class="contact-info">
        <div class="contact-info__form">
          <wmSelect
            :options="contactType"
            @updateOption="contactInfo.gender=$event"
            :customItemStyle="{'width': '269px', 'height': '35px'}"
            :placeholder="$t('contactModal.mr')"
            :selected="getGenderValue()"
          />
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.position"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.func')"
              type="text"
            >
            <div v-if="validateErrors.func" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.first_name"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.firstName')"
              type="text"
            >
            <div v-if="validateErrors.firstName" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.last_name"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.lastName')"
              type="text"
            >
            <div v-if="validateErrors.lastName" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.fixed_phone"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.phone')"
              type="text"
            >
            <div v-if="validateErrors.phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.cell_phone"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.mobilePhone')"
              type="text"
            >
            <div v-if="validateErrors.mobilePhone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="contact-info__form wm-group">
            <input
              v-model="contactInfo.email"
              class="contact-info__input wm-input"
              :placeholder="$t('contactModal.email')"
              type="text"
            >
            <div v-if="validateErrors.email" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <label class="wm-checkbox">
            {{ $t('contactModal.dm') }}
            <input class="wm-checkbox__hidden-input" name="all" type="checkbox" v-model="contactInfo.decisionMaker">
            <div class="wm-checkbox__item"></div>
          </label>
        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import { validateEmail, validateSiteUrl } from '@/include/helpers'

export default {
  name: 'editContactModal',
  components: {
    WmSelect
  },
  props: {
    contactId: Number,
    isEditModal: false
  },
  data() {
    return {
      contactType: {
        1: this.$t('contactModal.mr'),
        2: this.$t('contactModal.ms')
      },
      contactInfo: {
        gender: 1,
        first_name: '',
        last_name: '',
        position: '',
        fixed_phone: '',
        cell_phone: '',
        email: '',
        decisionMaker: '',
      },
      validateErrors: {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        func: false,
        mobilePhone: false,
      },
      erpId: undefined,
    }
  },
  beforeMount() {
    this.erpId = this.$store.getters.erpId;
    if(this.isEditModal){
      this.$store.dispatch('getErpContact', {
        erpId: this.erpId, 
        contactId: this.contactId
      }).then((data) => {
        this.contactInfo = data;
      })
    }
  },
  computed: {
  
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.validateErrors.firstName = typeof this.contactInfo.first_name === "undefined" || this.contactInfo.first_name === '';
      this.validateErrors.lastName = typeof this.contactInfo.last_name === "undefined" || this.contactInfo.last_name === '';
      this.validateErrors.phone = typeof this.contactInfo.fixed_phone === "undefined" || this.contactInfo.fixed_phone === '';
      this.validateErrors.mobilePhone = typeof this.contactInfo.cell_phone === "undefined" || this.contactInfo.cell_phone === '';
      this.validateErrors.email = typeof this.contactInfo.email === "undefined" || !validateEmail(this.contactInfo.email);
      this.validateErrors.func = typeof this.contactInfo.position === "undefined" || this.contactInfo.position === '';

      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        if(this.isEditModal){
          this.$store.dispatch('updateErpContact', {
            erpId: this.erpId, 
            id: this.contactId,
            contactInfo: this.contactInfo
          }).then(() => {
            this.$emit('close');
          })
        } else {
          this.$store.dispatch('addErpContact', {
            erpId: this.erpId, 
            contactInfo: this.contactInfo
          }).then(() => {
            this.$emit('close');
          })
        }
      }
    },
    getGenderValue() {
      if (this.contactInfo) {
        if (Object.keys(this.contactInfo).length > 0) {
          return Object.values(this.contactType)[this.contactInfo.gender - 1];
        }
      }
      return Object.values(this.contactType)[0];
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .contact-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }
  }

</style>
