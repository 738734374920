import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  REFRESH_TOKEN,
  SIGNUP_SUCCESS,
  SET_PAGE_TITLE,
  UPDATE_NEWS_LIST,
  UPDATE_USER_PROFILE,
  UPDATE_ERP_CONTACTS,
  UPDATE_ERP_ASDDRESSES,
  UPDATE_CURR_ERP_ADDR,
  UPDATE_CUSTOM_PLAQUES,
  UPDATE_PLAQUES,
  UPDATE_PLAQUE_FORMATS,
  UPDATE_DOCUMENTS_DOCUMENT,
  UPDATE_DOCUMENTS_TARIF,
  UPDATE_DOCUMENTS_CATALOG,
  UPDATE_NEW_CLIENT_DATA_ERP,
  UPDATE_NEW_CLIENT_DATA_CONTACTS,
  UPDATE_NEW_CLIENT_DATA_ADDRESS,
  REMOVE_NEW_CLIENT_DATA_ADDRESS,
  UPDATE_CURRENT_PAGE,
  UPDATE_NEW_CLIENT_DATA_USER_KIT,
  UPDATE_VIDEO_LIST,
} from '@/store/mutation-types';

export const authMutations = {
  [SIGNUP_SUCCESS]: (state, token) => {
    state.userToken = token
  },
  [AUTH_REQUEST]: (state) => {
    state.authStatus = 'loading'
  },
  [AUTH_SUCCESS]: (state, userData) => {
    state.authStatus = 'success'
    state.userToken = userData.token
    state.userId = userData.user_id
    state.isManager = userData.is_manager
  },
  [AUTH_ERROR]: (state) => {
    state.authStatus = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.authStatus = ''
    state.userToken = ''
    // state.erpId = undefined
    state.userProfile = {}
  },
  [REFRESH_TOKEN]: (state, data) => {
    state.userToken = data.token
    state.isManager = data.is_manager
    state.userId = data.user_id
  },
  [UPDATE_NEWS_LIST]: (state, data) => {
    state.newsList = data
  },
  [UPDATE_USER_PROFILE]: (state, data) => {
    state.userProfile = data
    if (!state.isManager) {
      state.erpId = data.profile.enterprise.id
    }
  },
  [UPDATE_ERP_CONTACTS]: (state, data) => {
    state.erpContacts = data
  },
  [UPDATE_ERP_ASDDRESSES]: (state, data) => {
    state.erpAddresses = data
  },
  [UPDATE_CURR_ERP_ADDR]: (state, data) => {
    state.currErpAddress = data
  },
  [UPDATE_CUSTOM_PLAQUES]: (state, data) => {
    state.customPlaques = data
  },
  [UPDATE_PLAQUES]: (state, data) => {
    state.plaques = data
  },
  [UPDATE_PLAQUE_FORMATS]: (state, data) => {
    state.plaqueFormats = data
  },
  [UPDATE_DOCUMENTS_DOCUMENT]: (state, data) => {
    state.documentsList = data
  },
  [UPDATE_DOCUMENTS_CATALOG]: (state, data) => {
    state.catalogDocumentsList = data
  },
  [UPDATE_DOCUMENTS_TARIF]: (state, data) => {
    state.tarifDocumentsList = data
  },
  [UPDATE_VIDEO_LIST]: (state, data) => {
    state.videoList = data
  },
};

export const commonMutations = {
  [SET_PAGE_TITLE]: (state, title) => {
    state.pageTitle = title
  },
  [UPDATE_NEW_CLIENT_DATA_ERP]: (state, payload) => {
    state.newClientDataErp = payload
  },
  [UPDATE_NEW_CLIENT_DATA_USER_KIT]: (state, payload) => {
    state.newClientDataUserKit = payload
  },
  [UPDATE_NEW_CLIENT_DATA_CONTACTS]: (state, payload) => {
    state.newClientDataContacts = payload
  },
  [UPDATE_NEW_CLIENT_DATA_ADDRESS]: (state, payload) => {
    state.newClientDataAddress = payload
  },
  [UPDATE_CURRENT_PAGE]: (state, payload) => {
    state.currPage = payload
  },
};
