import Vue from 'vue';

const logger = function () {
  let oldConsoleLog = null;
  let pub = {};

  pub.enableLogger = function enableLogger() {
    if (oldConsoleLog == null)
      return;

    window.console.log = oldConsoleLog;
  };

  pub.disableLogger = function disableLogger() {
    oldConsoleLog = console.log;
    window.console.log = function () {
    };
    window.console.info = function () {
    };
    window.console.error = function () {
    };
  };

  return pub;
}();

if (process.env.NODE_ENV === 'production') {
  logger.disableLogger();
} else {
  logger.enableLogger();
}

window.logger = logger;
Vue.prototype.logger = logger;

export default { logger };
