import { HTTP, HTTP_SIGNUP } from '@/api/common';
import router from '../router';

export const authApi = {
  obtainToken (authData) {
    return HTTP_SIGNUP.post('auth/login/', authData)
      .then((response) => {
        return response.data
      })
      .catch((error) => {

      })
  },
  registerUser (userData) {
    return HTTP_SIGNUP.post('signup/', userData, {
      headers: {
        'Content-Type': 'application/json'
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('USER REGISTRATION ERROR', error)
        return error
      })
  },
  refreshToken (token) {
    return HTTP.post('auth/refresh-token/', token)
      .then((response) => {
        if (response.data.hasOwnProperty('non_field_errors')) {
          this.$store.dispatch('removeToken').then(() => {
            this.$router.push('/login')
          })
        }
        if (response) {
          return response.data
        }
      })
      .catch((error) => {
      })
  },
}

export const wmApi = {
  async getNewsList () {
    const _tmp = await HTTP.get('news/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
    return _tmp
  },
  async getUserProfile () {
    const _tmp =  await HTTP.get('profile/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
    return _tmp;
  },
  async updateUserProfile (profileInfo) {
    const _tmp = await HTTP.put('profile/', profileInfo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
    return _tmp;
  },
  getErpContacts (erpId) {
    return HTTP.get(`enterprise/${erpId}/contacts/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getErpContact (erpId, contactId) {
    return HTTP.get(`enterprise/${erpId}/contacts/${contactId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  deleteErpContacts (erpId, id) {
    return HTTP.delete(`enterprise/${erpId}/contacts/${id}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  updateErpContact (erpId, id, contactInfo) {
    return HTTP.put(`enterprise/${erpId}/contacts/${id}/`, contactInfo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  addErpContact (erpId, contactInfo) {
    return HTTP.post(`enterprise/${erpId}/contacts/`, contactInfo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getErpAddresses (erpId) {
    return HTTP.get(`enterprise/${erpId}/addresses/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getErpAddress (erpId, addrId) {
    return HTTP.get(`enterprise/${erpId}/addresses/${addrId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  updateErpAddress (erpId, addrId, addrInfo) {
    return HTTP.put(`enterprise/${erpId}/addresses/${addrId}/`, addrInfo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  addErpAddress (erpId, addrInfo) {
    return HTTP.post(`enterprise/${erpId}/addresses/`, addrInfo, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  deleteErpAddress (erpId, addrId) {
    return HTTP.delete(`enterprise/${erpId}/addresses/${addrId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getCustomPlaques () {
    return HTTP.get(`catalog/custom-plaques/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getPlaques () {
    return HTTP.get(`catalog/plaques/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        console.log('getPlaques - action', response);
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  addCustomPlaque (plaqueId, plaqueData) {
    return HTTP.post(`catalog/plaques/${plaqueId}/customize/`, plaqueData, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  deleteCustomPlaque (plaqueId) {
    return HTTP.delete(`catalog/custom-plaques/${plaqueId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getPlaqueFormats (plaqueId) {
    return HTTP.get(`catalog/plaques/${plaqueId}/customize/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getDocuments (docType) {
    return HTTP.get(`documents/?doc_type=${docType}`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getVideoList () {
    return HTTP.get('video/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error('API ERROR getVideoList', error)
      })
  },
  getPages (slug) {
    return HTTP.get(`pages/${slug}/`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
}
