import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'
import StartPage from './views/start-page.vue'
import ResetPw from './views/reset-pw.vue'
import SlidersPage from '@/views/sliders-page.vue'
import OrdersTable from '@/views/orders-table-page.vue'
import PlaquesTable from '@/views/custom-plaques-table-page.vue'
import MainPage from '@/views/index.vue'
import orderNew from '@/views/order-new'
import inters from '@/views/orders/inter-epitafie/inters.vue'
import MyCabinet from '@/views/my-cabinet-page.vue'
import OrderDownloadPage from '@/views/order-download-page.vue'
import clientNew from '@/views/client-new'
import DocumentsTable from '@/views/doc-and-tarifs-table-page'
import ClientsTable from '@/views/clients-table-page.vue'
import VideoPage from '@/views/video-page.vue'
import About from '@/views/About'
import orderCollection from '@/views/orders/collection/collection_index.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('wmToken')
  if (!isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const isManager = (to, from, next) => {
  if (store.getters.isManager && store.getters.isAuthenticated) {
    next()
    return
  }
  next(from)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      name: 'home',
      component: MainPage,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      name: 'startPage',
      component: StartPage,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/reset',
      name: 'resetPw',
      component: ResetPw,
    },
    {
      path: '/',
      name: 'mainPage',
      component: MainPage,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/pm-collections',
      name: 'collections',
      component: SlidersPage,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/my-cabinet',
      name: 'myCabinet',
      component: MyCabinet,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/custom-plaques/:id?',
      name: 'customPlaques',
      component: PlaquesTable,
      beforeEnter: ifAuthenticated,
      props: true,
    },
    {
      name: 'orderNew',
      path: '/order/:slug',
      beforeEnter: ifAuthenticated,
      component: orderNew,
      props: true,
    },
    {
      name: 'imageCollection',
      path: '/order/collection/:slug',
      beforeEnter: ifAuthenticated,
      component: orderCollection,
      props: true,
    },
    {
      name: 'intersView',
      path: '/order/inter-epitafie/:slug',
      beforeEnter: ifAuthenticated,
      component: inters,
      props: true,
    },
    {
      name: 'ordersTable',
      path: '/orders',
      component: OrdersTable,
      beforeEnter: ifAuthenticated,
    },
    {
      name: 'clientsTable',
      path: '/enterprises',
      component: ClientsTable,
      beforeEnter: isManager,
    },
    {
      path: '/order-download/:status',
      name: 'orderDownload',
      component: OrderDownloadPage,
      beforeEnter: ifAuthenticated,
    },
    {
      name: 'clientNew',
      path: '/clients/:id?',
      component: clientNew,
      props: true,
    },
    {
      path: '/documents',
      name: 'document',
      component: DocumentsTable,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tarifs',
      name: 'tarif',
      component: DocumentsTable,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/catalog',
      name: 'catalog',
      component: DocumentsTable,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/video',
      name: 'video',
      component: VideoPage,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/about/',
      name: 'about',
      beforeEnter: ifAuthenticated,
      component: About,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/landing',
      name: 'landing',
      beforeEnter() {location.href = 'https://www.westmemory.fr'},
    },
  ]
})

router.beforeEach((to, from, next) => {
  console.log('Router', from, to, to.name !== 'resetPw');
  if (to.name !== 'startPage' && to.name !== 'resetPw') {
    store.dispatch('refreshToken').then((data) => {
      console.log('refreshToken', data)
      if (data) {
        next()
      } else next('/login')
    })
  }
  next()
})

export default router
