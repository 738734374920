<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="plaque-orders-page">
      <b-row no-gutters class="mr-5">
        <b-col cols="12" class="table-wrap">
          <b-table-simple borderless>
            <b-thead>
              <b-tr class="table-head-row">
                <b-td>{{ $t('plaques_table.reference') }}</b-td>
                <b-td>{{ $t('plaques_table.visual') }}</b-td>
                <b-td>{{ $t('plaques_table.format') }}</b-td>
                <b-td class="pos-rel">
                  {{ $t('plaques_table.location_inter') }}
                  {{ $t('plaques_table.pedestal') }}
                </b-td>
                <b-td>{{ $t('plaques_table.plate') }}</b-td>
                <b-td>{{ $t('plaques_table.aColler') }}</b-td>
                <b-td>{{ $t('plaques_table.socleSansInter') }}</b-td>
                <b-td>{{ $t('plaques_table.socleEmplacement') }}</b-td>
                <b-td>{{ $t('plaques_table.text') }}</b-td>
                <b-td>{{ $t('plaques_table.amount') }}</b-td>
                <b-td></b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="item in customPlaques"
                :key="item.id"
                class="table-body-row"
              >
                <b-td>{{item.original.name}}</b-td>
                <b-td><div class="table-body-img"><img v-lazy="item.original.image_thumbnail" alt="" @contextmenu="rightClick($event)"></div></b-td>
                <b-td>{{item.size}}</b-td>
                <b-td>
                  <label class="wm-checkbox">
                    <input
                      :checked="item.on_pedestal"
                      class="wm-checkbox__hidden-input"
                      name="all" type="checkbox"
                      disabled>
                    <div class="wm-checkbox__item"></div>
                  </label>
                </b-td>
                <b-td>
                  <label class="wm-checkbox">
                    <input
                      :checked="item.on_plate"
                      class="wm-checkbox__hidden-input"
                      name="all" type="checkbox"
                      disabled>
                    <div class="wm-checkbox__item"></div>
                  </label>
                </b-td>
                <b-td>
                  <label class="wm-checkbox">
                    <input
                      :checked="item.a_coller"
                      class="wm-checkbox__hidden-input"
                      name="all" type="checkbox"
                      disabled>
                    <div class="wm-checkbox__item"></div>
                  </label>
                </b-td>
                <b-td>
                  <label class="wm-checkbox">
                    <input
                      :checked="item.socle_sans"
                      class="wm-checkbox__hidden-input"
                      name="all" type="checkbox"
                      disabled>
                    <div class="wm-checkbox__item"></div>
                  </label>
                </b-td>
                <b-td>
                  <label class="wm-checkbox">
                    <input
                      :checked="item.socle_inter"
                      class="wm-checkbox__hidden-input"
                      name="all" type="checkbox"
                      disabled>
                    <div class="wm-checkbox__item"></div>
                  </label>
                </b-td>
                <b-td>{{item.pedestal_label}}{{item.plate_label}}</b-td>
                <b-td>{{item.amount}}</b-td>
                <b-td>
                  <div class="table-d-e-btn">
                    <div
                      @click.prevent="delCustomPlaque(item.id)"
                      class="table-d-btn"
                    ></div>
                    <div
                      @click.prevent="openOrderEdit(item.original)"
                      class="table-e-btn"
                    ></div>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row no-gutters class="mt-auto">
        <b-col cols="9" offset-md="1">
          <CommentForm
            :defaultCommentData="commentData"
            :isPreviewStatus="true"
            :isShowBackButton=true
            @updateData="updateOrderData"
            @submitForm="submitPlaqueOrder"

          />
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import CommentForm from '@/components/comment-form.vue'
import OrderEdit from '@/components/order-edit.vue'
import { pageTitleMixin, ImageCopyrightsMixin } from '@/mixins/common'

export default {
  name: 'PlaquesTable',
  components: {
    CommentForm,
    OrderEdit
  },
  mixins: [ pageTitleMixin, ImageCopyrightsMixin, ],
  data() {
    return {
      pageTitle: undefined,
      productSlug: 'collection-privee', //! Warning Hard code slug
      isOrderEdit: !!this.$route.params.id,
      customOrderData: {
        status: 1,
        contact: undefined,
        address: undefined,
        product_list: [],
        comment: '',
        reference_text: ''
      },
      orderPlaques: [],
      commentData: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
        comment: undefined,
      },
      preloader: true,
    }
  },
  beforeMount() {
    this.$store.dispatch('getProductList').then(() => {
      const productObj = this.$store.getters.productList.find(x => x.slug === this.productSlug)
      if (typeof productObj === 'undefined' || productObj === null) {
        this.pageTitle = this.$t('defaultTitle')
      } else {
        this.pageTitle = productObj.name
      }
    })
    this.initPlaques();
  },
  computed: {
    customPlaques() {
      let plaques = [];
      if(this.isOrderEdit) {
        plaques = this.orderPlaques;
      } else {
        plaques = this.$store.getters.customPlaques;
      }
      return plaques;
    },
    erpId() {
      return this.$store.getters.erpId;
    }
  },
  methods: {
    initPlaques() {
      if(this.isOrderEdit) {
        this.$store.dispatch('getErpPlaqueOrder', {
          erpId: this.$store.getters.erpId,
          orderId: this.$route.params.id,
        }).then((data) => {
          this.orderPlaques = data.product_list;
          this.commentData.contact = data.contact.id;
          this.commentData.address = data.address.id;
          this.commentData.comment = data.comment;
          this.commentData.reference_text = data.reference_text;
          this.preloader = false;
        })
      } else {
        this.$store.dispatch('getCustomPlaques')
        this.preloader = false;
      }
    },
    openOrderEdit(item) {
      this.$modal.show(OrderEdit,
      {
        plaque: item,
        order: this.$route.params.id,
        orderPlaques: this.orderPlaques,
      },{
        width: '920px',
        height: 'auto',
        scrollable: true,
        classes: 'plaque-edit-modal',
      });
    },
    delCustomPlaque(customPlId) {
      this.$store.dispatch('deleteCustomPlaque', customPlId);
      this.initPlaques();
    },
    updateOrderData(data) {
      this.customOrderData.contact = data.contact;
      this.customOrderData.address = data.address;
      this.customOrderData.comment = data.comment;
      this.customOrderData.reference_text = data.reference_text;
    },
    submitPlaqueOrder() {
      this.customPlaques.forEach(element => {
        if(!this.customOrderData.product_list.includes(element.id)){
          this.customOrderData.product_list.push(element.id)
        }
      });
      if(this.isOrderEdit) {
        this.$store.dispatch('updateErpPlaqueOrder', {
          erpId: this.erpId,
          orderId: this.$route.params.id,
          orderData: this.customOrderData
        }).then(() => {
          this.$router.push({name: `ordersTable`});
        })
      } else {
        this.$store.dispatch('createPlaqueOrder', {
          erpId: this.erpId,
          orderData: this.customOrderData
        }).then((data) => {
          this.preloader = true;
          this.$store.dispatch('confirmErpPlaqueOrder', {
            erpId: this.erpId,
            orderId: data.id,
          }).then(() => {
            this.preloader = false;
            this.$router.push({name: `ordersTable`});
          })
        })
      }

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .plaque-orders-page {
    position: relative;
  }

  .table {
    margin-left: 10px;
    th, td {
      padding: 0.25rem;
    }
  }

  .table-wrap {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
  }

  .table-head-row {
    border-bottom: 1px solid #b9b9b9;
  }

 .table-body-row td{
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
  }

  .table-body-img {
    display: inline-block;
    width: 100px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  .table-d-e-btn {
    width: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-d-btn {
    width: 18px;
    height: 18px;
    position: relative;
    cursor: pointer;
  }
  .table-d-btn:after,.table-d-btn:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 16px;
    background-color: #707070;
    top: 10px;
  }
  .table-d-btn:before {
    transform: rotate(45deg);
  }
  .table-d-btn:after {
    transform: rotate(-45deg);
  }

  .table-e-btn {
    width: 18px;
    height: 18px;
    background-image: url(/static/img/edit.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

</style>
