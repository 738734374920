import axios from 'axios'
import router from '../router';

// Generate CSRF token for AJAX requests
function getCookie (name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function errorResponseHandler(error) {
  console.log('Global error handler', error.message, error.response.status);
  if (error.response.status === 401 || ['Token expired', 'Invalid token' ].includes(error.message)) {
    if (router.name !== 'startPage' || router.name !== 'resetPw') {
      router.push({ name: 'startPage' })
    }
    throw new Error('Invalid token detected')
  }
  return error.response
}

export const HTTP = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    'X-CSRFToken': getCookie('csrftoken')
  }
})

HTTP.interceptors.response.use(response => response, errorResponseHandler);

export const HTTP_SIGNUP = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'X-CSRFToken': getCookie('csrftoken')
  }
})
