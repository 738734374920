import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { authGetters, commonGetters, wmGetters } from '@/store/general/getters';
import { productGetters } from '@/store/product/getters';
import { orderGetters } from '@/store/order/getters';
import { authMutations, commonMutations } from '@/store/general/mutations';
import { productMutations } from '@/store/product/mutations';
import { orderMutations } from '@/store/order/mutations';
import { authActions, commonActions, wmActions } from '@/store/general/actions';
import { productActions } from '@/store/product/actions';
import { orderActions } from '@/store/order/actions';
import { managerMutations } from "@/store/manager/mutations";
import { managerActions } from "@/store/manager/actions";
import { managerGetters } from "@/store/manager/getters";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  strictMode: true,
  storage: window.localStorage,
  reducer: state => ({
    erpId: state.erpId,
    isManager: state.isManager,
    newClientDataErp: state.newClientDataErp,
    newClientDataContacts: state.newClientDataContacts,
    newClientDataAddress: state.newClientDataAddress,
    newClientDataUserKit: state.newClientDataUserKit,
    userProfile: state.userProfile,
  }),
});

const state = {
  userToken: localStorage.getItem('wmToken') || '', // Storage for the user's token
  authStatus: '', // Storage for the user's status
  userId: undefined,
  isManager: undefined,
  productList: [],
  pageTitle: undefined,
  newsList: [],
  userProfile: {},
  erpId: undefined,
  erpList: [],
  currErp: {},
  currUserKit: {},
  erpContacts: [],
  erpAddresses: [],
  currErpAddress: {},
  plaques: [],
  customPlaques: [],
  erpPlaqueOrders: [],
  erpOrders: [],
  orders: [],
  plaqueFormats: [],
  documentsList: [],
  catalogDocumentsList: [],
  tarifDocumentsList: [],
  newClientDataErp: {},
  newClientDataUserKit: {},
  newClientDataContacts: [],
  newClientDataAddress: [],
  currPage: undefined,
  videoList: [],
}

export default new Vuex.Store({
  strict: true,
  state,
  getters: Object.assign(
    {},
    authGetters,
    productGetters,
    commonGetters,
    wmGetters,
    orderGetters,
    managerGetters,
  ),
  actions: Object.assign(
    {},
    authActions,
    productActions,
    commonActions,
    wmActions,
    orderActions,
    managerActions,
  ),
  mutations: Object.assign(
    {},
    authMutations,
    productMutations,
    commonMutations,
    managerMutations,
    orderMutations,
    { RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION },
  ),
  plugins: [vuexLocal.plugin],
});
