<template>
    <div class="start-page-form__container start-page__horizontal-line">
    <h2 class="start-page-form__title">{{ $t('signup.registration') }}</h2>
    <div class="start-page-form__wrap">
      <vue-form-generator
        :schema="schema"
        :model="model"
        :options="formOptions"
      ></vue-form-generator>
      OU
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUp',
  data() {
    return {
      model: {
        first_name: '',
        last_name: 'test',
        company: '',
        postal_code: '',
        email: '',
        phone: '',
        message: '',
      },
      schema: {
        fields: [{
          type: 'input',
          inputType: 'text',
          model: 'first_name',
          placeholder: this.$t('signup.name'),
          required: true,
          validator: 'string',
        },
        {
          type: 'input',
          inputType: 'text',
          model: 'company',
          placeholder: this.$t('signup.company'),
          required: true,
          validator: 'string',
        },
        {
          type: 'input',
          inputType: 'text',
          model: 'postal_code',
          placeholder: this.$t('signup.postal_code'),
          required: true,
          validator: this.postalCodeValidate,
        },
        {
          type: 'input',
          inputType: 'text',
          model: 'email',
          placeholder: this.$t('signup.email'),
          required: true,
          validator: 'email',
        },
        {
          type: 'input',
          inputType: 'text',
          model: 'phone',
          placeholder: this.$t('signup.phone'),
          required: true,
          validator: this.phoneValidate,
        },
        {
          type: 'textArea',
          model: 'message',
          placeholder: this.$t('signup.message'),
          hint: this.$t('signup.required'),
          styleClasses: ['start-page-form__ta'],
          validator: 'string',
        },
        {
          type: 'submit',
          onSubmit: this.userRegistration,
          buttonText: this.$t('signup.signup'),
          model: 'message',
          styleClasses: ['start-page-form__btn'],
          validateBeforeSubmit: true,
        }]
      },
      formOptions: {
        validateAfterChanged: true
      }
    }
  },
  methods: {
    userRegistration() {
      const userData = {
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        enterprise: this.model.company,
        postal_code: this.model.postal_code,
        email: this.model.email,
        cell_phone: this.model.phone,
      }
      if(this.model.message) {
        userData.message = this.model.message
      }
      this.$store.dispatch( 'userRegistration', userData).then((response) => {
        if(response instanceof Error) {
          alert(this.$t('signup.userExists'))
        } else {
          alert(this.$t('signup.alert_msg'))
        }
      })
    },
    postalCodeValidate(value) {
      let re = /^(?:[0-8]\d|9[0-8])\d{3}$/;
      if(!re.test(value)){
        return ["Invalid postal code!"]
      } else {
        return []
      }
    },
    phoneValidate(value) {
      let re = /^[+]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$/;
      if(!re.test(value)){
        return ["Invalid phone number!"]
      } else {
        return []
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .start-page-form__ou {
    font-size: 14px;
  }
  // .start-page-form__btn .field-wrap{
  //   margin-top: 0;
  //   margin-bottom: 5px;
  // }
</style>
