import {
  PRODUCTS_LIST,
  UPDATE_ERP_PLAQUE_ORDERS,
  UPDATE_ERP_ORDERS,
  UPDATE_ORDERS,
} from '@/store/mutation-types';
import { orderApi } from '@/api/order';

export const orderActions = {
  // async getProductList ({ commit }) {
  //   await productApi.productList()
  //     .then((data) => {
  //       console.log('getProductList -- action', data);
  //       commit(PRODUCTS_LIST, data)
  //     })
  //     .catch((error) => console.log(error));
  // },
  // async getProductSchema ({ commit }, productSlug) {
  //   return await productApi.productSchema(productSlug)
  //     .then((data) => {
  //       console.log('getProductSchema -- action', data);
  //       return data
  //     })
  //     .catch((error) => console.log(error));
  // },
  async createOrder ({ commit }, { erpId, data }) {
    const order = await orderApi.createOrder(erpId, data)
      .then((data) => {
        console.log('createOrder -- action', data);
        commit(PRODUCTS_LIST, data)
        return data
      })
      .catch((error) => console.log(error));
    return order
  },
  async createPlaqueOrder ({ commit }, { erpId, orderData }) {
    const tmp = await orderApi.createPlaqueOrder(erpId, orderData)
      .then((data) => {
        console.log('createPlaqueOrder -- action', data);
        return data
      })
      .catch((error) => console.log(error));
    return tmp
  },
  getErpOrders ({ commit }, erpId) {
    return new Promise((resolve, reject) => {
      orderApi.getErpOrders(erpId)
        .then((data) => {
          commit(UPDATE_ERP_ORDERS, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpOrder ({ commit }, { erpId, orderId }) {
    return new Promise((resolve, reject) => {
      orderApi.getErpOrder(erpId, orderId)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  confirmErpOrder ({ dispatch }, { erpId, orderId }) {
    return new Promise((resolve, reject) => {
      orderApi.confirmErpOrder(erpId, orderId)
        .then((response) => {
          if (response) {
            // dispatch('getErpOrders', erpId)
            resolve(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateErpOrder ({ dispatch }, { erpId, orderId, orderData }) {
    return new Promise((resolve, reject) => {
      orderApi.updateErpOrder(erpId, orderId, orderData)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpPlaqueOrders ({ commit }, erpId) {
    return new Promise((resolve, reject) => {
      orderApi.getErpPlaqueOrders(erpId)
        .then((data) => {
          commit(UPDATE_ERP_PLAQUE_ORDERS, data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getErpPlaqueOrder ({ commit }, { erpId, orderId }) {
    return new Promise((resolve, reject) => {
      orderApi.getErpPlaqueOrder(erpId, orderId)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  confirmErpPlaqueOrder ({ dispatch }, { erpId, orderId }) {
    return new Promise((resolve, reject) => {
      orderApi.confirmErpPlaqueOrder(erpId, orderId)
        .then((response) => {
          if (response) {
            // dispatch('getErpPlaqueOrders', erpId)
            resolve(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateErpPlaqueOrder ({ dispatch }, { erpId, orderId, orderData }) {
    return new Promise((resolve, reject) => {
      orderApi.updateErpPlaqueOrder(erpId, orderId, orderData)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  patchErpPlaqueOrder ({ dispatch }, { erpId, orderId, orderData }) {
    return new Promise((resolve, reject) => {
      orderApi.patchErpPlaqueOrder(erpId, orderId, orderData)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getOrders ({ commit }) {
    return new Promise((resolve, reject) => {
      orderApi.getOrders()
        .then((data) => {
          commit(UPDATE_ORDERS, data)
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendFiles ({ commit }, data) {
    return new Promise((resolve, reject) => {
      orderApi.sendFiles(data)
        .then((data) => {
          resolve(data)
          return data
        })
        .catch(error => {
          reject(error)
        })
    })
  },
};
