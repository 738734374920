<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="orders-page">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="11" class="table-wrap">
          <!-- <button
            @click="confirmAllOrders()"
            class="wm-btn orders-page__btn"
          >
            {{ $t('orders_table.confirm') }}
          </button> -->
          <b-table-simple borderless>
            <b-thead>
              <b-tr class="table-head-row">
                <b-td>{{ $t('orders_table.reference') }}</b-td>
                <b-td>{{ $t('orders_table.contact') }}</b-td>
                <b-td>{{ $t('orders_table.date') }}</b-td>
                <b-td>{{ $t('orders_table.download') }}</b-td>
                <b-td>{{ $t('orders_table.status') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
              </b-tr>
              <b-tr
                v-for="(item, index) in orders"
                :key="index"
                class="table-body-row"
              >
                <b-td
                  class="table-clicable-el"
                  @click.prevent="item.is_confirm ? confirmMessage() : (item.type === 'Order' ? editOrder(item.category.slug, item.id) : editCustomOrder(item.id))"
                >{{ item.reference_text }}</b-td>
                <b-td
                  class="table-clicable-el"
                  v-if="item.contact"
                  @click.prevent="item.is_confirm ? confirmMessage() : (item.type === 'Order' ? editOrder(item.category.slug, item.id) : editCustomOrder(item.id))"
                >{{ `${item.contact.first_name} ${item.contact.last_name}` }}</b-td>
                <b-td
                  class="table-clicable-el"
                  @click.prevent="item.is_confirm ? confirmMessage() : (item.type === 'Order' ? editOrder(item.category.slug, item.id) : editCustomOrder(item.id))"
                >{{ formatDate(item.created_at) }}</b-td>
                <b-td>
                  <a :href="item.is_confirm ? item.file : '#'"
                    :class="{
                      'active': item.is_confirm,
                      'load': loadFiles && !item.is_confirm
                    }"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="download-link">
                  </a>
                </b-td>
                <b-td>{{ getOrderId(item) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { pageTitleMixin } from '@/mixins/common'

export default {
  name: 'OrdersTable',
  data() {
    return {
      preloader: true,
      pageTitle: this.$t('page_titles.orders'),
      loadFiles: false,
      confirmCount: 0,
      syncOrderCount: 0,
    }
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    // this.$store.dispatch('getErpPlaqueOrders', this.erpId)
    // this.$store.dispatch('getErpOrders', this.erpId).then(() => {
    //   this.preloader = false;
    // })
    this.$store.dispatch('getOrders').then(() => {
      this.preloader = false;
    });
  },
  computed: {
    erpPlaqueOrders() {
      return this.$store.getters.erpPlaqueOrders;
    },
    erpOrders() {
      return this.$store.getters.erpOrders;
    },
    orders() {
      return this.$store.getters.orders;
    },
    unconfirmedOrders() {
      const orders = this.$store.getters.orders;
      return orders ? orders.filter(item => !item.is_confirm) : []
    },
    erpId() {
      return this.$store.getters.erpId;
    },
  },
  methods: {
    confirmAllOrders() {
      this.loadFiles = true;
      this.orders.forEach((element) => {
        if(!element.is_confirm) {
          if(element.type === 'Order') {
            this.$store.dispatch('confirmErpOrder', {
              erpId: this.erpId,
              orderId: element.id,
            })
          } else {
            this.$store.dispatch('confirmErpPlaqueOrder', {
              erpId: this.erpId,
              orderId: element.id,
            })
          }
        }
      })
      this.checkOrdersStatus()
    },
    checkOrdersStatus() {
      const intervalID = setInterval(async () => {
        if(this.unconfirmedOrders.length >= 0 && this.syncOrderCount < 3) {
          await this.$store.dispatch('getOrders')
          this.syncOrderCount += 1
        } else {
          console.log('Clear interval');
          this.loadFiles = false;
          clearInterval(intervalID)
        }
      },4000)
    },
    formatDate(date) {
      const newDate = `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}`
      return newDate;
    },
    editOrder(slug, id) {
      this.$router.push(`/order/${slug}/${id}`);
    },
    editCustomOrder(id) {
      this.$router.push(`/custom-plaques/${id}`);
    },
    confirmMessage() {
      // alert(this.$t('orders_table.confirm_message'))
    },
    getOrderId(item) {
      if (item.is_confirm) {
        return (item.type === "PlaqueOrder") ? `p-${item.id}` : item.id
      }
      return '-'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .table-wrap {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
  }

  .table-head-row {
    border-bottom: 1px solid #b9b9b9;
  }

  .table-body-row td{
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
  }

  .table-clicable-el {
    cursor: pointer;
  }

  .download-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(/static/img/download.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.4;
    cursor: default;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
    }
    &.load {
      opacity: 1;
      background-image: url(/static/img/img_load.gif);
    }
  }

  .orders-page__btn {
    margin-left: auto;
  }

</style>
