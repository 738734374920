<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div class="wm-modal__title">{{ $t('edit_addr_modal.title') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="wm-modal__subtitle">{{ $t('edit_addr_modal.subtitle') }}</div>
      <div class="contact-info">
        <div class="contact-info__form">
          <div class="erp-info__form wm-group">
            <input 
              v-model="newProfileInfo.first_name"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.first_name')" 
              type="text"
            >
            <div v-if="validateErrors.first_name" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input 
              v-model="newProfileInfo.last_name"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.name')" 
              type="text"
            >
            <div v-if="validateErrors.last_name" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input 
              v-model="newProfileInfo.email"
              class="contact-info__input wm-input" 
              :placeholder="$t('cabinet.email_nr')" 
              type="text"
            >
            <div v-if="validateErrors.email" class="error">
              {{ $t('errorTexts.error') }}
            </div>
          </div>
          <input 
            v-model="newProfileInfo.profile.position"
            class="contact-info__input wm-input" 
            :placeholder="$t('cabinet.position')" 
            type="text"
          >
          <input 
            v-model="newProfileInfo.profile.cell_phone"
            class="contact-info__input wm-input" 
            :placeholder="$t('cabinet.mob_phone')" 
            type="text"
          >
          <input 
            v-model="newProfileInfo.profile.fixed_phone"
            class="contact-info__input wm-input" 
            :placeholder="$t('cabinet.phone')" 
            type="text"
          >
        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import { validateEmail, validateSiteUrl } from '@/include/helpers'

export default {
  name: 'editProfileModal',
  components: {
    WmSelect
  },
  data() {
    return {
      newProfileInfo: {},
      validateErrors: {
        first_name: false,
        last_name: false,
        email: false,
      },
    }
  },
  beforeMount() {
    this.newProfileInfo = this.$store.getters.userProfile;
  },
  computed: {
   
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.validateErrors.first_name = typeof this.newProfileInfo.first_name === "undefined" || this.newProfileInfo.first_name === '';
      this.validateErrors.last_name = typeof this.newProfileInfo.last_name === "undefined" || this.newProfileInfo.last_name === '';
      this.validateErrors.email = typeof this.newProfileInfo.email === "undefined" || !validateEmail(this.newProfileInfo.email);

      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        this.$store.dispatch('updateUserProfile', this.newProfileInfo).then(() => {
          this.$emit('close');
        })
      }
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .contact-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }
  }

</style>
