import { HTTP } from '@/api/common';
import store from '@/store/index';

export const productApi = {
  async productList() {

    const _tmp = await HTTP.get('/products/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      });
    return _tmp;
  },
  async productSchema(productSlug) {
    const _tmp = await HTTP.get(`/products/${productSlug}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      });
    return _tmp;
  },
  async interEpitafieList() {
    const _tmp = await HTTP.get('/catalog/inter-epifaie/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      });
    return _tmp;
  },
  async interPersonalizeList() {
    const _tmp = await HTTP.get('/catalog/inters/', { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
      }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      });
    return _tmp;
  }

};
