<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div v-if="!data"
           class="wm-modal__title">{{ $t('enterpriseModal.title') }}</div>
      <div v-if="data"
           class="wm-modal__title">{{ $t('enterpriseModal.titleEdit') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="erp-info">
        <div class="erp-info__form">
          <div class="erp-info__form wm-group">
            <input
              v-model="erpInfo.name"
              class="erp-info__input wm-input"
              :placeholder="$t('enterpriseModal.name')"
              type="text"
            >
            <div v-if="validateErrors.name" class="error">
              {{ $t('errorTexts.required') }}
            </div>
            <div v-if="validateErrors.usedName" class="error">
              {{ $t('errorTexts.usedEnterpriseName') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="erpInfo.tva"
              class="erp-info__input wm-input"
              :placeholder="$t('enterpriseModal.tva')"
              type="text"
            >
            <div v-if="validateErrors.tva" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="erpInfo.webSite"
              class="erp-info__input wm-input"
              :placeholder="$t('enterpriseModal.webSite')"
              type="text"
            >
            <div v-if="validateErrors.webSite" class="error">
              {{ $t('errorTexts.siteUrl') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="erpInfo.postalCode"
              class="erp-info__input wm-input"
              :placeholder="$t('enterpriseModal.postalCode')"
              type="text"
            >
            <div v-if="validateErrors.postalCode" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <textarea
            rows="5"
            cols="100"
            :placeholder="$t('enterpriseModal.comment')"
            v-model="erpInfo.comment"
            class=""
          >
          </textarea>

        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button
        :disabled="!isSubmit"
        class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue';
import { validateSiteUrl } from '@/include/helpers';

export default {
  name: 'enterpriseModal',
  components: {
    WmSelect
  },
  data() {
    return {
      addrType: {
        1: this.$t('cabinet.delivery_address'),
        2: this.$t('cabinet.billing_address')
      },
      erpInfo: {
        name: undefined,
        tva: undefined,
        webSite: undefined,
        comment: undefined,
        postalCode: undefined,
      },
      validateErrors: {
        name: false,
        usedName: false,
        tva: false,
        webSite: false,
        postalCode: false,
      },
      isSubmit: false,
      isEdit: !!this.$route.params.id || this.isEditModal,
    }
  },
  props: {
    data: Object,
    isEditModal: false
  },
  mounted() {
    if (this.data) {
      this.erpInfo = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    newClientDataErp() {
      return this.$store.getters.newClientDataErp;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$store.dispatch('checkEnterpriseInfo', { name: this.erpInfo.name }).then((result) => {
        console.log('checkNameResult', result.data.detail);
        if (!result) {
          this.$emit('close');
        }
        
        let nameCheckResult = true;
        if(!this.isEdit) {
          this.validateErrors.usedName = result.data.detail === 'Used';
          nameCheckResult = result.data.detail !== 'Used'
        }

        if (Object.values(this.validateErrors).find((item) => item === true) === undefined && nameCheckResult) {
          if(this.isEdit) {
            const erpData = {
              name: this.erpInfo.name,
              postal_code: this.erpInfo.postalCode,
              vat: this.erpInfo.tva,
              web_site: this.erpInfo.webSite,
              comment: this.erpInfo.comment
            };
            const data = {
              erpId: this.isEditModal ? this.$store.getters.erpId : this.$route.params.id,
              erpInfo: JSON.parse(JSON.stringify(erpData)),
            };
            this.$store.dispatch('updateEnterprise', data).then(() => {
              if(this.isEditModal) {
                this.$store.dispatch('getUserProfile')
              }
            });
          } else {
            const data = JSON.parse(JSON.stringify(this.erpInfo));
            this.$store.dispatch('storeNewClientDataErp', data);
          }
          this.$emit('close');
        }
      })
      .catch((result) => this.$emit('close'))
    },
    addrTypeSave(option) {
      this.addrInfo.address_type = option;
    }
  },
  watch: {
    erpInfo: {
      deep: true,
      handler() {
        this.validateErrors.name = typeof this.erpInfo.name === "undefined" || this.erpInfo.name === '';
        this.validateErrors.tva = typeof this.erpInfo.tva === "undefined" || this.erpInfo.name === '';
        this.validateErrors.postalCode = typeof this.erpInfo.postalCode === "undefined" || this.erpInfo.postalCode === '';

        if (typeof this.erpInfo.webSite !== "undefined" && this.erpInfo.webSite !== '') {
          this.validateErrors.webSite = !validateSiteUrl(this.erpInfo.webSite);
        } else {
          this.validateErrors.webSite = false
        }
        const _tmp = Object.values(this.validateErrors).find(item => item === true);
        this.isSubmit = typeof _tmp === "undefined";

        console.log('this.validateErrors', this.validateErrors, _tmp, this.isSubmit);
      }
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .erp-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      textarea {
        margin-top: 10px;
      }
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }
  }

</style>
