<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="orders-page">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="5" v-for="item in videoList"
           :key="item.order"
           class="video_item"
        >
          <iframe v-if="item.video_type === 2"
                  height="250px"
                  width="100%"
                  allowfullscreen
                  :src="youtubeUrl(item)"
                  />
          <video v-else
                 :src="item.video_url"
                 width="100%"
                 height="250px"
                 controls>

          </video>
          <div class="video-title">{{ item.title }}</div>
        </b-col>
        <b-col cols="12" class="empty-list" v-if="videoList.length === 0">
          {{ $t('warningInfo.emptyVideoList') }}
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import { pageTitleMixin } from '@/mixins/common'

export default {
  name: 'VideoPage',
  data() {
    return {
      preloader: true,
      pageTitle: this.$t('page_titles.video'),
    }
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    this.$store.dispatch('getVideoList').then(() => {
      this.preloader = false
    })
  },
  computed: {
    videoList() {
      console.log('Vodeo list', this.$store.getters.videoList)
      return this.$store.getters.videoList
    },
  },
  methods: {
    formatDate(date) {
      const newDate = `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}`
      return newDate;
    },
    youtubeUrl (item) {
      return `https://www.youtube.com/embed/${item.path}?modestbranding=1&playsinline=1
      &showinfo=0&enablejsapi=1&origin=${window.location.origin}&widgetid=1`
    },
  },
  watch: {
    '$route' (to, from) {
      this.preloader = true;
      this.$store.dispatch('getDocuments', this.$route.name).then(() => {
        this.pageTitle = this.pTitle();
        this.preloader = false;
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .video-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .empty-list {
    text-align: center;
    margin: 30px 0;
  }
</style>
