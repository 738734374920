<template>
  <div>
    <Preloader v-show="preloader"/>
    <b-row v-show="!preloader" no-gutters>
      <b-col cols="10" offset-md="1">
        <div class="cabinet new-client-form">
          <div class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('newClient.erpSectionTitle') }}
            </div>
            <div v-if="Object.keys(newClientDataErp).length === 0" class="cabinet__add-btn" @click.prevent="enterpriseModalShow()">+</div>
          </div>
          <div class="error" v-if="createErrors.enterpriseExists">{{ $t('errorTexts.usedEnterpriseName') }}</div>
          <div class="registered-contacts">
            <b-row v-if="Object.keys(newClientDataErp).length > 0">
              <b-col
                cols="12"
                class="margin-b"
              >
                <b-card
                  :title="newClientDataErp.name"
                  class="registered-contacts__card"
                >
                <div class="cabinet__btn-group" :class="{'is-edit':isEdit}">
                  <div class="cabinet__edit-btn" @click.prevent="editErpInfo()"></div>
                  <div v-if="!isEdit" class="cabinet__close-btn" @click.prevent="deleteErpInfo()">+</div>
                </div>
                  <b-card-text>
                    <b>{{ $t('enterpriseModal.webSite') }}:</b>
                    <a :href="newClientDataErp.webSite">{{ ` ${newClientDataErp.webSite}` }}</a>
                  </b-card-text>
                  <b-card-text>
                    <b>{{ $t('enterpriseModal.tva') }}:</b> {{ newClientDataErp.tva }}
                  </b-card-text>
                  <b-card-text v-if="newClientDataErp.comment">
                    <b>{{ $t('cabinet.comment') }}:</b> {{newClientDataErp.comment}}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <!--<div v-if="newClientDataUserKit && Object.keys(newClientDataErp).length > 0" class="cabinet__title-wrap">-->
            <!--<div class="cabinet__title">-->
              <!--{{ $t('newClient.presentationKit') }}-->
            <!--</div>-->
            <!--<div class="cabinet__btn-wrap">-->
              <!--<div v-if="!isEdit" class="cabinet__edit-btn" @click.prevent="presKitModalShow(newClientDataUserKit)"></div>-->
              <!--<div v-if="!isEdit" class="cabinet__add-btn" @click.prevent="presKitModalShow()">+</div>-->
            <!--</div>-->
          <!--</div>-->
          <b-table-simple v-if="newClientDataUserKit && Object.keys(newClientDataUserKit).length > 0">
            <b-tbody>
              <b-tr 
                v-for="(value, name, index) in newClientDataUserKit.data"
                :key="index"
              >
                <b-td>{{ name }}</b-td>
                <b-td>{{ value === true ? '+' : ((value === ''||value ===false) ? '-' : value) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-if="Object.keys(newClientDataErp).length > 0" class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('newClient.contacts') }}
            </div>
            <div class="cabinet__add-btn" @click.prevent="contactModalShow()">+</div>
          </div>
          <div v-if="newClientDataContacts.length > 0" class="registered-contacts">
            <b-row>
              <b-col 
                v-for="(item, index) in newClientDataContacts"
                :key="index"
                cols="6"
                class="margin-b"
              >
                <b-card
                  v-if="item"
                  :title="`${item.gender === 2 ? 'Mrs':'Mr'} ${item.firstName} ${item.lastName}`"
                  class="registered-contacts__card"
                >
                <div class="cabinet__btn-group">
                  <div class="cabinet__edit-btn" @click.prevent="editContactModalShow(item, index)"></div>
                  <div class="cabinet__close-btn" @click.prevent="deleteContact(item)">+</div>
                  <!-- TODO Add edit contacts-->
                  <!-- TODO Add remove contacts-->
                </div>
                  <b-card-text>
                    {{ $t('contactModal.func')}}: {{ item.func }}
                  </b-card-text>
                  <b-card-text>
                    {{ $t('contactModal.phone')}}: {{ item.phone }}
                  </b-card-text>
                  <b-card-text>
                    {{ $t('contactModal.mobilePhone')}}: {{ item.mobilePhone }}
                  </b-card-text>
                  <b-card-text>
                    {{ $t('contactModal.email')}}: {{ item.email }}
                  </b-card-text>
                  <b-card-text>
                    {{ $t('contactModal.dm')}}: {{ item.decisionMaker ? '+':'-' }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div v-if="Object.keys(newClientDataErp).length > 0" class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('newClient.address') }}
            </div>
            <div class="cabinet__add-btn" @click.prevent="addressModalShow()">+</div>
          </div>
          <b-row v-if="newClientDataAddress.length > 0">
            <b-col 
              v-for="(item, index) in newClientDataAddress"
              :key="index"
              cols="6" 
              class="margin-b"
            >
              <b-card 
                :title="parseInt(item.address_type) === 2 ? $t('cabinet.billing_address') : $t('cabinet.delivery_address')"
                class="registered-contacts__card"
              >
                <div class="cabinet__btn-group">
                  <div class="cabinet__edit-btn" @click.prevent="addressModalShow(item, index)"></div>
                  <div class="cabinet__close-btn" @click.prevent="deleteAddress(item)">+</div>
                </div>
                <b-card-text>
                  {{$t('addressModal.address')}}: {{ item.street }}
                </b-card-text>
                <b-card-text>
                  {{$t('addressModal.city')}}: {{ item.city }}
                </b-card-text>
                <b-card-text>
                  {{$t('addressModal.postalCode')}}: {{ item.postal_code }}
                </b-card-text>
                <b-card-text>
                  {{$t('cabinet.mob_phone')}}: {{ item.cell_phone }}
                </b-card-text>
                <b-card-text>
                  {{$t('cabinet.phone')}}: {{ item.fixed_phone }}
                </b-card-text>
                <b-card-text>
                  {{$t('addressModal.email')}}: {{ item.email }}
                </b-card-text>
                
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="!isEdit">
            <b-col>
              <button
                v-if="Object.keys(newClientDataErp).length > 0"
                :disabled="isSubmit"
                @click.prevent="submitForm"
                class="wm-btn new-client-form__submit-btn"
              >
                {{ $t('newClient.btn') }}
              </button>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import editAddressModal from '@/components/edit-address-modal'
import addressModal from '@/components/address-modal'
import enterpriseModal from '@/components/enterprise-modal'
import presentationKitModal from '@/components/presentation-kit-modal'
import contactModal from '@/components/contact-modal'
import { pageTitleMixin } from '@/mixins/common'

export default {
  name: 'clientNew',
  components: {
    WmSelect
  },
  data() {
    return {
      preloader: true,
      isEdit: !!this.$route.params.id,
      options: {
        male: 'male',
        female: 'female'
      },
      pageTitle: this.isEdit ? this.$t('page_titles.edit_client') : this.$t('clients_table.new_client'),
      erpInfo: {},
      createErrors: {
        enterpriseExists: false,
      },
      validateErrors: {
        billingAdress: false,
        contactMain: false,
      },
      existErpData: {},
      isSubmit: true,
    }
  },
  mixins: [ pageTitleMixin ],
  mounted() {
    if(this.isEdit) {
      this.$store.dispatch('getErpContacts', this.$route.params.id)
      this.$store.dispatch('getErpAddresses', this.$route.params.id)
      this.$store.dispatch('getUserKit', this.$route.params.id)
      this.$store.dispatch('getEnterprise', this.$route.params.id).then((data) => {
        this.preloader = false;
      });
    } else this.preloader = false;
  },
  computed: {
    newClientDataErp() {
      if(this.isEdit) {
        const data = this.$store.getters.currErp;
        const _tmp = {
          name: data.name,
          tva: data.vat,
          webSite: data.web_site,
          comment: data.comment,
          postalCode: data.postal_code,
        }
        return _tmp;
      } else return this.$store.getters.newClientDataErp;
      
    },
    newClientDataContacts() {
      if(this.isEdit) {
        const existContacts = this.$store.getters.erpContacts;
        let _tmp = []
        existContacts.forEach(element => {
          _tmp.push({
            id: element.id,
            gender: element.gender,
            firstName: element.first_name,
            lastName: element.last_name,
            func: element.position,
            phone: element.fixed_phone,
            mobilePhone: element.cell_phone,
            email: element.email,
            decisionMaker: element.is_erp_admin,
          })
        });
        return _tmp
      } else {
        return this.$store.getters.newClientDataContacts;
      }

    },
    newClientDataAddress() {
      if(this.isEdit) {
        return this.$store.getters.erpAddresses.length > 0 ? this.$store.getters.erpAddresses : this.$store.getters.newClientDataAddress;
      } else {
        return this.$store.getters.newClientDataAddress;
      }
    },
    newClientDataUserKit() {
      if(this.isEdit) {
        return this.$store.getters.currUserKit;
      } else {
        return this.$store.getters.newClientDataUserKit;
      }
    },
    isManager() {
      return this.$store.getters.isManager;
    }
  },
  methods: {
    enterpriseModalShow(data=undefined) {
      this.$modal.show(enterpriseModal,
      {
        text: 'MODAL',
        data: data,
      },
      {
        height: 'auto',
        scrollable: false
      });
    },
    presKitModalShow(data=undefined) {
      this.$modal.show(presentationKitModal,
      {
        text: 'MODAL',
        kitModel: data,
      },
      {
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    contactModalShow(data=undefined) {
      this.$modal.show(contactModal,
      {
        text: 'MODAL',
        data: data,
      },
      {
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    addressModalShow(contact, index) {
      this.$modal.show(addressModal,
      {
        data: contact,
        index: index,
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    editContactModalShow(addr, index) {
      this.$modal.show(contactModal,
      {
        data: addr,
        index: index,
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    }, 
    deleteContact(contactInfo) {
      if(this.isEdit) {
        if(confirm(this.$t('newClient.contactDelConfirm'))) {
          this.$store.dispatch('deleteErpContacts', {
            erpId: this.$route.params.id,
            id: contactInfo.id
          }); 
        }
      } else {
        this.$store.dispatch('removeNewClientDataContacts', contactInfo);
      }
    },
    deleteAddress(addressInfo) {
      if(this.isEdit) {
        if(confirm(this.$t('newClient.addressDelConfirm'))) {
          this.$store.dispatch('deleteErpAddress', {
            erpId: this.$route.params.id,
            addrId: addressInfo.id
          });
        }
      } else {
        this.$store.dispatch('removeNewClientDataAddress', addressInfo);
      }
    },
    deleteErpInfo() {
      this.$store.dispatch('storeNewClientDataErp', {});
    },
    editErpInfo() {
      this.enterpriseModalShow(this.newClientDataErp)
    },
    clearFormData() {
      this.newClientDataAddress.forEach((item) => {
        this.$store.dispatch('removeNewClientDataAddress', item);
      });
      this.newClientDataContacts.forEach((item) => {
        this.$store.dispatch('removeNewClientDataContacts', item);
      });
      this.$store.dispatch('storeNewClientDataErp', {});
      this.$store.dispatch('storeNewClientUserKit', {}); 
    },
    submitForm() {
      let isAdminExist = false;
      this.newClientDataContacts.forEach((item) => {
        if(item.decisionMaker) {
          isAdminExist = true;
          return
        }
      })
      if(isAdminExist) {
        const erpData = JSON.stringify({
          name: this.newClientDataErp.name,
          postal_code: '',
          vat: this.newClientDataErp.tva,
          web_site: this.newClientDataErp.webSite,
          comment: this.newClientDataErp.comment
        });

        this.$store.dispatch('createEnterpriseAction', erpData)
          .then((result) => {
          if (result && !result.hasOwnProperty('message')) {
            this.createErrors.enterpriseExists = false;
            console.log('USER_KIT', this.newClientDataUserKit)
            this.$store.dispatch('createUserKit', {
              erpId: result.data.id,
              data: this.newClientDataUserKit,
            });

            this.newClientDataContacts.forEach((item) => {
              const _tmpContact = JSON.stringify({
                gender: item.gender,
                first_name: item.firstName,
                last_name: item.lastName,
                position: item.func,
                fixed_phone: item.phone,
                cell_phone: item.mobilePhone,
                email: item.email,
                is_erp_admin: item.decisionMaker,
              });
              const contactData = {erpId: result.data.id, clientInfo: _tmpContact};
              this.$store.dispatch('createEnterpriseContact', contactData);
            });

            this.newClientDataAddress.forEach((item) => {
              const _tmpAddress = JSON.stringify({
                address_type: item.address_type,
                cell_phone: item.cell_phone,
                vat_number: '',
                street: item.street,
                email: item.email,
                city: item.city,
                postal_code: item.postal_code,
                web_site: item.web_site,
              });
              const addressData = {erpId: result.data.id, addressInfo: _tmpAddress};
              this.$store.dispatch('createEnterpriseAddress', addressData);
            });
            this.clearFormData();
          } else {
            this.createErrors.enterpriseExists = true
          }
        }).then(() => {
          this.$router.push({name: 'clientsTable'})
        })
      } else {
        alert(this.$t('newClient.adminWarn'));
      }
    },
    checkValidation() {
      const _tmpAdress = this.newClientDataAddress.find(item => item.address_type === '2');
      this.validateErrors.billingAdress = typeof _tmpAdress !== "undefined";
      console.log('this.newClientDataAddress - watcher >> ', this.newClientDataAddress, _tmpAdress, this.validateErrors)

      const _tmpContact = this.newClientDataContacts.find(item => item.decisionMaker === true);
      this.validateErrors.contactMain = typeof _tmpContact !== "undefined";
      console.log('this.newClientDataContacts - watcher >> ', this.newClientDataContacts, _tmpContact, typeof _tmpContact !== "undefined", this.validateErrors)

      const _tmpError = Object.values(this.validateErrors).find(item => item === false);
      console.log('submit decission', this.isSubmit, _tmpError, this.validateErrors, Object.values(this.validateErrors));
      this.isSubmit = typeof _tmpError !== "undefined";
    }
  },
  watch: {
    newClientDataAddress: {
      deep: true,
      handler() {
        this.checkValidation()
      }
    },
    newClientDataContacts: {
      deep: true,
      handler() {
        this.checkValidation()
      }
    },
    validateErrors: {
      deep: true,
      handler() {
        this.checkValidation()
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .margin-b {
    margin-bottom: 15px;
  }

  .card-title {
    font-size: 18px;
  }

  .card-text {
    margin-bottom: 5px;
  }

  .cabinet {
    margin-top: 20px;

    &__title {
      font-size: 23px;
      font-family: 'DaxBold';
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 18px;
      font-family: 'DaxMedium';
      margin-bottom: 7px;
    }

    &__italic-text {
      font-style: italic;
      font-size: 14px;
      font-family: 'DaxRegular';
    }

    &__title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__add-btn {
      font-size: 26px;
      font-family: serif;
      cursor: pointer;
      margin-left: 10px;
    }

    &__btn-group {
      position: absolute;
      right: 4px;
      top: -6px;
      display: flex;
      align-items: center;
      &.is-edit {
        margin-top: 10px;
      }
    }

    &__btn-wrap {
      display: flex;
      align-items: center
    }

    &__close-btn {
      width: 20px;
      font-size: 24px;
      font-family: serif;
      transform: rotate(45deg);
      cursor: pointer;
      margin-left: 10px;
    }

    &__edit-btn {
      width: 16px;
      height: 16px;
      background-image: url(/static/img/edit.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }

  .add-contact {
    margin-bottom: 15px;

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    &__top-input {
      width: 165px;
      height: 35px;
      margin-bottom: 15px;
    }

    &__bot-input {
      width: 195px;
      height: 35px;
    }

    &__bottom-section {
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      height: 30px;
      width: 190px;
      border-radius: 100px;
    }
  }

  .registered-contacts {
    &__card {
      position: relative;
    }

    &__card-btn {
      position: absolute;
      right: 4px;
      top: 0;
      transform: rotate(45deg);
      font-size: 20px;
      font-family: serif;
      cursor: pointer;
    }
  }

  .new-client-form {
    &__submit-btn {
      position: relative;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 50px;
      padding: 5px 15px;
      width: 180px;
      border-radius: 100px;
      text-align: left;
      text-transform: uppercase;
      justify-content: flex-start;
      font-size: 15px;

      &:after {
        content: '';
        position: absolute;
        right: 4px;
        border: 4px solid transparent;
        border-left: 7px solid #fff;
        display: block;
        width: 0;
        height: 0;
      }
    }

  }

  

</style>
