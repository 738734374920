import {
  UPDATE_ERP_LIST,
  UPDATE_CURR_ERP,
  UPDATE_CURR_USER_KIT,
} from '@/store/mutation-types';
import { managerApi } from '@/api/manager';

export const managerActions = {
  async createEnterpriseAction( { commit }, data ) {
    console.log('Data in action createEnterpriseAction', data);
    const resultErpData = await managerApi.createEnterprise(data)
      .then((data) => {
        console.log('createEnterpriseAction -- action', data);
        return data
      })
      .catch((error) => {
        console.log(error);
        return error
      });
    return resultErpData;
  },
  async createEnterpriseContact( { commit }, data ) {
    console.log('Data in action createEnterpriseContact', data);
    const resultContactData = await managerApi.createEnterpriseContact(data)
      .then((data) => {
        console.log('createEnterpriseContact -- action', data);
        return data
      })
      .catch((error) => {
        console.log(error);
        return error
      });

    return resultContactData;
  },
  async createEnterpriseAddress( { commit, dispatch }, data ) {
    console.log('Data in action createEnterpriseAddress', data);
    const resultAddressData = await managerApi.createEnterpriseAddress(data)
      .then((data) => {
        console.log('createEnterpriseAddress -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return resultAddressData;
  },
  async checkEnterpriseInfo( { commit }, data ) {
    console.log('Data in action checkEnterpriseInfo', data);
    return await managerApi.checkEnterpriseName(data)
      .then((data) => {
        if (data) {
          console.log('checkEnterpriseInfo -- action', data);
          return data
        }
      })
      .catch((error) => console.log(error));
  },
  async getEnterpriseList ( { commit }) {
    const enterpriseList = await managerApi.getEnterpriseList()
      .then((data) => {
        console.log('getEnterpriseList -- action', data);
        commit(UPDATE_ERP_LIST, data);
        return data
      })
      .catch((error) => console.log(error));

    return enterpriseList;
  },
  async getUserKit ( { commit }, erpId) {
    const userKit = await managerApi.getUserKit(erpId)
      .then((data) => {
        console.log('getUserKit -- action', data);
        commit(UPDATE_CURR_USER_KIT, data)
        return data
      })
      .catch((error) => console.log(error));

    return userKit;
  },
  async updateEnterprise ( { dispatch }, { erpId, erpInfo } ) {
    const response = await managerApi.updateEnterprise(erpId, erpInfo)
      .then((data) => {
        if (data) {
          dispatch('getEnterprise', erpId)
        }
        console.log('updateEnterprise -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return response;
  },
  async getEnterprise ( { commit }, erpId) {
    const enterprise = await managerApi.getEnterprise(erpId)
      .then((data) => {
        console.log('getEnterprise -- action', data);
        commit(UPDATE_CURR_ERP, data)
        return data
      })
      .catch((error) => console.log(error));

    return enterprise;
  },
  async createUserKit ( { dispatch }, { erpId, data } ) {
    const response = await managerApi.createUserKit(erpId, data)
      .then((data) => {
        if (data) {
          // dispatch('createUserKit', erpId)
        }
        console.log('createUserKit -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return response;
  },
  async createContract ({}, data ) {
    const response = await managerApi.generateContract(data.erpId)
      .then((data) => {
        return data
      })
      .catch((error) => console.log(error));

    return response;
  },
};
