export const pageTitleMixin = {
  // ===================================================================================================================
  created: function () {
    this.title()
  },
  // ===================================================================================================================
  methods: {
    // -----------------------------------------------------------------------------------------------------------------
    title: function () {
      if (this.pageTitle) {
        this.$store.dispatch('setPageTitle', this.pageTitle)
      }
    },
  },
  // ===================================================================================================================
  watch: {
    pageTitle () {
      this.title()
    },
  },
}

export const ImageCopyrightsMixin = {
  methods: {
    rightClick (e) {
      e.preventDefault()
    },
  },
}
