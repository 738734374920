export default {
  name: "standardPreview",
  props: {
    orderData: {
      type: Object,
      default: {},
    },
    productSlug: {
      type: String,
      default: '',
    },
    productSchema: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      preloader: false,
    }
  },
  computed: {
    interPeronalizeData() {
      if (this.productSlug.includes('inter-and-epitafie')) {
        if (this.orderData.hasOwnProperty('personalize')) {
          return this.orderData['personalize']
        }
      }
      return []
    }
  },
  methods: {
    getFieldLabel(fieldSlug) {
      const fieldData = this.productSchema.fields.find(item => item.inputName === fieldSlug)
      return (fieldData) ? fieldData.label : fieldSlug
    },
    getFieldValue(value) {
      return (value.constructor.name === 'FileList' ) ? value[0].name : value
    }
  }
}
