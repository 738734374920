<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div v-if="!data" class="wm-modal__title">{{ $t('add_addr_modal.title') }}</div>
      <div v-if="data" class="wm-modal__title">{{ $t('add_addr_modal.titleEdit') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="wm-modal__subtitle">{{ $t('add_addr_modal.subtitle') }}</div>
      <div class="contact-info">
        <div class="contact-info__form">
          <wmSelect 
            :options="checkBillingAddress()"
            @updateOption="addrInfo.address_type = $event"
            :customItemStyle="{'width': '269px', 'height': '35px'}"
            :placeholder="$t('add_addr_modal.addr_type')"
            :selected="getAddrValue()"
          />
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.street"
              class="contact-info__input wm-input"
              :placeholder="$t('addressModal.address')"
              type="text"
            >
            <div v-if="validateErrors.street" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.city"
              class="contact-info__input wm-input"
              :placeholder="$t('addressModal.city')"
              type="text"
            >
            <div v-if="validateErrors.city" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.postal_code"
              class="contact-info__input wm-input"
              :placeholder="$t('addressModal.postalCode')"
              type="text"
            >
            <div v-if="validateErrors.postal_code" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.cell_phone"
              class="contact-info__input wm-input"
              :placeholder="$t('cabinet.mob_phone')"
              type="text"
            >
            <div v-if="validateErrors.cell_phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.fixed_phone"
              class="contact-info__input wm-input"
              :placeholder="$t('cabinet.phone')"
              type="text"
            >
            <div v-if="validateErrors.fixed_phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="addrInfo.email"
              class="contact-info__input wm-input"
              :placeholder="$t('addressModal.email')"
              type="text"
            >
            <div v-if="validateErrors.email" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue';
import { validateEmail, validateSiteUrl } from '@/include/helpers';

export default {
  name: 'addAddressModal',
  components: {
    WmSelect
  },
  props: {
    data: Object,
    index: Number,
    editNewAddress: Boolean,
  },
  data() {
    return {
      addrType: {
        1: this.$t('cabinet.delivery_address'),
        2: this.$t('cabinet.billing_address')
      },
      addrInfo: {
        address_type: 1,
        street: '',
        city: '',
        postal_code: '',
        cell_phone: '',
        fixed_phone: '',
        email: '',
      },
      validateErrors: {
        street: false,
        cell_phone: false,
        fixed_phone: false,
        email: false,
        postal_code: false,
        city: false,
      },
      isEdit: !!this.$route.params.id,
    }
  },
  mounted() {
    if (this.data) {
      this.addrInfo = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    newClientDataAddress() {
      if(this.isEdit) {
        return this.$store.getters.erpAddresses.length > 0 ? this.$store.getters.erpAddresses : this.$store.getters.newClientDataAddress;
      } else {
        return this.$store.getters.newClientDataAddress;
      }
    },
    isManager() {
      return this.$store.getters.isManager;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.validateErrors.street = typeof this.addrInfo.street === "undefined" || this.addrInfo.cell_phone === '';
      this.validateErrors.cell_phone = typeof this.addrInfo.cell_phone === "undefined" || this.addrInfo.cell_phone === '';
      this.validateErrors.fixed_phone = typeof this.addrInfo.fixed_phone === "undefined" || this.addrInfo.fixed_phone === '';
      this.validateErrors.email = typeof this.addrInfo.email === "undefined" || !validateEmail(this.addrInfo.email);
      this.validateErrors.postal_code = typeof this.addrInfo.postal_code === "undefined" || this.addrInfo.postal_code === '';
      this.validateErrors.city = typeof this.addrInfo.city === "undefined" || this.addrInfo.city === '';

      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        if(this.isEdit) {
          if (this.isManager && this.index === undefined) {
            // Manager create new address, enterprise already exist
            // TODO This part should be refactored
            const _tmpAddress = JSON.stringify({
              address_type: this.addrInfo.address_type,
              cell_phone: this.addrInfo.cell_phone,
              vat_number: '',
              street: this.addrInfo.street,
              email: this.addrInfo.email,
              city: this.addrInfo.city,
              postal_code: this.addrInfo.postal_code,
              web_site: this.addrInfo.web_site,
            });
            const addressData = {erpId: this.$route.params.id, addressInfo: _tmpAddress};
            this.$store.dispatch('createEnterpriseAddress', addressData).then(() => {
                this.$store.dispatch('getErpAddresses', this.$route.params.id);
            })
          } else {
            const data = {
              erpId: this.$route.params.id,
              addrId: this.data.id,
              addrInfo: JSON.parse(JSON.stringify(this.addrInfo)),
            };
            this.$store.dispatch('updateErpAddress', data);
          }
        } else {
          const data = {
            addressInfo: JSON.parse(JSON.stringify(this.addrInfo)),
            num: this.index
          };
          this.$store.dispatch('storeNewClientDataAddress', data).then(() => {
              this.$store.dispatch('getErpAddresses', this.$route.params.id);
          })
        }
        this.$emit('close');
      }
    },
    getAddrValue() {
      if (this.data) {
          if (Object.keys(this.data).length > 0) {
            return Object.values(this.addrType)[this.data.address_type - 1];
          }
        }
        return Object.values(this.addrType)[0];
    },
    checkBillingAddress() {
      const billingAddress = this.newClientDataAddress.filter((item) => parseInt(item.address_type) === 2);
      console.log('billingAddress', billingAddress);
      if (billingAddress.length > 0) {
        return { 1: this.$t('cabinet.delivery_address') }
      }
      return this.addrType
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .contact-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }

  }

</style>
