import {
  PRODUCTS_LIST,
  UPDATE_ERP_PLAQUE_ORDERS,
  UPDATE_ERP_ORDERS,
  UPDATE_ORDERS,
} from '@/store/mutation-types';

export const orderMutations = {
  // [PRODUCTS_LIST]: (state, data) => {
    // state.productList = data
  // },
  [UPDATE_ERP_PLAQUE_ORDERS]: (state, data) => {
    state.erpPlaqueOrders = data
  },
  [UPDATE_ERP_ORDERS]: (state, data) => {
    state.erpOrders = data
  },
  [UPDATE_ORDERS]: (state, data) => {
    state.orders = data
  },
}
