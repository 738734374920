// Auth types
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

// Profile types
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_ERP_CONTACTS = 'UPDATE_ERP_CONTACTS';
export const UPDATE_ERP_ASDDRESSES = 'UPDATE_ERP_ASDDRESSES';
export const UPDATE_CURR_ERP_ADDR = 'UPDATE_CURR_ERP_ADDR';

// Product types
export const PRODUCTS_LIST = 'PRODUCTS_LIST';
export const UPDATE_CUSTOM_PLAQUES = 'UPDATE_CUSTOM_PLAQUES';
export const UPDATE_PLAQUES = 'UPDATE_PLAQUES';
export const UPDATE_PLAQUE_FORMATS = 'UPDATE_PLAQUE_FORMATS';

// Order types
export const UPDATE_ERP_PLAQUE_ORDERS = 'UPDATE_ERP_PLAQUE_ORDERS';
export const UPDATE_ERP_ORDERS = 'UPDATE_ERP_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';

// Common
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const UPDATE_NEWS_LIST = 'UPDATE_NEWS_LIST';
export const UPDATE_NEW_CLIENT_DATA_ERP = 'UPDATE_NEW_CLIENT_DATA_ERP';
export const UPDATE_NEW_CLIENT_DATA_CONTACTS = 'UPDATE_NEW_CLIENT_DATA_CONTACTS';
export const REMOVE_NEW_CLIENT_DATA_CONTACTS = 'REMOVE_NEW_CLIENT_DATA_CONTACTS';
export const UPDATE_NEW_CLIENT_DATA_ADDRESS = 'UPDATE_NEW_CLIENT_DATA_ADDRESS';
export const REMOVE_NEW_CLIENT_DATA_ADDRESS = 'REMOVE_NEW_CLIENT_DATA_ADDRESS';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_NEW_CLIENT_DATA_USER_KIT = 'UPDATE_NEW_CLIENT_DATA_USER_KIT';

// Documents + tarifs
export const UPDATE_DOCUMENTS_DOCUMENT = 'UPDATE_DOCUMENTS_DOCUMENT'
export const UPDATE_DOCUMENTS_CATALOG = 'UPDATE_DOCUMENTS_CATALOG'
export const UPDATE_DOCUMENTS_TARIF = 'UPDATE_DOCUMENTS_TARIF'
export const UPDATE_VIDEO_LIST = 'UPDATE_VIDEO_LIST'

// Manager
export const UPDATE_ERP_LIST = 'UPDATE_ERP_LIST'
export const UPDATE_CURR_ERP = 'UPDATE_CURR_ERP'
export const UPDATE_CURR_USER_KIT = 'UPDATE_CURR_USER_KIT'
