function validateSiteUrl( name ) {
  const r = /^(http|https):\/\/[^ "]+$/;
  return r.test(name);
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function twoDigitStr(d) {
  return (d < 10) ? '0' + d.toString() : d.toString()
}

export { validateSiteUrl, validateEmail, deepCopy, twoDigitStr };


