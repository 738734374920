<template>
  <b-container class="start-page">
    <img class="start-page__logo" alt="" src="/static/img/wm_logo.png">
    <b-row no-gutters class="justify-content-center">
      <b-col cols="6" class="centered-col">
        <h4 class="start-page-form__title text-center">{{ $t('reset_pw.title') }}</h4>
        <div class="start-page-form__wrap text-center">
        <vue-form-generator 
          :schema="schema" 
          :model="model" 
          :options="formOptions"
        ></vue-form-generator>
        <button class="wm-btn start-page-form__btn mt-3" type="submit">{{ $t('reset_pw.send') }}</button>
      </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ResetPw',
  components: {
  },
  data() {
    return {
      model: {
        email: '',
      },
      schema: {
        fields: [{
          type: "input",
          inputType: "text",
          model: "email",
          placeholder: this.$t('signup.email'),
          validator: 'email',
        }]
      },
      formOptions: {
        validateAfterChanged: true
      },
    }
  }
}
</script>

<style scoped lang="scss">

  .centered-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

</style>
