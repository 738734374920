import {
  PRODUCTS_LIST
} from '@/store/mutation-types';
import { productApi } from '@/api/product';

export const productActions = {
  async getProductList ({ commit }) {


    await productApi.productList()
      .then((data) => {
        console.log('getProductList -- action', data);
        commit(PRODUCTS_LIST, data)
      })
      .catch((error) => console.log(error));
  },
  async getProductSchema ({ commit }, productSlug) {
    const _tmp = await productApi.productSchema(productSlug)
      .then((data) => {
        console.log('getProductSchema -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return _tmp
  },
  async getInterEpitafieList({ commit }) {
    const _tmp = await productApi.interEpitafieList()
      .then((data) => {
        console.log('getInterEpitafieList -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return _tmp
  },
  async getInterPersonalizeList({ commit }) {
    const _tmp = await productApi.interPersonalizeList()
      .then((data) => {
        console.log('getInterPersonalizeList -- action', data);
        return data
      })
      .catch((error) => console.log(error));

    return _tmp
  },
};
