<template>
  <div class="wm-modal">
    <div class="wm-modal__header">
      <div v-if="!data" class="wm-modal__title">{{ $t('contactModal.title') }}</div>
      <div v-if="data" class="wm-modal__title">{{ $t('contactModal.titleEdit') }}</div>
    </div>
    <div class="wm-modal__body">
      <div class="erp-info">
        <div class="erp-info__form">
          <wmSelect
            :options="contactType"
            @updateOption="contactInfo.gender=$event"
            :customItemStyle="{'width': '269px', 'height': '35px'}"
            :placeholder="$t('contactModal.mr')"
            :selected="getGenderValue()"
          />
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.func"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.func')"
              type="text"
            >
            <div v-if="validateErrors.func" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.firstName"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.firstName')"
              type="text"
            >
            <div v-if="validateErrors.firstName" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.lastName"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.lastName')"
              type="text"
            >
            <div v-if="validateErrors.lastName" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.phone"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.phone')"
              type="text"
            >
            <div v-if="validateErrors.phone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.mobilePhone"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.mobilePhone')"
              type="text"
            >
            <div v-if="validateErrors.mobilePhone" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <div class="erp-info__form wm-group">
            <input
              v-model="contactInfo.email"
              class="erp-info__input wm-input"
              :placeholder="$t('contactModal.email')"
              type="text"
            >
            <div v-if="validateErrors.email" class="error">
              {{ $t('errorTexts.required') }}
            </div>
          </div>
          <label class="wm-checkbox">
            {{ $t('contactModal.dm') }}
            <input class="wm-checkbox__hidden-input" name="all" type="checkbox" v-model="contactInfo.decisionMaker">
            <div class="wm-checkbox__item"></div>
          </label>

        </div>
      </div>       
    </div>
    <div class="wm-modal__footer">
      <button class="wm-btn wm-modal__btn" 
        @click.prevent="close"
      >{{ $t('add_addr_modal.cancel') }}</button>
      <button class="wm-btn wm-modal__btn"
        @click.prevent="submit"
      >{{ $t('add_addr_modal.ok') }}</button>
    </div>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue';
import { validateEmail } from '@/include/helpers';

export default {
  name: 'contactModal',
  components: {
    WmSelect
  },
  props: {
    data: Object,
    index: Number,
  },
  data() {
    return {
      contactType: {
        1: this.$t('contactModal.mr'),
        2: this.$t('contactModal.ms')
      },
      contactInfo: {
        gender: 1,
        firstName: undefined,
        lastName: undefined,
        func: undefined,
        phone: undefined,
        mobilePhone: undefined,
        email: undefined,
        decisionMaker: undefined,
      },
      validateErrors: {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        func: false,
        mobilePhone: false,
      },
      isEdit: !!this.$route.params.id,
    }
  },
  mounted() {
    if (this.data) {
      this.contactInfo = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isManager() {
      return this.$store.getters.isManager;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.validateErrors.firstName = typeof this.contactInfo.firstName === "undefined" || this.contactInfo.firstName === '';
      this.validateErrors.lastName = typeof this.contactInfo.lastName === "undefined" || this.contactInfo.lastName === '';
      this.validateErrors.phone = typeof this.contactInfo.phone === "undefined" || this.contactInfo.phone === '';
      this.validateErrors.mobilePhone = typeof this.contactInfo.mobilePhone === "undefined" || this.contactInfo.mobilePhone === '';
      this.validateErrors.email = typeof this.contactInfo.email === "undefined" || !validateEmail(this.contactInfo.email);
      this.validateErrors.func = typeof this.contactInfo.func === "undefined" || this.contactInfo.func === '';

      if (Object.values(this.validateErrors).find((item) => item === true) === undefined) {
        if (this.isEdit) {
          if (this.isManager && this.index === undefined) {
            // Manager create new contact, enterprise already exist
            const _tmpContact = JSON.stringify({
              gender: this.contactInfo.gender,
              first_name: this.contactInfo.firstName,
              last_name: this.contactInfo.lastName,
              position: this.contactInfo.func,
              fixed_phone: this.contactInfo.phone,
              cell_phone: this.contactInfo.mobilePhone,
              email: this.contactInfo.email,
              is_erp_admin: this.contactInfo.decisionMaker,
            });
            const contactData = {erpId: this.$route.params.id, clientInfo: _tmpContact};
            this.$store.dispatch('createEnterpriseContact', contactData).then(
              () => {
                this.$store.dispatch('getErpContacts', this.$route.params.id)
              }
            );
          } else {
            const _tmpContact = {
              gender: this.contactInfo.gender,
              first_name: this.contactInfo.firstName,
              last_name: this.contactInfo.lastName,
              position: this.contactInfo.func,
              fixed_phone: this.contactInfo.phone,
              cell_phone: this.contactInfo.mobilePhone,
              email: this.contactInfo.email,
              is_erp_admin: this.contactInfo.decisionMaker,
            };
            const data = {
              erpId: this.$route.params.id,
              id: this.data.id,
              contactInfo: _tmpContact,
            };
            this.$store.dispatch('updateErpContact', data);
          }

        } else {
          const data = {
            contactInfo: JSON.parse(JSON.stringify(this.contactInfo)),
            num: this.index
          };
          this.$store.dispatch('storeNewClientDataContacts', data);
        }
        this.$emit('close');
      }
    },
    getGenderValue() {
      if (this.data) {
        if (Object.keys(this.data).length > 0) {
          return Object.values(this.contactType)[this.data.gender - 1];
        }
      }
      return Object.values(this.contactType)[0];
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .v--modal-box {
    width: 600px;
  }

  .erp-info {

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      textarea {
        margin-top: 10px;
      }
    }

    &__input {
      width: 48%;
      height: 35px;
      margin-bottom: 10px;
    }

    .wm-checkbox__item {
      margin-left: 5px;
    }

    .wm-group {
      width: 48%;

      .wm-input {
        width: 100%;
      }

    }
    .error {
      color: red;
      margin: -5px 5px 10px;
      font-size: 14px;
    }
  }


</style>
