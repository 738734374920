<template>
<b-row no-gutters class="mt-auto">
  <b-col v-if="title" cols="12">
    <div v-html="title" class="product__title"></div>
  </b-col>
  <b-col v-if="subtitle" cols="12">
    <div v-html="subtitle" class="product__subtitle"></div>
  </b-col>
</b-row>
</template>

<script>
export default {
  name: 'productSubtitle',
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<style scoped>
.product__title {
  font-size: 18px;
  font-weight: bold;
  font-family: 'DaxMedium';
  margin: 0 10px 15px 30px;
}

.product__subtitle {
  max-height: 295px;
  margin: 0 10px 30px 30px;
  text-align: justify;
  font-size: 14px;
  overflow: hidden;
}
</style>
