<template>
  <div>
    <Preloader v-show="preloader"/>
    <b-row v-show="!preloader" no-gutters>
      <b-col cols="8" offset-md="2">
        <div class="main-menu__wrap">
          <div class="main-menu__title">{{ $t('main_page.title') }}</div>
          <ul class="main-menu" v-show="productList">
            <li v-for="item in productList" :key="item.slug">
              <router-link :to="getRouteName(item.pdf_doc, item.slug)" class="main-menu__btn wm-btn">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CommentForm from '@/components/comment-form.vue'
import { pageTitleMixin } from '@/mixins/common';

export default {
  name: 'MainPage',
  components: {
    CommentForm,
  },
  mixins: [ pageTitleMixin ],
  data() {
    return {
      pageTitle: this.$t('page_titles.dashboard'),
      preloader: true,
    }
  },
  mounted() {
    this.loadProductsList()
  },
  methods: {
    loadProductsList() {
      this.$store.dispatch('getProductList').then(() => {
        console.log('Finish load product list');
        this.preloader = false;
      })
    },
    getRouteName(pdfDocumentTemplate, productSlug) {
      if (pdfDocumentTemplate === 'Collections') {
        return { name: 'imageCollection', params: { slug: productSlug }}
      }

      switch (productSlug) {
        case 'collection-privee':
          return { name: 'collections'}
        case 'inter-and-epitafie':
          return { name: 'intersView', params: { slug: productSlug }}
        default:
          return  { name: 'orderNew', params: { slug: productSlug }}
      }
    },
  },
  computed: {
    productList() {
      return this.$store.getters.productList
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .main-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    &__wrap {
      margin-top: 40px;
      border: 1px solid #b9b9b9;
      padding: 20px 40px 40px;
      border-radius: 20px;
    }

    &__title {
      font-size: 20px;
      font-family: 'DaxMedium';
      text-align: center;
      margin-bottom: 30px;
    }

    &__btn {
      padding: 8px 10px;
      font-size: 15px;
    }

    li:not(:last-child){
      margin-bottom: 15px;
    }
  }

</style>
