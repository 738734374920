export default {
  name: "collectionPreview",
  props: {
    orderData: {
      type: Object,
      default: {},
    },
    catalog: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      preloader: false,
    }
  },
  methods: {
    getCatalogItem(id) {
      return this.catalog.find(item => (item.id).toString() === id)
    }
  }
}
