<template>
  <div class="card-carousel-wrapper">
    <div
      class="card-carousel--nav__left"
      @click="moveCarousel(-1)"
      :disabled="atHeadOfList"
    ></div>
    <div class="card-carousel">
      <div class="card-carousel--overflow-container">
        <div
          class="card-carousel-cards"
          :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}"
        >
          <div
            class="card-carousel__card"
            v-for="(item,key) in plaques" :key="`${item.id}+${key}`"
            @click="openOrderEdit(item, plaques)"
            @contextmenu="rightClick($event)"
          >
            <div
              :class="{'active' : item.is_created}"
              class="card-carousel__card--img-border"
            >
              <div class="card-carousel__card--img">
                <img v-lazy="item.image_thumbnail" alt="">
              </div>
            </div>
            <div class="card-carousel__card--footer">
              <p class="card-carousel__card--title">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-carousel--nav__right"
      @click="moveCarousel(1)"
      :disabled="atEndOfList"
    ></div>
  </div>
</template>

<script>
import { ImageCopyrightsMixin } from '@/mixins/common'

export default {
  name: 'WmSlider',
  props: {
    plaques: Array,
  },
  mixins: [
    ImageCopyrightsMixin,
  ],
  data() {
    return {
      currentOffset: 0,
      windowSize: 4,
      paginationFactor: 190,
      showOrderEdit: false,
      isInOrder: false,
      items: [
        {name: 'Tycoon Thai', tag: "Thai"},
        {name: 'Ippudo', tag: "Japanese"},
        {name: 'Milano', tag: "Pizza"},
        {name: 'Tsing Tao', tag: "Chinese"},
        {name: 'Frances', tag: "French"},
        {name: 'Burma Superstar', tag: "Burmese"},
        {name: 'Salt and Straw', tag: "Ice cream"},
      ]
    }
  },
  mounted() {
    this.$store.dispatch('getCustomPlaques');
  },
  computed: {
    atEndOfList() {
      return this.currentOffset <= (this.paginationFactor * -1) * (this.plaques.length - this.windowSize);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
    customPlaques() {
      return this.$store.getters.customPlaques;
    }
  },
  methods: {
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
    openOrderEdit(item, plaques) {
      this.$emit('openOrderEdit', item, plaques);
    },
    // isHaveCustomPl(plaqueId) {
    //   this.$store.getters.customPlaques.forEach(element => {
    //     console.log('isHAVE', element.original.id, plaqueId)
    //     if(element.original.id === plaqueId){
    //       console.log('TRUE')
    //       return true;
    //     }
    //   });
    //   console.log('FALSE')
    //   return false;
    // }
  },
  watch: {
    customPlaques() {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  $vue-navy: #2c3e50;
  $vue-navy-light: #3a5169;
  $vue-teal: #42b883;
  $vue-teal-light: #42b983;
  $gray: #666a73;
  $light-gray: #f8f8f8;


  body {
    background: $light-gray;
    color: $vue-navy;
    font-family: 'Source Sans Pro', sans-serif;
    min-height: 1000px;
  }

  .card-carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: $gray;
  }

  .card-carousel {
    display: flex;
    justify-content: center;
    width: 740px;

    &--overflow-container {
      overflow: hidden;
      min-width: 100%;
    }

    &--nav__left,
    &--nav__right {
      display: inline-block;
      width: 10px;
      height: 10px;
      padding: 5px;
      box-sizing: border-box;
      border-top: 4px solid #707070;
      border-right: 4px solid #707070;
      cursor: pointer;
      margin: 0 10px 25px;
      transition: transform 150ms linear;
      &[disabled] {
        opacity: 0.2;
        border-color: black;
      }
    }

    &--nav__left {
      transform: rotate(-135deg);
      &:active {
        transform: rotate(-135deg) scale(0.9);
      }
    }

    &--nav__right {
      transform: rotate(45deg);
      &:active {
        transform: rotate(45deg) scale(0.9);
      }
    }
  }

  .card-carousel-cards {
    display: flex;
    transition: transform 150ms ease-out;
    transform: translatex(0px);

    .card-carousel__card {
      height: 130px;
      margin: 0 20px;
      cursor: pointer;
      background-color: #fff;
      z-index: 3;
      margin-bottom: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 10px;
      }

      &--img-border {
        margin-bottom: 10px;
        margin-top: 10px;
        width: 150px;
        height: 100px;
      }

      &--img {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 150px;
        height: 100px;
        overflow: hidden;

        img {
          display: inline-block;
        }
      }

      img {
        max-width: 100%;
        vertical-align: bottom;
        transition: opacity 150ms linear;
        user-select: none;

        &:hover {
          opacity: 0.7;
        }
      }

      &--footer {
        border-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--title {
        margin: 0;
        font-size: 16px;
        line-height: 16px;
      }
    }

    .card-carousel__card--img-border.active {
      position: relative;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        display: block;
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
        z-index: 1;
        border: 15px solid #d6d6d6;
        border-radius: 15px;
      }
    }
  }

  h1 {
    font-size: 3.6em;
    font-weight: 100;
    text-align: center;
    margin-bottom: 0;
    color: $vue-teal;
  }

</style>
