export const wmGetters = {
  authStatus: state => state.authStatus,
  newsList: state => state.newsList,
  userProfile: state => state.userProfile,
  erpId: state => state.erpId,
  erpContacts: state => state.erpContacts,
  erpAddresses: state => state.erpAddresses,
  currErpAddress: state => state.currErpAddress,
  customPlaques: state => state.customPlaques,
  plaques: state => state.plaques,
  plaqueFormats: state => state.plaqueFormats,
  documentsList: state => state.documentsList,
  catalogDocumentsList: state => state.catalogDocumentsList,
  tarifDocumentsList: state => state.tarifDocumentsList,
  videoList: state => state.videoList,
  isManager: state => state.isManager,
  userId: state => state.userId,
}

export const authGetters = {
  isAuthenticated: state => !!state.userToken,
  authStatus: state => state.authStatus,
};

export const commonGetters = {
  pageTitle: state => state.pageTitle,
  newClientDataErp: state => state.newClientDataErp,
  newClientDataContacts: state => state.newClientDataContacts,
  newClientDataAddress: state => state.newClientDataAddress,
  newClientDataUserKit: state => state.newClientDataUserKit,
  currPage: state => state.currPage,
};
