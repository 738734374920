import { HTTP } from '@/api/common';

export const orderApi = {
  createOrder(enterprise_id, data) {
    return HTTP.post(`/enterprise/${enterprise_id}/orders/`, data, { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('wmToken')
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  createPlaqueOrder (erpId, orderData) {
    return HTTP.post(`enterprise/${erpId}/plaque-orders/`, orderData, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error, response) => {
        console.log('API ERROR', error)
        return response
      })
  },
  getErpOrders (erpId) {
    return HTTP.get(`enterprise/${erpId}/orders/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getErpOrder (erpId, orderId) {
    return HTTP.get(`enterprise/${erpId}/orders/${orderId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  confirmErpOrder (erpId, orderId) {
    return HTTP.get(`enterprise/${erpId}/orders/${orderId}/confirm/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  updateErpOrder (erpId, orderId, orderData) {
    return HTTP.put(`enterprise/${erpId}/orders/${orderId}/`, orderData, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error, response) => {
        console.log('API ERROR', error)
        return response
      })
  },
  getErpPlaqueOrders (erpId) {
    return HTTP.get(`enterprise/${erpId}/plaque-orders/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  getErpPlaqueOrder (erpId, orderId) {
    return HTTP.get(`enterprise/${erpId}/plaque-orders/${orderId}/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  confirmErpPlaqueOrder (erpId, orderId) {
    return HTTP.get(`enterprise/${erpId}/plaque-orders/${orderId}/confirm/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  updateErpPlaqueOrder (erpId, orderId, orderData) {
    return HTTP.put(`enterprise/${erpId}/plaque-orders/${orderId}/`, orderData, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response
      })
      .catch((error, response) => {
        console.log('API ERROR', error)
      })
  },
  patchErpPlaqueOrder (erpId, orderId, orderData) {
    return HTTP.patch(`enterprise/${erpId}/plaque-orders/${orderId}/`, orderData, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response
      })
      .catch((error, response) => {
        console.log('API ERROR', error)
      })
  },
  getOrders () {
    return HTTP.get(`orders/`, { headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
  sendFiles (data) {
    return HTTP.post(`file-upload/`, data, { headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('wmToken'),
    }})
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log('API ERROR', error)
      })
  },
};
