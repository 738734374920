import {
  PRODUCTS_LIST,
  UPDATE_ERP_LIST,
  UPDATE_CURR_ERP,
  UPDATE_CURR_USER_KIT,
} from '@/store/mutation-types';

export const managerMutations = {
  // [PRODUCTS_LIST]: (state, data) => {
    // state.productList = data
  // },
  [UPDATE_ERP_LIST]: (state, data) => {
    state.erpList = data
  },
  [UPDATE_CURR_ERP]: (state, data) => {
    state.currErp = data
  },
  [UPDATE_CURR_USER_KIT]: (state, data) => {
    state.currUserKit = data
  },
}
