<template>
  <div class="dropdown-group">
    <ul>
      <li v-if="selectedOption"
          @click="toggleMenu()"
          ref="dropdownToggle"
          :class="{ 'active' : showMenu }"
          class="dropdown-group__toggle"
          :style="customItemStyle"
          >
        {{ selectedOption }}
        <span class="caret"></span>
      </li>

      <li v-if="!selectedOption"
          @click="toggleMenu()"
          ref="dropdownToggle"
          :class="{ 'active' : showMenu }"
          class="dropdown-group__toggle"
          :style="customItemStyle"
      >
        {{placeholderText}}
        <span class="caret"></span>
      </li>
    </ul>

    <ul class="dropdown-options" :style="customDropdownStyle" v-if="showMenu">
      <li v-for="(value, key) in resultOptions" :key="key">
        <div :data-value="key" @click="updateOption(key)">
          {{ value }}
        </div>
      </li>
    </ul>
    <div v-if="errorText" class="dropdown-error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WmSelect',
  data() {
    return {
      selectedOption: {
      },
      showMenu: false,
      placeholderText: '',
    };
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selected: {},
    placeholder: [String],
    customItemStyle: [Object],
    customDropdownStyle: [Object],
    errorText: [String],
  },

  mounted() {
    this.selectedOption = this.selected;
    this.placeholderText = this.$t('comment_form.dropdown_default');
    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    }
  },
  created() {
    document.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.showMenu = false;
      }
    });
  },
  computed: {
    resultOptions() {
      if (typeof this.options !== 'undefined') {
        const tmp = Object.assign({}, this.options);
        const defaultOptionsKeyName = Object.keys(tmp)
          .find(key => tmp[key] === this.selectedOption);
        delete tmp[defaultOptionsKeyName];
        return tmp;
      }
      return {};
    },
  },

  methods: {
    updateOption(option) {
      this.selectedOption = this.options[option];
      this.showMenu = false;
      this.$emit('updateOption', option);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  watch: {
    selected() {
      this.selectedOption = this.selected;
    }
  }
};

</script>

<style lang="scss" scoped>
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  .dropdown {
    &-group {
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      max-height: 35px;

      &a:hover {
        text-decoration: none;
      }

      &__toggle {
        padding: 6px 10px;
        box-shadow: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #b9b9b9;
        background-color: #ffffff;
        font-size: 14px;
        overflow: hidden;

        &.active {
          border-bottom: none;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-options {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      width: 100%;
      padding: 5px 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #ffffff;
      border: 1px solid #b9b9b9;
      border-radius: 0 0 4px 4px;
      border-top: none;
      background-clip: padding-box;
      display: flex;
      flex-direction: column;

      &> li {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 6px 10px;
        cursor: pointer;

        &:hover {
          color: #b9b9b9;
        }
      }
    }
    &-error {
      color: red;
      margin-bottom: 5px;
    }
  }

  .caret {
    border: 5px solid transparent;
    border-top: 9px solid #707070;
    display: block;
    width: 0;
    height: 0;
    position: relative;
    top: 3px;
  }
</style>
