<template>
  <div>
    <Preloader v-show="preloader"/>
    <b-row v-show="!preloader" no-gutters>
      <b-col cols="8" offset-md="1">
        <div v-if="erpProfile" class="cabinet">
          <div class="cabinet__title">{{ $t('cabinet.enterprise') }}</div>
          <div class="erp-profile">
            <b-row>
              <b-col>
                <b-card 
                  :title="`${erpProfile.name}`"
                  class="registered-contacts__card"
                >
                <!-- <div class="cabinet__btn-group is-edit">
                  <div v-if="userProfile.profile.is_erp_admin" class="cabinet__edit-btn" @click.prevent="editErpInfo(erpProfile)"></div>
                </div> -->
                  <b-row>
                    <b-col cols="6">
                      <b-card-text>
                        {{ `${$t('cabinet.website')}: `}}
                        <a :href="erpProfile.web_site">{{erpProfile.web_site}}</a>
                      </b-card-text>
                      <b-card-text>
                        {{ `${$t('cabinet.VAT')}: ${erpProfile.vat}` }}
                      </b-card-text>
                    </b-col>
                    <b-col cols="6">
                      <b-card-text>
                        {{ `${$t('cabinet.postal_code')}: ${erpProfile.postal_code}` }}
                      </b-card-text>
                      <b-card-text v-if="erpProfile.comment">
                        {{ `${$t('cabinet.comment')}: ${erpProfile.comment}` }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="!userProfile.profile.is_erp_admin" class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('cabinet.profile_info') }}
            </div>
          </div>
          <div v-if="!userProfile.profile.is_erp_admin" class="erp-profile">
            <b-row>
              <b-col>
                <b-card 
                  :title="`${userProfile.profile.gender === 2 ? 'Mrs':'Mr'} ${userProfile.first_name} ${userProfile.last_name}`"
                  class="registered-contacts__card"
                >
                <!-- <div class="cabinet__btn-group is-edit">
                  <div class="cabinet__edit-btn" @click.prevent="editProfileInfo()"></div>
                </div> -->
                  <b-row>
                    <b-col cols="6">
                      <b-card-text>
                        {{ `${$t('cabinet.username')}: ${userProfile.username}`}}
                      </b-card-text>
                      <b-card-text>
                        {{ `${$t('cabinet.email')}: ${userProfile.email}` }}
                      </b-card-text>
                       <b-card-text>
                        {{ `${$t('cabinet.position')}: ${userProfile.profile.position}` }}
                      </b-card-text>
                    </b-col>
                    <b-col cols="6">
                      <b-card-text>
                        {{ `${$t('cabinet.mob_phone')}: ${userProfile.profile.cell_phone}` }}
                      </b-card-text>
                      <b-card-text>
                        {{ `${$t('cabinet.phone')}: ${userProfile.profile.fixed_phone}` }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="userProfile.profile.is_erp_admin" class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('cabinet.contacts') }}
              <div class="cabinet__italic-text">{{ $t('cabinet.contact_info') }}</div>
            </div>
            <!-- <div class="cabinet__add-btn" @click.prevent="addContactModalShow">+</div> -->
          </div>
          <div v-if="userProfile.profile.is_erp_admin" class="registered-contacts">
            <div class="cabinet__subtitle">{{ $t('cabinet.reg_contacts') }}</div>
            <b-row>
              <b-col 
                v-for="(item, index) in erpContacts"
                :key="index"
                cols="6" 
                class="margin-b"
              >
                <b-card 
                  :title="`${item.gender === 2 ? 'Mrs':'Mr'} ${item.first_name} ${item.last_name}`"
                  class="registered-contacts__card"
                >
                  <!-- <div v-if="userProfile.profile.is_erp_admin" class="cabinet__btn-group">
                    <div class="cabinet__edit-btn" @click.prevent="editContactModalShow(item.id)"></div>
                    <div class="cabinet__close-btn" @click.prevent="deleteContact(item.id)">+</div>
                  </div> -->
                  <b-card-text>
                    {{ item.position }}
                  </b-card-text>
                  <b-card-text>
                    {{ item.fixed_phone }}
                  </b-card-text>
                  <b-card-text>
                    {{ item.cell_phone }}
                  </b-card-text>
                  <b-card-text>
                    {{ item.email }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="cabinet__title-wrap">
            <div class="cabinet__title">
              {{ $t('cabinet.reg_contact_info') }}
              <div class="cabinet__italic-text">{{ $t('cabinet.contact_info') }}</div>
            </div>
            <!-- <div v-if="userProfile.profile.is_erp_admin" class="cabinet__add-btn" @click.prevent="addAddrModalShow">+</div> -->
          </div>
          <b-row>
            <b-col 
              v-for="(item, index) in erpAddresses"
              :key="index"
              cols="6" 
              class="margin-b"
            >
              <b-card 
                :title="item.address_type === 2 ? $t('cabinet.billing_address') : $t('cabinet.delivery_address')"
                class="registered-contacts__card"
              >
                <!-- <div v-if="userProfile.profile.is_erp_admin" class="cabinet__btn-group">
                  <div class="cabinet__edit-btn" @click.prevent="editAddrModalShow(item.id)"></div>
                  <div class="cabinet__close-btn" @click.prevent="deleteErpAddress(item.id)">+</div>
                </div> -->
                <b-card-text>
                  {{ item.vat_number }}
                </b-card-text>
                <b-card-text>
                  {{ item.street }}
                </b-card-text>
                <b-card-text>
                  {{ item.city }}
                </b-card-text>
                <b-card-text>
                  {{ item.postal_code }}
                </b-card-text>
                <b-card-text>
                  {{ item.cell_phone }}
                </b-card-text>
                <b-card-text>
                  {{ item.email }}
                </b-card-text>
                <b-card-text>
                  <a :href="item.web_site" class="wm-link">{{ item.web_site }}</a>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <div class="cabinet__title">
            {{ $t('cabinet.change_password') }}
            <div class="cabinet__italic-text">{{ $t('cabinet.contact_info') }}</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import { pageTitleMixin } from '@/mixins/common'
import editAddressModal from '@/components/edit-address-modal.vue'
import editContactModal from '@/components/edit-contact-modal.vue'
import enterpriseModal from '@/components/enterprise-modal'
import editProfileModal from '@/components/edit-user-profile-modal'

export default {
  name: 'MyCabinet',
  components: {
    WmSelect
  },
  data() {
    return {
      preloader: true,
      pageTitle: this.$t('page_titles.cabinet'),
      erpProfile: {},
      options: {
        male: 'male',
        female: 'female',
      },
      isEdit: true,
    }
  },
  mixins: [ pageTitleMixin ],
  computed: {
    erpContacts() {
      return this.$store.getters.erpContacts;
    },
    erpAddresses() {
      return this.$store.getters.erpAddresses;
    },
    userProfile() {
      return this.$store.getters.userProfile;
    },
    userId() {
      return this.$store.getters.userId;
    }
  },
  methods: {
    editErpInfo(data) {
      const _tmp = {
        name: data.name,
        tva: data.vat,
        webSite: data.web_site,
        comment: data.comment,
        postalCode: data.postal_code,
      }
      this.$modal.show(enterpriseModal,
      {
        text: 'MODAL',
        data: _tmp,
        isEditModal: true,
      },
      {
        height: 'auto',
        scrollable: false
      });
    },
    editProfileInfo() {
      this.$modal.show(editProfileModal, 
      {
        
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    addAddrModalShow() {
      this.$modal.show(editAddressModal, 
      {
        isEditModal: false
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    editAddrModalShow(addrId) {
      this.$modal.show(editAddressModal, 
      {
        addrId: addrId,
        isEditModal: true
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    }, 
    deleteErpAddress(addrId) {
      if(confirm(this.$t('newClient.addressDelConfirm'))) {
        this.$store.dispatch('deleteErpAddress', {
          erpId: this.$store.getters.erpId, 
          addrId: addrId
        });
      }
    },
    addContactModalShow() {
      this.$modal.show(editContactModal, 
      {
        isEditModal: false
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    },
    editContactModalShow(contactId) {
      this.$modal.show(editContactModal, 
      {
        contactId: contactId,
        isEditModal: true
      },{
        width: '600px',
        height: 'auto',
        scrollable: false
      });
    }, 
    deleteContact(contactId) {
      if(confirm(this.$t('newClient.contactDelConfirm'))) {
        this.$store.dispatch('deleteErpContacts', {
          erpId: this.$store.getters.erpId,
          id: contactId
        }); 
      }
    },
  },
  watch: {
    userProfile: {
      immediate: true,
      handler() {
        this.$store.dispatch('getErpContacts', this.$store.getters.erpId);    
        this.$store.dispatch('getErpAddresses', this.$store.getters.erpId).then(() => {
          if (this.$store.getters.userProfile.profile === null) {
            this.erpProfile = null
          } else {
            this.erpProfile = this.$store.getters.userProfile.profile.enterprise;
          }
          this.preloader = false;
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .margin-b {
    margin-bottom: 15px;
  }

  .card-title {
    font-size: 18px;
  }

  .card-text {
    margin-bottom: 5px;
  }

  .cabinet {
    margin-top: 20px;

    &__title {
      font-size: 23px;
      font-family: 'DaxBold';
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 18px;
      font-family: 'DaxMedium';
      margin-bottom: 7px;
    }

    &__italic-text {
      font-style: italic;
      font-size: 14px;
      font-family: 'DaxRegular';
    }

    &__title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__add-btn {
      font-size: 26px;
      font-family: serif;
      cursor: pointer;
    }

    &__btn-group {
      position: absolute;
      right: 4px;
      top: -6px;
      display: flex;
      align-items: center;
      &.is-edit {
        margin-top: 10px;
      }
    }

    &__close-btn {
      width: 20px;
      font-size: 24px;
      font-family: serif;
      transform: rotate(45deg);
      cursor: pointer;
      margin-left: 10px;
    }

    &__edit-btn {
      width: 16px;
      height: 16px;
      background-image: url(/static/img/edit.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }

  .add-contact {
    margin-bottom: 15px;

    &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    &__top-input {
      width: 165px;
      height: 35px;
      margin-bottom: 15px;
    }

    &__bot-input {
      width: 195px;
      height: 35px;
    }

    &__bottom-section {
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      height: 30px;
      width: 190px;
      border-radius: 100px;
    }
  }

  .registered-contacts {
    &__card {
      position: relative;
    }

    &__card-btn {
      position: absolute;
      right: 4px;
      top: 0;
      transform: rotate(45deg);
      font-size: 20px;
      font-family: serif;
      cursor: pointer;
    }
  }

  .erp-profile {
    margin-bottom: 20px;
  }

  

</style>
