<template>
  <b-container class="comment-form">
    <b-row no-gutters class="mt-auto">
      <b-col cols="12">
        <b-row v-if="isPreviewStatus">
          <b-col cols="8">
            <textarea
              class="comment-form__ta wm-input"
              name="comment"
              :placeholder="$t('comment_form.comment')"
              v-model="commentData.comment"
            ></textarea>
            <div class="comment-form__requaired">{{ $t('signup.required') }}</div>
          </b-col>
          <b-col cols="4">
            <div class="el-with-help-button margin-b">
              <input
                :class="`comment-form__input wm-input ${inputErrorClass}`"
                :placeholder="error.reference_text || $t('comment_form.input')"
                type="text"
                v-model="commentData.reference_text"
              >
              <HelpButton :helpText="$t('comment_form.help_text')"/>
            </div>
            <wmSelect
              :options="contactOptions"
              :selected="isOrderEdit ? contactOptions[commentData.contact] : undefined"
              :customItemStyle="{'width': '175px', 'height': '35px'}"
              :placeholder="$t('comment_form.dropdown_contact')"
              @updateOption="commentData.contact=$event"
              class="margin-b"
              :errorText="error.contact"
            />
            <wmSelect
              :options="addressOptions"
              :selected="isOrderEdit ? addressOptions[commentData.address] : undefined"
              :customItemStyle="{'width': '175px', 'height': '35px'}"
              :placeholder="$t('comment_form.dropdown_address')"
              @updateOption="commentData.address=$event"
              class="margin-b"
              :errorText="error.address"
            />
          </b-col>
        </b-row>
        <b-row v-if="isShowBackButton">
          <b-col cols="2" class="mb-3"/>
          <b-col cols="4" class="mt-3">
            <button
              @click.prevent="backButton"
              class="wm-btn comment-form__back-btn"
            >
            {{ $t('comment_form.backButtonText') }}
            </button>
          </b-col>
          <b-col cols="4" class="mt-3">
            <button
              @click.prevent="submitForm"
              class="wm-btn comment-form__btn"
            >
            {{ $t('comment_form.validate') }}
            </button>
          </b-col>
          <b-col cols="2"/>
        </b-row>
        <b-row v-else>
          <b-col v-if="!isPreviewStatus" class="mt-3">
            <button
              @click.prevent="previewPage"
              id="preview-button"
              class="wm-btn comment-form__btn"
            >
            {{ $t('comment_form.preview') }}
            </button>
          </b-col>
          <b-col v-if="isPreviewStatus" class="mt-3">
            <button
              @click.prevent="submitForm"
              class="wm-btn comment-form__btn"
            >
            {{ $t('comment_form.validate') }}
            </button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-auto">
      <b-col cols="12">
        <p class="comment-form__footer-text">{{ $t('comment_form.footer_text') }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import WmSelect from '@/components/wm-select.vue'
import HelpButton from '@/components/help-button.vue'

export default {
  name: 'commentForm',
  components: {
    WmSelect,
    HelpButton
  },
  props: {
    defaultCommentData: Object,
    isShowBackButton: {
      type: Boolean,
      default: false,
    },
    isPreviewStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addressOptions: {},
      contactOptions: {},
      isOrderEdit: !!this.$route.params.id,
      commentData: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
        comment: undefined,
      },
      error: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
      },
      erpId: undefined,
      inputErrorClass: '',
    }
  },
  beforeCreate() {
    this.erpId = this.$store.getters.erpId;
    if (this.erpId) {
      console.log('Comment-form', this.erpId);
      this.$store.dispatch('getErpContacts', this.erpId);
      this.$store.dispatch('getErpAddresses', this.erpId);
    }
  },
  computed: {
    erpAddresses(){
      return this.$store.getters.erpAddresses
    },
    erpContacts(){
      return this.$store.getters.erpContacts
    },
  },
  methods: {
    submitForm() {
      this.error.contact = (!this.commentData.contact) ? this.$t('errorTexts.required') : undefined
      this.error.address = (!this.commentData.address) ? this.$t('errorTexts.required') : undefined
      this.error.reference_text = (!this.commentData.reference_text) ? this.$t('errorTexts.required') : undefined

      if (!this.error.contact && !this.error.address && !this.error.reference_text) {
        this.$emit('submitForm')
      }
    },
    previewPage() {
      this.$emit('previewForm')
    },
    backButton() {
      this.$router.push({name: 'collections'})
    },
    currOrderContact() {
      if(this.isOrderEdit) {
        for(let key in this.contactOptions) {
          if(key == this.commentData.contact) {
            return this.contactOptions[key]
            // return {[key]: this.contactOptions[key]}
          }
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    commentData: {
      deep: true,
      handler() {
        this.$emit('updateData', this.commentData)
      }
    },
    defaultCommentData: {
      deep: true,
      handler() {
        this.commentData = Object.assign({},this.defaultCommentData);
        console.log('Watcher', this.commentData)
      }
    },
    erpAddresses() {
      console.log('Address refresh', this.erpAddresses)
      this.erpAddresses.forEach((item) => {
        if(item.address_type === 1) {
          this.addressOptions[item.id] = `${item.city} ${item.street}`
        }
      })
    },
    erpContacts() {
      console.log('Contacts refresh', this.erpContacts)
      this.erpContacts.forEach((item) => {
        this.contactOptions[item.id] = `${item.first_name} ${item.last_name}`
      })
    },
    error: {
      deep: true,
      handler() {
        this.inputErrorClass = (this.error.reference_text) ? 'input-error' : ''
      },
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .comment-form {
    &__ta {
      height: 122px;
      width: 100%;
    }

    &__input {
      width: 175px;
      height: 35px;
    }

    &__requaired {
      font-size: 12px;
      font-style: italic;
    }

    &__btn {
      position: relative;
      margin: 0 auto;
      padding: 5px 30px 5px 15px;
      border-radius: 100px;
      text-align: left;
      text-transform: uppercase;
      justify-content: flex-start;
      font-size: 15px;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        right: 4px;
        border: 4px solid transparent;
        border-left: 7px solid #fff;
        display: block;
        width: 0;
        height: 0;
      }
    }
    &__back-btn {
      position: relative;
      margin: 0 auto;
      padding: 5px 15px;
      width: 180px;
      border-radius: 100px;
      text-align: left;
      text-transform: uppercase;
      justify-content: center;
      font-size: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 4px;
        border: 4px solid transparent;
        border-left: 7px solid #fff;
        display: block;
        width: 0;
        height: 0;
        transform: rotate(180deg);
      }
    }

    &__footer-text {
      padding-top: 1em;
      text-align: center;
      font-size: 14px;
      font-style: italic;
    }
  }

  .margin-b {
    margin-bottom: 8px;
  }

  .reference-error {
    color: red;
    margin-bottom: 5px;
  }

  .input-error {
    border-color: red;
    &::placeholder {
      color: red;
    }
  }

</style>
