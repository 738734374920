<template>
  <div>
    <Preloader v-if="preloader"/>
    <b-row class="order-top-select justify-content-center" no-gutters>
      <b-col cols="5" class="text-center">
        <wmSelect
          :selected="$t('sliders_page.all_category')"
          :options="categoriesList"
          @updateOption="selectedCategory=$event"
          :customItemStyle="{'width': '269px', 'height': '35px'}"
          :placeholder="$t('sliders_page.choose_category')"
        />
      </b-col>
      <b-col
        v-if="plaques.find((x) => x.is_created === true)"
        cols="5"
      >
        <router-link
          to="/custom-plaques"
          class="wm-btn preview-btn">
          {{ $t('sliders_page.preview') }}
        </router-link>
      </b-col>
    </b-row>
    <div v-if="!preloader" class="sliders-page">
      <productSubtitle
        :title="pageTitle"
        :subtitle="pageTitleDescription"/>
      <b-row no-gutters>
        <b-col
          v-for="(item, key) in filteredPlaques"
          :key="key"
          cols="12"
          class="slider-wrap">
          <div class="slider-title">{{ item[0].category[0].name }}</div>
          <WmSlider
            @openOrderEdit="openOrderEdit"
            :plaques="item"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-5">
        <b-col cols="5" class="d-flex align-items-center justify-content-center">
          <router-link
            v-if="plaques.find((x) => x.is_created === true)"
            to="/custom-plaques"
            class="wm-btn preview-btn">
            {{ $t('sliders_page.preview') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import WmSlider from '@/components/slider.vue'
import OrderEdit from '@/components/order-edit.vue'
import WmSelect from '@/components/wm-select.vue'
import { pageTitleMixin } from '@/mixins/common';
import productSubtitle from '@/components/product-subtitle'

export default {
  name: 'SlidersPage',
  components: {
    WmSlider,
    OrderEdit,
    WmSelect,
    productSubtitle
  },
  mixins: [ pageTitleMixin ],
  data () {
    return {
      plaque: {},
      preloader: true,
      productSlug: 'collection-privee', //! Warning Hard code slug
      pageTitle: undefined,
      pageTitleDescription: undefined,
      selectedCategory: '',
      categoriesList: {},
    }
  },
  mounted () {
    if (this.erpId) {
      this.$store.dispatch('getErpPlaqueOrders', this.erpId)
      this.$store.dispatch('getProductList').then(() => {
        const productObj = this.$store.getters.productList.find(x => x.slug === this.productSlug)
        if (typeof productObj === 'undefined' || productObj === null) {
          this.pageTitle = this.$t('defaultTitle')
        } else {
          this.pageTitle = productObj.name
        }
      })
      this.$store.dispatch('getPlaques').then(() => {
        this.preloader = false
      })
    } else {
      console.info('DEV info! Managers couldn\'t see this page');
      this.$store.dispatch('getUserProfile')
    }
  },
  computed: {
    productPageSubtitle () {
      const plaqueSlug = 'collection-privee'
      const products = this.$store.getters.productList

      return products.find(obj => {
        return obj.slug === plaqueSlug
      }).page_title
    },
    erpId() {
      return this.$store.getters.erpId
    },
    plaques() {
      console.log('this.$store.getters.plaques', this.$store.getters.plaques);
      return this.$store.getters.plaques
    },
    filteredPlaques() {
      let newPlaquesList = {}
      this.plaques.forEach((element, index) => {
        if (element.category && element.category.length > 0) {
          element.category.forEach((elementCategory, indexCategory) => {
            if (typeof newPlaquesList[elementCategory.order] === "object") {
              const tmpElement = Object.assign({}, element);
              tmpElement.category = new Array(elementCategory);
              newPlaquesList[elementCategory.order].push(tmpElement)
            }
            if (typeof newPlaquesList[elementCategory.order] === "undefined") {
              const tmpElement = Object.assign({}, element);
              tmpElement.category = new Array(elementCategory);
              newPlaquesList[elementCategory.order] = new Array(tmpElement)
            }
          });
        }
      });
      // Filter data by the dropdown value
      let filtered = {};
      if (this.selectedCategory && this.selectedCategory !== '1000') {
        filtered[this.selectedCategory] = [...newPlaquesList[this.selectedCategory]]
      } else {
        filtered = newPlaquesList
      }
      // Sort each line by order
      Object.keys(filtered).forEach(key => {
        filtered[key] = [...filtered[key].sort((a, b) => a.order - b.order)]
      })
      console.log('Filtered plaques', filtered)
      return filtered
    }
  },
  methods: {
    openOrderEdit (item, plaques) {
      this.$modal.show(OrderEdit,
        {
          plaque: item,
          plaques: plaques,
        },
        {
          width: '80%',
          height: 'auto',
          pivotY: 0.05,
          scrollable: true,
          classes: 'plaque-edit-modal',
        });
    },
  },
  watch: {
    plaques: {
      deep: true,
      handler () {
        this.categoriesList['1000'] = this.$t('sliders_page.all_category')
        this.plaques.forEach(item => {
          item.category.forEach(categoryItem => {
            this.categoriesList[categoryItem.order] = categoryItem.name
          })
        })
      },
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .sliders-page {
    position: relative;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .slider-title {
    font-size: 20px;
    font-family: 'DaxMedium';
    margin: 0 105px;
  }

  .order-top-select {
    position: absolute;
    top: 5px;
    width: 100%;
  }

  .preview-btn {
    padding: 5px 10px;
    border-radius: 100px !important;
    font-size: 15px;
    margin: 2px 0 0 10px;
    width: 70%;

    &:after {
      content: '';
      border: 4px solid transparent;
      border-left: 7px solid #fff;
      display: block;
      margin-left: 10px;
      width: 0;
      height: 0;
    }
  }

</style>
