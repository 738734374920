<template>
  <div>
    <Preloader v-show="preloader"/>
    <b-row class="order-top-select" v-if="activeFilterDropdown && !previewStatus" no-gutters>
      <b-col class="text-right">
        <wmSelect
          :selected="$t('sliders_page.all_category')"
          :options="filterItems"
          @updateOption="selectedFilterItem=$event"
          :customItemStyle="{'width': '269px', 'height': '35px'}"
          :placeholder="$t('sliders_page.choose_category')"
        />
      </b-col>
      <b-col>
        <button
          @click.prevent="clickOnPrevieButton()"
          class="wm-btn order-top-select-button"
        >
        {{ $t('comment_form.preview') }}
        </button>
      </b-col>
    </b-row>
    <div v-show="!preloader" class="product-page">
      <productSubtitle
        :title="pageTitle"
        :subtitle="pageTitleDescription"/>
      <div v-show="!previewStatus" class="product-page__form">
        <vue-form-generator
          :schema="filteredSchema"
          :model="dataModel"
          @validated="onProductFormValidated"
          ref="productForm"
        ></vue-form-generator>
        <div class="mobilier-inter"
          v-if="$route.params.slug.includes('inter-and-epitafie') && showOnlyMobilierHook">
          <div class="mobilier-inter-title">{{ $t('product.mobilierTitle') }}</div>
          <div class="mobilier-inter-text">{{ $t('product.mobilierText') }}</div>
        </div>
      </div>
      <standardPreview
        v-if="previewStatus"
        :orderData="dataModel"
        :productSlug="$route.params.slug"
        :productSchema="schema"
      />
      <b-row no-gutters class="mt-auto">
        <b-col cols="9" offset-md="1">
          <commentForm
            :defaultCommentData="commentData"
            :isPreviewStatus="previewStatus"
            @updateData="commentFormData = $event"
            @submitForm="submit()"
            @previewForm="showPreviewForm()"
          ></commentForm>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { pageTitleMixin } from '@/mixins/common';
import { deepCopy, twoDigitStr } from "@/include/helpers";
import commentForm from '@/components/comment-form';
import productSubtitle from '@/components/product-subtitle';
import WmSelect from '@/components/wm-select.vue';
import standardPreview from '@/views/orders/preview/standard_preview.vue';
// TODO: Remove inter & epitafie part from that component
export default {
  name: "orderNew",
  data() {
    return {
      isOrderEdit: !!this.$route.params.id,
      schema: undefined,
      filteredSchema: undefined,
      dataModel: {},
      preloader: true,
      productData: undefined,
      pageTitle: undefined,
      pageTitleDescription: undefined,
      commentFormData: undefined,
      commentData: {
        contact: undefined,
        address: undefined,
        reference_text: undefined,
        comment: undefined,
      },
      interSchema: undefined,
      epitafieSchema: undefined,
      customSchemaField: undefined,
      customSchema: [],
      customModel: {},
      interCount: 1,
      epitafieCount: 1,
      customCount: 1,
      testSchema: {
        fields: [
          {
            type: "upload",
            label: "Photo",
            model: "photo",
            inputName: "photo",
            multiple: true,
            onChanged(model, schema, event) {
              model.photo = event.target.files
            }
          },
        ]
      },
      interEpitafieList: [],
      testModel: {
        photo: undefined,
      },
      newElCount: 0,
      addButton: {
        classes: 'lol',
        label: 'Add',
        onclick: () => {},
      },
      removeButton: {
        classes: 'lol-rem',
        label: 'Remove',
        onclick: () => {},
      },
      activeFilterDropdown: false,
      filterItems: [],
      selectedFilterItem: '',
      showOnlyMobilierHook: true,
      previewStatus: false,
      isFormValid: false,
    }
  },
  components: {
    commentForm,
    WmSelect,
    productSubtitle,
    standardPreview,
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    console.log('this.$route.params.slug', this.$route.params.slug);
    this.retrieveProductData();
  },
  updated() {
    setTimeout(this.changeHelpTextPosition(), 2000);
  },
  methods: {
    retrieveProductData() {
      this.$store.dispatch('getProductSchema', this.$route.params.slug)
        .then(( data )=> {
            console.log('data', data);
            if (this.$route.params.slug.includes('inter-and-epitafie')) {
              this.preloader = true;
              this.schema = data.schema;
              this.interSchema = data.schema.fields.slice(0, 2);
              this.epitafieSchema = data.schema.fields.slice(2, 4);
              this.customSchemaField = data.schema.fields.slice(4, 6);
              this.$store.dispatch('getInterEpitafieList').then((data) => {
                this.interEpitafieList = data;
                console.log('this.interEpitafieList', this.interEpitafieList);
                this.preloader = false;
              });
              // Prepare dropdown filter
              this.filterItems['3'] = this.$t('sliders_page.all_category')
              this.filterItems['0'] = this.$t('productPageFilter.inter')
              this.filterItems['1'] = this.$t('productPageFilter.epitafie')
              this.filterItems['2'] = this.$t('productPageFilter.custom')
              this.filterItems['4'] = this.$t('productPageFilter.mobilier')
              this.activeFilterDropdown = true
            } else {
              this.schema = data.schema;
              this.addHiddenSubmit();
              this.preloader = false;
            }

            this.productTitle = data.name;
            this.pageTitle = data.name;
            this.pageTitleDescription = data.page_title;
            // if(this.$route.params.slug.includes('inter-and-epitafie')) {
            //   this.pageTitle = this.$t('page_titles.inter_epitafie');
            // }

            if(this.isOrderEdit) {
              this.$store.dispatch('getErpOrder', {
                erpId: this.$store.getters.erpId,
                orderId: this.$route.params.id,
              }).then((data) => {
                if(this.$route.params.slug.includes('inter-and-epitafie')) {
                  this.buildCustomSchemaEdit(data.data);
                }
                this.dataModel = data.data;
                this.commentData.contact = data.contact.id;
                this.commentData.address = data.address.id;
                this.commentData.comment = data.comment;
                this.commentData.reference_text = data.reference_text;
                this.concatLinksToFiles()
                this.preloader = false;
              })
            } else {
              this.concatLinksToFiles()
              // this.preloader = false;
            }
        });
    },
    changeHelpTextPosition(){
      const pageContainer = document.getElementsByClassName('product-page');
      const helpTextElements = document.getElementsByClassName('helpText');
      const pageContainerRect = pageContainer[0].getBoundingClientRect();
      const maxContainerWidth = pageContainerRect.x + pageContainerRect.width;
      if (helpTextElements.length > 1) {
        for (let i = 0; i <= helpTextElements.length - 1; i += 1) {
          if (typeof helpTextElements[i] === 'object') {
            let elementContainer = helpTextElements[i].getBoundingClientRect();
            let maxElementWidth = elementContainer.x + elementContainer.width;
            if (maxElementWidth > maxContainerWidth) {
              helpTextElements[i].style.top = '18px';
              helpTextElements[i].style.left = `-${maxElementWidth - maxContainerWidth}px`;
            } else {
              helpTextElements[i].style.top = '0';
              helpTextElements[i].style.left = '20px';
            }
          }
          if (i > 10) {
            break
          }
        }
      }
    },
    concatLinksToFiles() {
      this.schema.fields.forEach(element => {
        if(element.type === 'upload') {
          element['onChanged'] = (model, schema, event) => {
            model[element.model] = event.target.files
          }
          let links = ''
          if(this.dataModel.files && this.dataModel.files.length > 0) {
            this.dataModel.files.forEach(item => {
              links = links.concat(
                `<a class="wm-link" target="_blank" rel="noopener noreferrer" href="${item}">
                  ${item.slice(item.lastIndexOf('/')+1)}
                </a><br>`)
            });
          }
          element['hint'] = links;
        }
      });
    },
    showFiles(event) {
      console.log(event.target.files);
    },
    reindexCounter(data=undefined) {
      let _tmpData = this.dataModel;
      if (data) {
        _tmpData = data
      }
      let interCount = Object.keys(_tmpData).filter(item => item.includes('inter') && !item.includes('quantity')).length;
      let epitafieCount = Object.keys(_tmpData).filter(item => item.includes('epitafie') && !item.includes('quantity')).length;
      let customCount = Object.keys(_tmpData).filter(item => item.includes('custom') && !item.includes('quantity')).length;
      this.interCount = interCount;
      this.epitafieCount = epitafieCount;
      this.customCount = customCount;
      console.log('reindexCounter', this.interCount, this.epitafieCount, this.customCount)
    },
    buildCustomSchemaEdit(data) {
      this.schema.fields = {};
      this.customSchema = [];
      this.reindexCounter(data);
      let _inter = 0;
      let _epitafie = 0;
      let _custom = 0;
      let result = undefined;

      for (let objKey in data) {
        if (objKey.includes('inter') || objKey.includes('epitafie') || objKey.includes('custom')) {
          if (!objKey.includes('quantity')) {
            if (objKey.includes('inter')) {
              _inter += 1;
              if (_inter === this.interCount) {
                result = this.addSchemaEdit(objKey, true);
                _inter +=1;
              } else {
                result = this.addSchemaEdit(objKey);
              }
            };
            if (objKey.includes('epitafie')) {
              _epitafie += 1;
              if (_epitafie === this.epitafieCount) {
                result = this.addSchemaEdit(objKey, true);
                _epitafie +=1;
              } else {
                result = this.addSchemaEdit(objKey);
              }
            };
            if (objKey.includes('custom')) {
              _custom++;
              if (_custom === this.customCount) {
                result = this.addSchemaEdit(objKey, true);
                _custom++;
              } else {
                result = this.addSchemaEdit(objKey);
              }
            };
            this.customSchema = this.customSchema.concat(result[0]);
          }
        }
      }
      if (_custom === 0) {
        this.customSchema = this.customSchema.concat(this.customSchemaField);
      }

      console.log('_tmpSchema - edit', this.customSchema, this.dataModel);
      this.schema.fields = this.customSchema;
      this.dataModel = data;
      this.addHiddenSubmit()
    },
    buildFormInterEpitafie() {
      const groupSet = {
        groups: [
          {
            legend: this.$t('product.inter'),
            fields: []
          },
          {
            legend: this.$t('product.epitafie'),
            fields: []
          },
          {
            legend: this.$t('product.custom'),
            fields: []
          }
        ]
      };
      const field = {
        inputName: '', // custom00
        inputType: 'number',
        label: '', //custom
        model: '', // custom00
        multiple: false,
        required: false,
        styleClasses: ['col-md-4', 'inter-epitafie-item'],
        type: 'input',
        validator: "number",
      };
      this.customSchema = [];
      this.customModel = {};
      const newSchema = deepCopy(groupSet);

      if (this.interEpitafieList && this.interEpitafieList.length) {

        this.interEpitafieList.forEach((item) => {
          if (item.item_type === 'inter') {
            const _tmpField = deepCopy(field);
            _tmpField.inputName = item.code;
            _tmpField.label = `${item.text} (${item.code})`;
            _tmpField.model = item.code;
            newSchema.groups[0].fields = newSchema.groups[0].fields.concat([_tmpField]);
            this.customModel[item.code] = '';
          }

          if (item.item_type === 'epitafie') {
            const _tmpField = deepCopy(field);
            _tmpField.inputName = item.code;
            _tmpField.label = `${item.text} (${item.code})`;
            _tmpField.model = item.code;
            newSchema.groups[1].fields = newSchema.groups[1].fields.concat([_tmpField]);
            this.customModel[item.code] = '';
          }
        })
      }
      for (var i = 0; i < 3; i++) {
        const _tmpField = deepCopy(field);
        _tmpField.inputName = `custom${i}`;
        _tmpField.label = this.$t('product.customLabel');
        _tmpField.model = `custom${i}`;
        _tmpField.inputType = 'string';
        _tmpField.validator = 'string';
        _tmpField.styleClasses = ['col-md-3'];
        newSchema.groups[2].fields = newSchema.groups[2].fields.concat([_tmpField]);
        this.customModel[`custom${i}`] = '';

        const _tmpCountField = deepCopy(field);
        _tmpCountField.inputName = `custom${i}_count`;
        _tmpCountField.label = this.$t('product.customLabelQuantity');
        _tmpCountField.model = `custom${i}_count`;
        _tmpCountField.inputType = 'number';
        _tmpCountField.validator = 'number';
        _tmpCountField.styleClasses = ['col-md-1'];
        newSchema.groups[2].fields = newSchema.groups[2].fields.concat([_tmpCountField]);
        this.customModel[`custom${i}_count`] = '';
      }

      this.schema = newSchema;
      this.addHiddenSubmit();
      this.dataModel = this.customModel;
    },
    addHiddenSubmit() {
      console.log('Add hidden submit button');
      if (!this.schema.fields) {
        this.schema.fields = []
      }
      this.schema.fields.push({
        type: 'submit',
        id: 'order-submit-btn',
        onSubmit: this.submitOrder,
        styleClasses: ['order-submit-hidden-btn'],
        validateBeforeSubmit: true,
      });
    },
    addSchemaEdit(nameField, isAddButton=false) {
      let _tmpSchema = [];
      let _newField = undefined;
      if (nameField.includes('inter')) {
        _newField = deepCopy(this.interSchema);
      }
      if (nameField.includes('epitafie')) {
        _newField = deepCopy(this.epitafieSchema);
      }
      if (nameField.includes('custom')) {
        _newField = deepCopy(this.customSchemaField);
      }

      _newField.forEach(item => {
        item.inputName = nameField;
        item.model = nameField;
        if (item.label.includes('quantity')) {
          item.label = this.$t('order_edit.quantity');
          item.inputName = `quantity_${nameField}`;
          item.model = `quantity_${nameField}`
        }
        // add buttons
        if (item.inputName.includes('quantity')) {
          const _removeBtn = deepCopy(this.removeButton);
          _removeBtn.onclick = () => {
            this.removeItemForm(deepCopy(item.inputName), deepCopy(item.model));
          };
          item.buttons = [_removeBtn];
          if (isAddButton) {

            const _addBtn = deepCopy(this.addButton);
            _addBtn.onclick = () => {
              if (nameField.includes('inter')) {
                this.dataModel[`quantity_inter${twoDigitStr(this.interCount)}`] = '';
                this.dataModel[`inter${twoDigitStr(this.interCount)}`] = '';
                this.interCount++;
                this.customModel = Object.assign({}, this.dataModel);
              }
              if (nameField.includes('epitafie')) {
                this.dataModel[`quantity_epitafie${twoDigitStr(this.epitafieCount)}`] = '';
                this.dataModel[`epitafie${twoDigitStr(this.epitafieCount)}`] = '';
                this.epitafieCount++;
                this.customModel = Object.assign({}, this.dataModel);
              }
              if (nameField.includes('custom')) {
                this.dataModel[`quantity_custom${twoDigitStr(this.epitafieCount)}`] = '';
                this.dataModel[`custom${twoDigitStr(this.epitafieCount)}`] = '';
                this.epitafieCount++;
                this.customModel = Object.assign({}, this.dataModel);
              }

            };
            item.buttons = [_addBtn]
          }
        }
      });
      _tmpSchema = _tmpSchema.concat(_newField);

      return [_tmpSchema]
    },
    reindexSchema() {
      let newIndexEpitafie = 0;
      let newIndexInter = 0;
      let newIndexCustom = 0;
      const newDataModel = {};
      const newSchema = [];

      for (let dataItem in this.dataModel) {
        console.log('dataItem', dataItem);
        if (dataItem.includes('quantity_inter')) {
          let _tmpName = dataItem.toString();
          let interIndex = _tmpName.slice(-2);
          newDataModel[`quantity_inter${twoDigitStr(newIndexInter)}`] = this.dataModel[dataItem];
          newDataModel[`inter${twoDigitStr(newIndexInter)}`] = this.dataModel[`inter${interIndex}`];
          newIndexInter++;
        }
      }
      for (let dataItem in this.dataModel) {
        if (dataItem.includes('quantity_epitafie')) {
          let _tmpName = dataItem.toString();
          let epitafieIndex = _tmpName.slice(-2);
          newDataModel[`quantity_epitafie${twoDigitStr(newIndexEpitafie)}`] = this.dataModel[dataItem];
          newDataModel[`epitafie${twoDigitStr(newIndexEpitafie)}`] = this.dataModel[`epitafie${epitafieIndex}`];
          newIndexEpitafie++;
        }
      }
      for (let dataItem in this.dataModel) {
        if (dataItem.includes('quantity_custom')) {
          let _tmpName = dataItem.toString();
          let customIndex = _tmpName.slice(-2);
          newDataModel[`quantity_custom${twoDigitStr(newIndexCustom)}`] = this.dataModel[dataItem];
          newDataModel[`custom${twoDigitStr(newIndexCustom)}`] = this.dataModel[`custom${customIndex}`];
          newIndexCustom++;
        }
      }

      console.log('reindexSchema', newDataModel, newSchema);
      this.dataModel = newDataModel;
      this.buildCustomSchemaEdit(newDataModel);
    },
    removeItemForm(fieldName, modelName) {
      const _index = this.customSchema.findIndex(item => item.inputName === fieldName);
      this.customModel = this.dataModel;
      console.log('removeItemForm1', _index, fieldName, deepCopy(this.customModel), deepCopy(this.customSchema));
      if (_index) {
        delete this.customModel[this.customSchema[_index-1].inputName];
        delete this.customModel[this.customSchema[_index].inputName];
        this.customSchema.splice(_index-1, 2);
      }
      console.log('removeItemForm2', _index, fieldName, this.customModel, this.customSchema);
      this.schema.fields = this.customSchema;
      this.dataModel = this.customModel;
    },
    sendFiles(files, orderId) {
      let formData = new FormData();
      for(let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('order_id', orderId)
      this.$store.dispatch('sendFiles', formData);
    },
    submit() {
      document.getElementById('order-submit-btn').click();
    },
    showPreviewForm() {
      this.$refs.productForm.validate()
      if (this.isFormValid) {
        this.previewStatus = this.isOrderEmpty();
      }
    },
    confirmOrder(id) {
      const NEGATIVE_RESPONSE = [
        'Added to processing queue',
        'In Process'
      ]
      this.preloader = true;
      this.$store.dispatch('confirmErpOrder', {
        erpId: this.$store.getters.erpId,
        orderId: id,
      }).then((result) => {
        if (result.data.detail === 'Order confirmed successfully') {
          this.preloader = false;
          this.$router.push({name: 'ordersTable'});
        }
        if (NEGATIVE_RESPONSE.includes(result.data.detail)) {
          setTimeout(this.confirmOrder(id), 1000);
        }
      })
    },
    submitOrder() {
      const orderData = this.commentFormData;
      orderData.data = this.dataModel;
      if (this.$route.params.slug.includes('inter-and-epitafie')) {
        // spike-nail
        orderData.data[orderData.data['custom0']] = orderData.data['custom0_count'];
        orderData.data[orderData.data['custom1']] = orderData.data['custom1_count'];
        orderData.data[orderData.data['custom2']] = orderData.data['custom2_count'];
      }
      orderData.status = 1;
      orderData.category = this.$route.params.slug;

      let files = [];
      let orderId = undefined;
      Object.keys(this.dataModel).forEach(element => {
        if(element.includes('file_')) {
          files.push(this.dataModel[element][0]);
        }
      });

      if (this.isOrderEdit) {
        this.$store.dispatch('updateErpOrder', {
          erpId: this.$store.getters.erpId,
          orderId: this.$route.params.id,
          orderData: orderData
        }).then((data) => {
          orderId = data.id
          if (files) {
            if (files.length) {
              this.sendFiles(files, orderId)
            } else {
              this.$router.push({name: 'ordersTable'});
            }
          } else {
            this.$router.push({name: 'ordersTable'});
          }
        })
      } else {
        this.$store.dispatch('createOrder', {
          erpId: this.$store.getters.erpId,
          data: orderData
        }).then((data) => {
          orderId = data.id;
          this.sendFiles(files, orderId);
        }).then(() => this.confirmOrder(orderId))
      }
    },
    isOrderEmpty() {
      return !Object.values(this.dataModel).every(x => x === null || x === '')
    },
    onProductFormValidated(isValid, _) {
      this.isFormValid = isValid;
    },
    clickOnPrevieButton() {
      document.getElementById('preview-button').click();
    }
  },
  watch: {
    // TODO how to start after help text loaded?!!!
    interEpitafieList: {
      handler() {
        this.buildFormInterEpitafie();
      }
    },
    schema: {
      deep: true,
      handler() {
        if (typeof this.schema === 'object') {
          this.filteredSchema = this.schema
        }
      }
    },
    selectedFilterItem: {
      handler() {
        if (Object.keys(this.schema).find(x => x ==='groups')) {
          const _tmp = Object.assign({}, this.schema)
          let newGroup = []

          // Filter schema by dropdown value
          switch (this.selectedFilterItem) {
            case '0':
              newGroup = _tmp.groups[0]
              this.showOnlyMobilierHook = false
              break
            case '1':
              newGroup = _tmp.groups[1]
              this.showOnlyMobilierHook = false
              break
            case '2':
              newGroup = _tmp.groups[2]
              this.showOnlyMobilierHook = false
              break
            default:
              this.showOnlyMobilierHook = true
          }

          // Check if newGroup is defined and consist right data
          if (Object.keys(newGroup).find(x => x === 'legend')) {
            _tmp.groups = [newGroup]
          }
          this.filteredSchema = Object.assign({}, _tmp)
          if (this.selectedFilterItem === '4') {
            this.filteredSchema = {}
          }
        } else {
          this.filteredSchema = Object.assign({}, this.schema)
        }
      }
    }
  }
}
</script>

<!-- include the vue-multiselect CSS -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
  .product-page {
    margin-bottom: 50px;

    &__form {
      margin: 20px 30px 70px 30px;
    }
    &__comment-form {
    }
  }

  .order-submit-hidden-btn {
    display: none;
  }

  .mobilier-inter {
    width: 100%;

    &-title {
      margin-top: 15px;
      margin-bottom: 30px;
      font-size: 24px;
      border-bottom: 1px solid gray;
     }
    &-text {
      padding: 0 15px;
    }
  }
</style>

<style lang="scss">
  .multiselect__tags {
    min-height: 36px;
    padding: 0px 40px 0 8px;
    /*height: 36px;*/
    border: 1px solid #ccc;
  }
  .multiselect__placeholder {
    margin-top: 5px;
  }

  .multiselect__input {
    line-height: 35px;
  }

  .multiselect__tags-wrap > .multiselect__tag {
    margin-top: 5px;
  }

  div[slug="inter-and-epitafie"] div.form-group.col-md-4.inter-epitafie-item.field-input {
    display: inline-flex !important;
  }

  div[slug="inter-and-epitafie"] .order-submit-hidden-btn {
    display: none !important;
  }

  div[slug="inter-and-epitafie"] legend {
    margin-bottom: 30px;
    border-bottom: 1px solid gray;
  }

  .inter-epitafie-item label {
    display: flex !important;
    max-width: 80%;
    min-width: 80%;
  }

  .inter-epitafie-item .field-wrap {
    width: 40px;
  }

  /*.inter-epitafie-item input {*/
  /*  padding: 0 2px 0 4px !important;*/
  /*}*/

  input[type="number"] {
    padding: 0 2px 0 4px !important;
  }
  .order-top-select {
    position: absolute;
    top: 5px;
    width: 100%;

    &-button {
      padding: 5px 10px;
      border-radius: 100px !important;
      font-size: 15px;
      margin: 2px 0 0 10px;

      &:after {
        content: '';
        border: 4px solid transparent;
        border-left: 7px solid #fff;
        display: block;
        margin-left: 10px;
        width: 0;
        height: 0;
      }
    }
  }

</style>
