<template>
  <div>
    <Preloader v-show="preloader"/>
    <div v-show="!preloader" class="orders-page">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="11" class="table-wrap">
          <b-table-simple borderless>
            <b-thead>
              <b-tr class="table-head-row">
                <b-td>{{ $t('documents.title') }}</b-td>
                <b-td>{{ $t('orders_table.download') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="item in documentsList"
                :key="item.id"
                class="table-body-row"
              >
                <b-td>{{ item.title }}</b-td>
                <b-td style="width: 40px">
                  <a :href="item.file" target="_blank" rel="noopener noreferrer" class="download-link"></a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import { pageTitleMixin } from '@/mixins/common'

export default {
  name: 'DocumentsTable',
  data() {
    return {
      preloader: true,
      pageTitle: this.pTitle(),
    }
  },
  mixins: [ pageTitleMixin ],
  beforeMount() {
    this.$store.dispatch('getDocuments', this.$route.name).then(() => {
      this.preloader = false;
    })
  },
  computed: {
    documentsList() {
      switch (this.$route.name) {
        case 'document':
          return this.$store.getters.documentsList;
        case 'catalog':
          return this.$store.getters.catalogDocumentsList;
        default:
          return this.$store.getters.tarifDocumentsList
      }
    },
  },
  methods: {
    pTitle () {
      switch (this.$route.name) {
        case 'document':
          return this.$t('page_titles.documents')
        case 'catalog':
          return this.$t('page_titles.catalog')
        default:
          return this.$t('page_titles.tarifs')
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.preloader = true;
      this.$store.dispatch('getDocuments', this.$route.name).then(() => {
        this.pageTitle = this.pTitle();
        this.preloader = false;
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .table-wrap {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
  }

  .table-head-row {
    border-bottom: 1px solid #b9b9b9;
  }

  .table-body-row td{
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
  }

  .download-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(/static/img/download.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

</style>
