<template>
  <div class="right-side-bar" :class="{ 'open': isOpen }">
    <div class="right-side-bar__content">

      <div class="section"
        v-for="(item, index) in newsListToShow"
        :key="index"
      >
        <h2 class="section__title"
          v-if="item.title"
        >{{ item.title }}</h2>
        <div class="section__image-container"
          v-if="contentToShow(item) === 'image'"
          @click.prevent="showImageModal(item.image_thumbnail_large)"
        >
          <img v-lazy="item.image_thumbnail" alt="">
        </div>
        <div class="section__video-container"
          v-if="contentToShow(item) === 'video'"
        >
          <iframe
            class="section__video-iframe"
            :src="item.video"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="section__tarif-container"
             v-if="item.category.includes('Tarifs')">
          <ul>
            <li v-for="item in fileList">
              <svg data-name="Livello 1" id="Livello_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><g fill="white"><path d="M61.88,93.12h0a3,3,0,0,0,.44.36l.24.13a1.74,1.74,0,0,0,.59.24l.25.07h0a3,3,0,0,0,1.16,0l.26-.08.3-.09a3,3,0,0,0,.3-.16l.21-.12a3,3,0,0,0,.46-.38L93,66.21A3,3,0,1,0,88.79,62L67,83.76V3a3,3,0,0,0-6,0V83.76L39.21,62A3,3,0,0,0,35,66.21Z"/><path d="M125,88a3,3,0,0,0-3,3v22a9,9,0,0,1-9,9H15a9,9,0,0,1-9-9V91a3,3,0,0,0-6,0v22a15,15,0,0,0,15,15h98a15,15,0,0,0,15-15V91A3,3,0,0,0,125,88Z"/></g></svg>
              <a :href="item.file" target="_blank" class="download-link">{{ item.title }}</a></li>
          </ul>

        </div>
        <div class="section__text"
          v-if="item.text" v-html="item.text"
        ></div>
      </div>

    </div>
    <div @click="toggleSidebar" class="right-side-bar__toggle">
      <div class="right-side-bar__toggle-text">{{ toggleText }}</div>
    </div>
  </div>
</template>

<script>
import imageModal from '@/components/common/image-modal/index.vue';
import OrderEdit from "@/components/order-edit";

export default {
  name: 'RightSideBar',
  components: {
    imageModal,
  },
  data() {
    return {
      isOpen: false,
      newsListToShow: [],
      fileList: [],
    }
  },
  beforeMount() {
    this.$store.dispatch('getNewsList').then(()=> {
      this.filterFaqList()
    });
    this.loadDocuments();
  },
  computed: {
    toggleText() {
      if(this.isOpen) {
        return this.$t('right_side_bar.close');
      } else return this.$t('right_side_bar.open');
    },
    newsList() {
      return this.$store.getters.newsList;
    },
    documentsList() {
      return this.$store.getters.documentsList;
    },
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      this.$emit('sidebarToggle', this.isOpen);
    },
    async loadDocuments() {
      const docs = await this.$store.dispatch('getDocuments', 'tarif');
      const tarifs = await this.$store.dispatch('getDocuments', 'document');
      if (docs) {
        docs.forEach((item) => {
          if (item.profile === null) {
            this.fileList.push(item)
          }
        })
      }
      if (tarifs) {
        tarifs.forEach((item) => {
          if (item.profile === null) {
            this.fileList.push(item)
          }
        })
      }
    },
    contentToShow(item) {
      if(item.image_thumbnail && item.image_thumbnail !== null) {
        return 'image'
      } else if(item.video && item.video!=='') {
        return 'video'
      }
    },
    filterFaqList(){
      let path = this.$route.path;

      if (Array.isArray(this.newsList)) {
        this.newsListToShow = (path === '/') ? this.newsList.filter(item => item.show_on_main_page) : this.newsList.filter(item => item.tags.includes(path));
      }
    },
    showImageModal(imageUrl) {
      this.$modal.show(imageModal,
        {
          imageData: imageUrl,
        },
        {
          width: '80%',
          height: '90%',
          classes: 'image-modal',
          adaptive: true,
        });
    },
  },
  watch: {
    $route () {
      this.filterFaqList()
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .right-side-bar {
    width: 292px;
    // min-height: 100vh;

    position: absolute;
    right: -307px;
    top: -90px;
    transition: .4s all ease;
    border: 1px solid #b9b9b9;

    &__toggle {
      width: 40px;
      height: 160px;
      background-color: #a8a8a8;
      position: absolute;
      left: -18px;
      border-radius: 50%;
      top: 38%;
      cursor: pointer;
      z-index: 9;

      &:before,&:after {
        content: '';
        position: absolute;
        left: 0;
        border: 5px solid transparent;
        border-right: 9px solid #fff;
        display: block;
        width: 0;
        height: 0;
      }
      &:before {
        top: 48px;
      }
      &:after {
        bottom: 48px;
      }
    }

    &__toggle-text {
      writing-mode: tb-rl;
      transform: rotate(180deg);
      font-size: 14px;
      text-align: center;
      height: 100%;
      color: #fff;
      text-transform: uppercase;
    }

    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 10;
      height: 100vh;
      overflow-y: auto;
    }
  }

  .open {
    right: -15px;
  }

  .open .right-side-bar__toggle:before,.open .right-side-bar__toggle:after{
    transform: rotate(180deg);
    left: 5px;
  }

  .video-section {
    padding: 30px 30px 10px;
    overflow: hidden;
    &__title {
      font-family: 'DaxMedium';
      font-size: 21px;
      text-align: center;
    }



    &__link {
      font-size: 14px;
      text-decoration: underline;
      display: block;
      text-align: center;
    }
  }

  .section {
    padding: 20px 15px;
    border-top: 1px solid #b9b9b9;
    overflow: hidden;

    &__title {
      text-transform: uppercase;
      font-size: 24px;
      text-align: center;
      font-family: 'DaxMedium';
    }

    &__text {
      overflow: hidden;
    }

    &__video-container {
      margin-bottom: 10px;
    }

    &__video-iframe {
      width: 100%;
    }

    &__image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 235px;
      overflow: hidden;
      margin: auto;
      margin-bottom: 10px;

      img {
        max-width: 100%;
      }
    }

    &__tarif-container {

      ul {

        list-style: none;
        padding: 0 40px;

        li {
          background-color: #a8a8a8;
          border-radius: 6px;
          padding: 1px 0;
          margin-bottom: 4px;

          svg {
            width: 16px;
            margin-left: 5px;
            margin-top: -3px;
          }

        }
      }
      .download-link {
        display: inline-block;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 5px;
        color: white;
        line-height: 18px;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
      }
    }
  }

</style>
