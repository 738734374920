import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { LayoutPlugin, TableSimplePlugin, CardPlugin } from 'bootstrap-vue'
import VueFormGenerator from 'vue-form-generator'
import VueI18n from 'vue-i18n'
import messages from './locale'
import VModal from 'vue-js-modal'
import VueLazyload from 'vue-lazyload'
import Multiselect from 'vue-multiselect'
import VueHead from 'vue-head'

import VueFormGeneratorLocale from './vfg-translate'
import '@/include/logger'
import '@/include/sentry'

import HomePage from './views/Home.vue'
import Preloader from '@/components/preloader.vue'

import 'vue-form-generator/dist/vfg.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/style.css'

Vue.use(LayoutPlugin)
Vue.use(TableSimplePlugin)
Vue.use(CardPlugin)
Vue.use(VueI18n)
Vue.use(VueLazyload, {
  error: '/static/img/img_error.png',
  loading: '/static/img/img_load.gif',
  attempt: 1,
})
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dynamicDefaults: { clickToClose: false } })
Vue.use(VueHead)
Vue.component('HomePage', HomePage)
Vue.component('Preloader', Preloader)
Vue.component('multiselect', Multiselect)

// VueFormGenerator.validators.resources = vfgRes
// VueFormGenerator.validators.resources.fieldIsRequired = 'Ezt a mezőt kötelező kitölteni!';
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'fr', // set locale
  messages, // set locale messages
})

if (i18n.locale === 'fr') {
  Vue.use(VueFormGeneratorLocale)
} else {
  Vue.use(VueFormGenerator)
}

new Vue({
  router,
  i18n,
  store,
  HomePage,
  Preloader,
  render: h => h(App),
}).$mount('#app')
